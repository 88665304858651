import React, { useState, useEffect } from 'react'
import { Popup, ToolbarItem } from 'devextreme-react/popup'
import ScrollView from 'devextreme-react/scroll-view'
import { Config } from '../../../classes/config'
import AlertDialog from '../../Common/AlertDialog'
import SelectBoxInput from '../../Common/ReusableComponents/SelectInputBox'
import DateBoxInput from '../../Common/ReusableComponents/DateBox'
import InputLabel from '@mui/material/InputLabel'

interface AssignMultipleProps {
  showModal?: boolean
  details?: any
  crewItems?: any
  selectedCrew?: any
  assignMultipleWO?: (data: any) => void
  onClose?: () => void
  StatusItems?: any
}

const AssignMultiple: React.FC<AssignMultipleProps> = props => {
  const [Eststartdate, setEstStartDate] = useState(new Date())
  const [Estenddate, setEstEndDate] = useState(new Date())
  const [crewteam, setCrewTeam] = useState<string>('')
  const [showAlert, setShowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [hideAlertButton, setHideAlertButton] = useState(false)

  useEffect(() => {
    const endaDate = new Date()
    endaDate.setHours(endaDate.getHours() + 2)

    setEstStartDate(new Date())
    setEstEndDate(endaDate)
    setCrewTeam(props.selectedCrew)
  }, [props.selectedCrew])

  const handleValueChange = (e: any, fieldName: string) => {
    if (fieldName === 'Eststartdate') {
      const endaDate = new Date(e.value)
      endaDate.setHours(e.value.getHours() + 2)
      setEstEndDate(endaDate)
    }
    if (fieldName === 'Estenddate') {
      setEstEndDate(e.value)
    }
  }

  const handleDropdownChange = (e: any, fieldName: string) => {
    setCrewTeam(e.selectedItem)
  }

  const assignWO = () => {
    if (!crewteam) {
      setAlertMessage('Please select crew')
      setShowAlert(true)
      return
    }
    if (!Eststartdate) {
      setAlertMessage('Please select start date')
      setShowAlert(true)
      return
    }
    if (!Estenddate) {
      setAlertMessage('Please select finish date')
      setShowAlert(true)
      return
    }
    if (Eststartdate && Estenddate) {
      const startDate = new Date(Eststartdate)
      const endDate = new Date(Estenddate)
      if (endDate.getTime() < startDate.getTime()) {
        setAlertMessage('Scheduled Start Date should be greater than Scheduled Finish Date')
        setShowAlert(true)
        return
      }
    }
    const dataToUpdate = {
      crew: crewteam,
      startDate: Eststartdate,
      endDate: Estenddate
    }
    if (props.assignMultipleWO) {
      props.assignMultipleWO(dataToUpdate)
    }
  }

  const alert = () => (
    <AlertDialog
      showDialog={showAlert}
      message={alertMessage}
      title={Config.productTitle}
      hideAlertButton={hideAlertButton}
      onClose={_closeDialog}
    />
  )

  const _closeDialog = () => {
    setAlertMessage('')
    setShowAlert(false)
  }

  return (
    <div className='ui-container'>
      {showAlert && alert()}
      <Popup
        width={'50vw'}
        // maxHeight={'85vh'}
        minHeight={200}
        height={'auto'}
        showTitle={true}
        showCloseButton={true}
        title={'Assign Workorder(s)'}
        dragEnabled={true}
        hideOnOutsideClick={false}
        visible={props.showModal}
        onVisibleChange={props.onClose}
      >
        <ScrollView width='100%' height='100%' showScrollbar='onScroll' scrollByThumb={true}>
          <div className='popContentDiv'>
            <div>
            <InputLabel shrink className='label bold'>
                Employee
                </InputLabel>
              <SelectBoxInput
                stylingMode='outlined'
                label='Employee'
                selectedItem={crewteam}
                displayExpr='text'
                valueExpr='id'
                items={props.crewItems}
                onSelectionChanged={(e: any) => handleDropdownChange(e, 'crewteam')}
                showClearButton={true}
              />
            </div>
            <br />
            <div>
            <InputLabel shrink className='label bold'>
                Schedule Start Date
                </InputLabel>
              <DateBoxInput
                stylingMode='outlined'
                value={Eststartdate ? new Date(Eststartdate) : null}
                type='datetime'
                displayFormat={'dd/MM/yy hh:mm a'}
                showClearButton={true}
                onValueChanged={(e: any) => handleValueChange(e, 'Eststartdate')}
              />
            </div>
            <br />
            <div>
            <InputLabel shrink className='label bold'>
                Schedule End Date
                </InputLabel>
              <DateBoxInput
                stylingMode='outlined'
                value={Estenddate ? new Date(Estenddate) : null}
                type='datetime'
                displayFormat={'dd/MM/yy hh:mm a'}
                showClearButton={true}
                onValueChanged={(e: any) => handleValueChange(e, 'Estenddate')}
              />
            </div>
          </div>
        </ScrollView>
        <ToolbarItem
          widget='dxButton'
          toolbar='bottom'
          location='after'
          options={{
            icon: 'close',
            text: 'Cancel',
            stylingMode: 'outlined',
            onClick: props.onClose
          }}
        />
        <ToolbarItem
          widget='dxButton'
          toolbar='bottom'
          location='after'
          options={{
            icon: 'save',
            text: 'Save',
            stylingMode: 'contained',
            onClick: assignWO
          }}
        />
      </Popup>
    </div>
  )
}

export default AssignMultiple
