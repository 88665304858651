import React, { useEffect } from 'react'
import Draggable from 'devextreme-react/draggable'
import Grid from '@mui/material/Grid'
import { Config } from '../../../classes/config'
import global from '../../../classes/global'
import IconButton from '@mui/material/IconButton'
import ViewListIcon from '@mui/icons-material/ViewList'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import UndoIcon from '@mui/icons-material/Undo'
import RoomIcon from '@mui/icons-material/Room'
import EventNoteIcon from '@mui/icons-material/EventNote'
import FlagIcon from '@mui/icons-material/Flag'
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd'
import PhoneIcon from '@mui/icons-material/Phone'
import moment from 'moment'
import CheckBoxInput from '../../Common/ReusableComponents/CheckBox'

interface SchedulerCardProps {
  woData?: any
  view?: any
  isMapListItem?: boolean
  schedulerlistDraggingAllowed?: boolean
  draggingGroupName?: string
  onDragStart?: (e: any, woData: any) => void
  onDragEnd?: (e: any) => void
  viewItem?: (woData: any) => void
  zoomToSelectWO?: (woData: any) => void
  viewWO?: (woData: any) => void
  showUnassignWO?: boolean
  unassignWO?: (woData: any) => void
  onUnassignedSelection?: (woData: any) => void
  onUnassignedSelectionOption?: boolean
  showCrew?: boolean
  mapView?: boolean
  dashBoardCard?: boolean
  selectedWO?: boolean
  _onShowNotesPanel?: (woData: any) => void
  onItemDragStart?:(e: any) => void
  onItemDragEnd?: (e: any) => void
  draggingAllowed?: boolean
  selectedCrewInSchedulerList?: any
}

const SchedulerCard: React.FC<SchedulerCardProps> = ({
  woData,
  view,
  isMapListItem = false,
  schedulerlistDraggingAllowed = false,
  draggingGroupName,
  onDragStart,
  onDragEnd,
  viewItem,
  zoomToSelectWO,
  viewWO,
  showUnassignWO,
  unassignWO,
  onUnassignedSelection,
  onUnassignedSelectionOption,
  showCrew,
  mapView,
  dashBoardCard,
  selectedWO,
  onItemDragStart,
  onItemDragEnd,
  draggingAllowed
}) => {
  return (
    <>
      {view === 'List' ? (
        <>
        <div style={{ display: 'flex' }}>
          <div
            className='crewTitleColorScheduler'
            style={{
              // background: `${Config.priorityColors[woData.priority] ?? '#cccccc'}`,
              background: `${woData.priorityColor || '#cccccc'}`,
              height: '100px'
            }}
          ></div>
           <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
          <div>
            {schedulerlistDraggingAllowed ? (
              <div
                key={woData.Workordernumber}
                draggable={!isMapListItem}
                onDragStart={onDragStart ? (e: any) => onDragStart(e, woData) : undefined}
                // style={{ width: '55%', float: 'left' }}
              >
                <div
                  className='schedulerCardTitle'
                  title={woData.text}
                  style={{ height: '23px', display: isMapListItem ? '-webkit-box' : '' }}
                >
                  {woData.text}
                </div>
                <div className='displayFlex'>
                  <div style={{ fontSize: '12px', color: '#999' }}>
                    {woData.Workordernumber} {woData.erpWorkorder ? ` (${woData.erpWorkorder})` : ''}
                  </div>
                  {woData.fieldstatus && (
                    <div style={{ fontSize: '12px', color: Config.statusColors[woData.fieldstatus] }}>
                      &nbsp;|&nbsp;{woData.fieldstatus}
                    </div>
                  )}
                  <div className='schedulerCardSubtitle' title={woData.location}>
                    &nbsp;|&nbsp;{woData.suburb}
                  </div>
                </div>
              </div>
            ) : (
              <Draggable
                key={woData.text}
                // style={{ width: '55%', float: 'left' }}
                clone={true}
                group={draggingGroupName}
                data={woData}
                //onDragStart={(e: any) => onDragStart(e)}
                onDragEnd={onDragEnd ? (e: any) => onDragEnd(e) : undefined}
              >
                <div className='schedulerCardTitle' title={woData.text}>
                  {woData.text}
                </div>
                <div className='displayFlex'>
                  <div style={{ fontSize: '12px', color: '#999' }}>
                    {woData.Workordernumber} {woData.erpWorkorder ? ` (${woData.erpWorkorder})` : ''}
                  </div>
                  {woData.fieldstatus && (
                    <div style={{ fontSize: '12px', color: Config.statusColors[woData.fieldstatus] }}>
                      &nbsp;|&nbsp;{woData.fieldstatus}
                    </div>
                  )}
                  <div className='schedulerCardSubtitle' title={woData.location}>
                    &nbsp;|&nbsp;{woData.suburb}
                  </div>
                </div>
              </Draggable>
            )}
          
          
          <div style={{display:'flex', alignItems:'center',marginTop:'8px'}}>
            <IconButton onClick={viewItem ? () => viewItem(woData) : undefined}>
              <InfoOutlinedIcon className={'schedulerCradIcon'} titleAccess='View Detail' />
            </IconButton>

            {!mapView && (
              <IconButton onClick={zoomToSelectWO ? () => zoomToSelectWO(woData) : undefined}>
                <RoomIcon className={'schedulerCradIcon'} titleAccess='View on Map' />
              </IconButton>
            )}
            <IconButton onClick={viewWO ? () => viewWO(woData) : undefined}>
              <ViewListIcon className={'schedulerCradIcon'} titleAccess='Go to Detail Screen' />
            </IconButton>

            {showUnassignWO && (
              <IconButton onClick={unassignWO ? () => unassignWO(woData) : undefined}>
                <UndoIcon className='schedulerCradIcon link' titleAccess='Unassign Workorder' />
              </IconButton>
            )}

            {woData.fieldstatus === global.Unassigned && onUnassignedSelectionOption && (
              <div style={{ float: 'right', margin: '6px' }}>
                <CheckBoxInput
                  value={woData.selectedWO}
                  onValueChanged={onUnassignedSelection ? () => onUnassignedSelection(woData) : undefined}
                />
              </div>
            )}
          </div>
          </div>
          </div>
          </div>
        </>
      ) : (
        <div style={{ textAlign: 'left' }} className={selectedWO ? 'SelectSchedulerCard' : ''}>
          <Grid container spacing={1} style={{ borderLeft: `8px solid ${Config.priorityColors[woData.priority]}` }}>
            <Grid item xs={12} sm={12}>
              <div className='schedulerCardWO displayFlex'>
                <div style={{ width: '100%' }}>
                  {woData.Workordernumber} {woData.erpWorkorder ? ` (${woData.erpWorkorder})` : ''}
                </div>
                {woData.fieldstatus && (
                  <div
                    className='schedulerStatusWrapper'
                    style={{
                      color: Config.statusColors[woData.fieldstatus],
                      border: `2px solid ${Config.statusColors[woData.fieldstatus]}`
                    }}
                  >
                    {woData.fieldstatus}
                  </div>
                )}
              </div>
              {schedulerlistDraggingAllowed ? (
                <div
                  className='schedulerCardTitle'
                  title={woData.text}
                  key={woData.Workordernumber}
                  draggable
                  onDragStart={onDragStart ? (e: any) => onDragStart(e, woData) : undefined}
                >
                  {woData.text}
                </div>
              ) : draggingAllowed ? (
                <Draggable
                  key={woData.text}
                  clone={true}
                  group={draggingGroupName}
                  data={woData}
                  onDragStart={onItemDragStart ? (e: any) => onItemDragStart(e) : undefined}
                  onDragEnd={onItemDragEnd ? (e: any) => onItemDragEnd(e) : undefined}
                >
                  <div className='schedulerCardTitle' title={woData.text}>
                    {woData.text}
                  </div>
                </Draggable>
              ) : (
                <div className='schedulerCardTitle' title={woData.text}>
                  {woData.text}
                </div>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <div className='schedulerCardLocation'>
                <span className='mr-5'>
                  <RoomIcon className='schedulerCardLocationIcon' />
                </span>
                {woData.location}
              </div>
            </Grid>
            {showCrew && (
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <div className='schedulerCardCrew'>{woData.crewname}</div>
              </Grid>
            )}
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <div className='schedulerCardDueDate'>
                <span className='mr-5'>
                  <EventNoteIcon className='schedulerCardIcon' />
                </span>
                {moment(woData.duedate).format('MMM DD, YYYY')}
              </div>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <div className='schedulerCardPriority'>
                <span className='mr-5'>
                  <FlagIcon className='schedulerCardIcon' />
                </span>
                {woData.priority}
              </div>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <div className='schedulerCardAssignedTo'>
                <span className='mr-5'>
                  <AssignmentIndIcon className='schedulerCardIcon' />
                </span>
                {woData.assignedto}
              </div>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <div className='schedulerCardPhone'>
                <span className='mr-5'>
                  <PhoneIcon className='schedulerCardIcon' />
                </span>
                {woData.phone}
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <div className='schedulerCardDesc'>{woData.description}</div>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <div className='schedulerCardActions'>
                <div>
                  <InfoOutlinedIcon
                    className='schedulerCardActionIcon'
                    titleAccess='View Detail'
                    onClick={viewItem ? () => viewItem(woData) : undefined}>
                  </InfoOutlinedIcon>
                  {!mapView && (
                    <RoomIcon
                      className='schedulerCardActionIcon'
                      titleAccess='View on Map'
                      onClick={zoomToSelectWO ? () => zoomToSelectWO(woData) : undefined}
                    />
                  )}
                  <ViewListIcon
                    className='schedulerCardActionIcon'
                    titleAccess='Go to Detail Screen'
                    onClick={viewWO ? () => viewWO(woData) : undefined}
                  />
                  {showUnassignWO && (
                    <UndoIcon
                      className='schedulerCardActionIcon link'
                      titleAccess='Unassign Workorder'
                      onClick={unassignWO ? () => unassignWO(woData): undefined}
                    />
                  )}
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      )}
    </>
  )
}

export default SchedulerCard
