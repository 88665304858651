import React, { useState, useEffect } from 'react'
import moment from 'moment'
import DScheduler, { Resource, View, AppointmentDragging, Scrolling } from 'devextreme-react/scheduler'
//import Appointment from './Support/appointment';
import Draggable from 'devextreme-react/draggable'
import {NavLink} from "react-router-dom";
import { Config } from '../../classes/config'
import utility from '../../classes/utility'
import AlertDialog from '../Common/AlertDialog'
import MyMapComponent from '../Common/MapComponent/GoogleMapComponent'
import ListView from './Support/ListView'
import IconButton from '@mui/material/IconButton'
import Icon from '@mui/material/Icon'
import Grid from '@mui/material/Grid'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from '../Common/TabPanel'
import { Input, Typography } from '@mui/material'
import Paper from '@mui/material/Paper'
import Slider from '@mui/material/Slider'
import Popover from '@mui/material/Popover'
import Box from '@mui/material/Box'
import global from '../../classes/global'

import DetailsModal from './Support/SchedulerDetailsModal'
import SchedulerCard from './Support/SchedulerCard'
import SchedulerCrewListView from './Support/SchedulerCrewListView'
import SchedulerWorkorderListView from './Support/SchedulerWorkorderListView'
import AssignMultiple from './Support/AssignedMultiple'
import MapSchedulerList from './Support/MapSchedulerList'
import MapModal from '../Map/Map'

import CheckBoxInput from '../Common/ReusableComponents/CheckBox'
import Resizable from 'devextreme-react/resizable'
import Spinner from '../Common/ReusableComponents/Spinner'
import SelectBoxInput from '../Common/ReusableComponents/SelectInputBox'
import MultiSelect from '../Common/ReusableComponents/MultiSelectBox'
import SpeedDialAction from 'devextreme-react/speed-dial-action'
import Tooltip from '@mui/material/Tooltip'
import 'devextreme/dist/css/dx.light.css'

import SettingsIcon from '@mui/icons-material/Settings'
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import EventNoteIcon from '@mui/icons-material/EventNote';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import ListAltIcon from '@mui/icons-material/ListAlt';
import ViewCompactIcon from '@mui/icons-material/ViewCompact';
import RefreshIcon from '@mui/icons-material/Refresh';
import { set } from 'react-hook-form';

let AssignedworkOrdersFromList: any = []
let UnassignedworkOrdersFromList: any = []

const groups = ['crew']
const draggingGroupName = 'workorderDataGroup'


interface WOProps {
  role?: any
  Department?: any
  currentUser?:any
  pageListItems?:any
  isContractor?:any
  team?:any
}

interface Department {
  id: number
  title: string
  show_on_scheduler: boolean
  is_active: boolean
}

interface Team {
  id: string
  text: string
}

interface StateType {
  loading?: boolean
  selectedMapBtnAssign?: boolean
  selectedMapBtnUnassign?: boolean
  statusGroup?: any
  WOMarker?: any
  workordersForMap?: any[]
  callAfterInitMap?: boolean
  callAfterInit?: boolean
  crewteam?: any[]
  //crewItems?: any[]
  onCrewSelected?: boolean
  selectedCrewInSchedulerList?: any
  selectedWorkorderbyCrew?: any[]
}

interface StateViewType {
  viewOne: boolean
  viewTwo: boolean
  horizontalTopPanes: any[]
  horizontalBottomPanes: any[]
  schedulerViewChange: string
  scheduleResizableWidth: null | number
}

const Scheduler: React.FC<WOProps> = props => {
  const [resources, setResources] = useState<any[]>([])
  const [crewItems, setCrewItems] = useState<any[]>([])
  const [selectedDeptKey, setSelectedDeptKey] = useState<any>(
    props.Department?.id ? props.Department?.id : ''
  )
  const [StatusItems, setStatusItems] = useState<any[]>([])
  const [currentUser, setCurrentUser] = useState<any>()
  const [setupDataPromise, setSetupDataPromise] = useState<any[]>([])
  const [interval, setInterval] = useState<any>()

  const [currentDate, setCurrentDate] = useState(new Date())
  const [currentView, setCurrentView] = useState<any>('Work Week')
  const [workorderData, setWorkorderData] = useState<any[]>([])
  const [unassignedWorkorderData, setUnassignedWorkorderData] = useState<any[]>([])
  // const [workordersForMap, setWorkordersForMap] = useState<any[]>([])
  const [zoom, setZoom] = useState<number>(8)
  const [center, setCenter] = useState<{ lat: number; lng: number }>({ lat: -34.92866, lng: 138.59863 })
  const [selectedMapBtnCrew, setSelectedMapBtnCrew] = useState<boolean>(true)
  //const [selectedMapBtnAssign, setSelectedMapBtnAssign] = useState<boolean>(true)
  //const [selectedMapBtnUnassign, setSelectedMapBtnUnassign] = useState<boolean>(true)
  // const [loading, setLoading] = useState<boolean>(true)
  const [selected, setSelected] = useState<number>(0)
  const [progressLine, setProgressLine] = useState<number>(0)
  const [showweekend, setShowWeekend] = useState<boolean>(false)
  const [schedulerSplitterView, setSchedulerSplitterView] = useState<string | null>('viewTwo')
  const [workingHour, setWorkingHour] = useState<number[]>([9, 17])
  const [schedulerViewChange, setSchedulerViewChange] = useState<string>('list')
  const [cardView, setCardView] = useState<string | null>('List')
  // const [onCrewSelected, setOnCrewSelected] = useState<boolean>(false)
  const [unAssignedResizableWidth, setUnAssignedResizableWidth] = useState<string>('300px')
  const [useStyle, setUseStyle] = useState<boolean>(localStorage.getItem('theme') === 'theme-dark')
  const [alertMessage, setAlertMessage] = useState<string | JSX.Element>('')
  const [hideAlertButton, setHideAlertButton] = useState<boolean>(true)
  const [showAlert, setShowAlert] = useState<boolean>(false)
  const [deptOptions, setDeptOptions] = useState<any[]>([])
  const [department, setDepartment] = useState<any>(props.Department)
  const [goHome, setGoHome] = useState<boolean>(false)
  const [teamsItems, setTeamsItems] = useState<Team[]>([])
  const [allTeamsItems, setAllTeamsItems] = useState<Team[]>([])
  const [team, setTeam] = useState<Team>()
  const [allCrewItems, setAllCrewItems] = useState<any[]>([])
  const [dateRange, setDateRange] = useState<any>(null)
  const [woType, setWoType] = useState<string>('')
  const [completedchk, setCompletedchk] = useState<boolean>(false)
  const [schedulerGridDivWidth, setSchedulerGridDivWidth] = useState<any>('100%')
  const [maxSchedulerWidth, setMaxSchedulerWidth] = useState<number>(100)
  const [schedulerGridDivWidth1, setSchedulerGridDivWidth1] = useState<boolean>(false)
  const [isNarrow, setIsNarrow] = useState<boolean>(false)
  const [openMapModal, setOpenMapModal] = useState<boolean>(false)
  const [showAssignMultipleModal, setShowAssignMultipleModal] = useState<boolean>(false)
  const [multiSelectbyDragDrop, setMultiSelectbyDragDrop] = useState<boolean>(false)
  const [selectedWOItems, setSelectedWOItems] = useState<any[]>([])
  const [filterWordOrders, setFilterWordOrders] = useState<string>('')
  const [selectedCrewInSchedulerListforCrewListDrop, setSelectedCrewInSchedulerListforCrewListDrop] =
    useState<any>(null)
  // const [selectedCrewInSchedulerList, setSelectedCrewInSchedulerList] = useState<any>(null)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [showMoreSettingMenu, setShowMoreSettingMenu] = useState(false)
  const [crewLocation, setCrewLocation] = useState<string>('')
  const [address, setAddress] = useState<string>('')
  const [showColorLegend, setShowColorLegend] = useState<boolean>(false)
  const [details, setDetails] = useState<any>('')
  const [showModal, setShowModal] = useState<boolean>(false)

  const [state, setState] = useState<StateType>({
    loading: false,
    selectedMapBtnAssign: false,
    selectedMapBtnUnassign: false,
    statusGroup: {},
    WOMarker: {},
    workordersForMap: [],
    callAfterInitMap: false,
    callAfterInit: false,
    crewteam: [],
    //crewItems: [],
    onCrewSelected: false,
    selectedWorkorderbyCrew: []
  })

  const [viewState, setViewState] = useState<StateViewType>({
    viewOne: false,
    viewTwo: true,
    horizontalTopPanes: [],
    horizontalBottomPanes: [],
    schedulerViewChange: '',
    scheduleResizableWidth: null
  })

  useEffect(() => {
    const currentDetail = localStorage.getItem('currentDetail')
    if (!currentDetail) {
      //window.open('/#/SignIn', '_self');
    }
    if (props.role === global.worxOnline_Reader) {
      window.open('#/AccessDenied', '_self')
    }
    if (!props.Department) {
      window.open('#/AccessRequest', '_self')
    }
    setCurrentUser(props.currentUser)
    //setState(prevState => ({ ...prevState, crewItems: [] }))
    //setSelectedDeptKey(props.Department?.id ? props.Department?.id : deptTestData.id)
    setCardView(localStorage.getItem('cardView') ? localStorage.getItem('cardView') : 'List')
    setSchedulerSplitterView(
      localStorage.getItem('schedulerSplitterView') ? localStorage.getItem('schedulerSplitterView') : 'viewOne'
    )
    if (window.innerWidth < 1300) {
      setSchedulerSplitterView('viewTwo')
    }
  }, [])

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    try {
      await _getStatusDataAsync()
      await getDepartments()
    } catch (err) {
      console.log(err)
    }
  }

  const _getStatusDataAsync = async () => {
    try {
      const data: any = await utility.genericGetAPICall(Config.statuslist)
      const woStatusItems: any = data.map((r: any) => ({ id: r.id, text: r.title }))
      setStatusItems(woStatusItems)
    } catch (err) {
      console.log(err)
    }
  }

  const getDepartments = async () => {
   setState(prevState => ({ ...prevState, loading: true }))
    setAlertMessage(<Spinner label='Please wait...' />)
    setHideAlertButton(true)
    setShowAlert(true)

    try {
      const data = await utility.genericGetAPICall(Config.DepartmentList)
      const deptList = data

      const deptComboBoxOptions: any[] = deptList.filter(
        (element: any) => element.show_on_scheduler && element.is_active
      )

      const showOnScheduler: any = deptComboBoxOptions.some((d: Department) => d.id === department.id)
      // if (deptComboBoxOptions) {
      //const selectedDept = deptComboBoxOptions.find((d: Department) => d.id === props.Department.id)
      //const selectedDept: any = deptComboBoxOptions.find((d: Department) => d.id === department.id)
      if (showOnScheduler) {
        setDeptOptions(deptComboBoxOptions)
        setDepartment(department)
        //setSelectedDeptKey(deptTestData.id)
        //await getTeamsAndCrew(deptTestData.id)
        //}
      } else {
        setAlertMessage(
          'Oops! Looks like your department is not eligible to use Scheduler. Please contact site admin if you want to access the Scheduler'
        )
        setHideAlertButton(false)
        setShowAlert(true)
        setGoHome(true)
        setState(prevState => ({ ...prevState, loading: false }))
      }
    } catch (err) {
      console.log(err)
      setState(prevState => ({ ...prevState, loading: false }))
    }
  }

  const getTeamsAndCrew = async (deptID: number) => {
    setState(prevState => ({ ...prevState, loading: true }))
    const dataToPassToService: any = {}
    dataToPassToService.department_id = [deptID]
    try {
      const data = await utility.genericPostAPICall(Config.searchCrewDeptTeam, dataToPassToService)

      const teamsItems: Team[] = []
      const crewItemsData: any[] = []
      setSetupDataPromise([])

      setTeam({ id: 'All', text: 'All' })
      teamsItems.push({ id: 'All', text: 'All' })

      const uniqueTeams = new Set()
      const uniqueCrews = new Set()

      data.forEach((r: any) => {
        if (!uniqueTeams.has(r.team_id)) {
          teamsItems.push({ id: r.team_id, text: r.team_name })
          uniqueTeams.add(r.team_id)
        }

        if (!uniqueCrews.has(r.crew_id)) {
          crewItemsData.push({
            id: r.crew_id,
            text: `${r.crew_name}`,
            'Select All': `${r.crew_name}`,
            color: r.crew_colour ? r.crew_colour : '#90C3D4',
            teamID: r.team_id,
            teamName: r.team_name,
            loading: false
          })
          uniqueCrews.add(r.crew_id)
        }
      })

      const sortedItems = utility._sortItems(teamsItems, 'text', false)
      setTeamsItems(sortedItems)
      setAllTeamsItems(sortedItems)
      setResources(crewItemsData)
      setCrewItems(crewItemsData)
      setAllCrewItems(crewItemsData)

      //await Promise.all(setupDataPromise)

      //showFilterMapIcon()
      await getWorkOrdersForDepartment(currentDate, currentView, false)
    } catch (err) {
      console.log(err)
      setState(prevState => ({ ...prevState, loading: false }))
    }
  }

  // const getCrew = () => {
  //   setAlertMessage(<Spinner label='Please wait...' />)
  //   setHideAlertButton(true)
  //   setShowAlert(true)
  //   setLoading(true)

  //   let dataToPassToService: any = {}

  //   dataToPassToService.departmentId = +selectedDeptKey ? selectedDeptKey : 4
  //   utility.genericPostAPIcall(Config.SearchCrewApi, dataToPassToService).then(
  //     (data: any) => {
  //       let crews = data
  //       let resources: any = []
  //       let crewItemsData: any = []
  //       crews.forEach((element: any) => {
  //         crewItemsData.push({
  //           id: element.id,
  //           text: `${element.title}`,
  //           'Select All': `${element.title}`,
  //           color: element.mapColour ? element.mapColour : '#90C3D4',
  //           teamID: element.teamId,
  //           teamName: element.teamName,
  //           loading: false
  //         })
  //       })
  //       setResources(crewItemsData)
  //       setState(prevState => ({ ...prevState, crewItems: crewItemsData }))
  //       setAllCrewItems(crewItemsData)

  //       let setupDataPromise: any = []
  //       //getWorkOrdersForDepartment(currentDate, currentView, false)
  //       getUnassignedWorkOrders()
  //       Promise.all(setupDataPromise).then(() => {
  //         showFilterMapIcon()
  //       })
  //     },
  //     err => {
  //       console.log(err)
  //     }
  //   )
  // }

  const getWorkOrders = () => {
    setSetupDataPromise([])
    setWorkorderData([])
    setUnassignedWorkorderData([])
    getWorkOrdersForDepartment(currentDate, currentView, true)
    //getUnassignedWorkOrders()
    Promise.all(setupDataPromise).then(() => {
      setTimeout(() => showFilterMapIcon(), 500)
      // showFilterMapIcon();
    })
  }

  const getWorkOrdersForDepartment = async (date?: any, day?: any, refresh?: any) => {
    //setShowAlert(true)
    //setAlertMessage(<Spinner label='Please wait...' height={40} width={40} size={'medium'} />)
    //setHideAlertButton(true)

    // const wostatus: string[] = []

    // if (StatusItems) {
    //   StatusItems.forEach((s: any) => {
    //     if (s.text !== global.Unassigned) {
    //       wostatus.push(s.id)
    //     }
    //   })
    // }
    
    const dataToPassToService: any = {}

    const tempDate = date ? date : new Date()
    const tempDay = day ? day : 'Day'
    const range = getRange(tempDate, tempDay)

    const startDate = range ? range.startDate : new Date()
    startDate.setHours(0, 0, 0, 0)
    let endDate = range ? range.endDate : new Date()
    if (tempDay === 'Day' || tempDay === 'agenda') {
      endDate = new Date(endDate)
      endDate.setDate(endDate.getDate() + 2)
    }
    dataToPassToService.department_id = [selectedDeptKey ? +selectedDeptKey : department.id]
    //dataToPassToServiceUnassigned.scheduledStartFrom = startDate.toISOString().substring(0, 10) + 'T00:00:00.000Z'
    //dataToPassToServiceUnassigned.workorder_status_id = wostatus
    //dataToPassToServiceUnassigned.scheduledStartTo = endDate.toISOString().substring(0, 10) + 'T23:59:59.000Z'

    const searchAPI = `${Config.WOSearchAPI}`
    try {
      const results: any = await utility.genericPostAPICall(searchAPI, dataToPassToService)

      const assignedWorkorderListData: any[] = results.filter(
        (element: any) => element.workorder_status?.title !== global.Unassigned
      )

      const unassignedWorkorderListData: any[] = results.filter(
        (element: any) =>
          element.workorder_status?.title === global.Unassigned || element.workorder_status?.title === global.Reassigned
      )
      const AssignedworkOrdersFromList: any[] = []
      const UnassignedworkOrdersFromList: any[] = []

      const assignedIds = new Set<number>()
      const unassignedIds = new Set<number>()

      //Assigned Workorders
      if (assignedWorkorderListData.length === 0) {
        const tempStartDate = new Date()
        const tempEndDate = new Date()
        tempStartDate.setHours(0, 0, 0, 0)
        tempEndDate.setHours(24, 0, 0, 0)
        AssignedworkOrdersFromList.push({
          text: 'No Work Order Found',
          startDate: tempStartDate,
          endDate: tempEndDate,
          hideInMap: true
        })
      } else {
        assignedWorkorderListData.forEach((item: any) => {
          if (item.id && !assignedIds.has(item.id)) {
            assignedIds.add(item.id)
            let enddate = item.date_scheduled_end ? new Date(item.date_scheduled_end) : new Date(item.date_scheduled_start)
            if (!item.date_scheduled_end) enddate.setHours(enddate.getHours() + 1)

          const bgColor = 'yellow'
          let lat
          let lng
          if (item.locationLatLong) {
            lat = item.locationLatLong.y
            lng = item.locationLatLong.x
          }
          let newWorkorder = false
          if (item.created) {
            const tempDate = new Date()
            tempDate.setHours(tempDate.getHours() - 1)
            if (new Date(item.created).getTime() >= tempDate.getTime()) {
              newWorkorder = true
            }
          }
          if (item.id) {
            //let tempstreetAddress
            AssignedworkOrdersFromList.push({
              key: item.id,
              id: item.id,
              servicerequest: item.work_request?.id,
              Workordernumber: item.id,
              text: item.title,
              priority: item.priority?.title,
              priorityColor: item.priority?.color_code,
              worktype: item.workorder_type?.title,
              crewtitle: item.crew?.title,
              crew: item.crew?.id,
              crewMapColor: item.crew?.map_color,
              createddate: item.created,
              eststartdate: item.date_scheduled_start,
              fieldstatus: item.workorder_status?.title,
              wostatus: item.workorder_status?.title,
              contractorstatus: item.status,
              location: item.workorder_location,
              startDate: startDate,
              endDate: enddate,
              bgColor: bgColor,
              lat: lat ? lat : '',
              long: lng ? lng : '',
              WOComments: item.comments,
              AllowEditing: false,
              newCreated: newWorkorder,
              selectedWO: false,
              address: item.address_street_name,
              assetIds: item.asset_ids ? item.asset_ids : [item.asset_id]
            })
          }
        } 
        })
      }

      //UnAssigned workorders
      if (unassignedWorkorderListData.length > 0) {
        unassignedWorkorderListData.forEach((item: any) => {
          if (item.id && !unassignedIds.has(item.id)) {
            unassignedIds.add(item.id)
          let lat
          let lng
          if (item.locationLatLong) {
            lat = item.locationLatLong.y
            lng = item.locationLatLong.x
          }

          let newWorkorder = false
          if (item.created) {
            const tempDate = new Date()
            tempDate.setHours(tempDate.getHours() - 1)
            if (new Date(item.created).getTime() >= tempDate.getTime()) {
              newWorkorder = true
            }
          }
          if (item.id) {
            UnassignedworkOrdersFromList.push({
              key: item.id,
              id: item.id,
              servicerequest: item.work_request?.id,
              Workordernumber: item.id,
              text: item.title,
              priority: item.priority?.title,
              priorityColor: item.priority?.color_code,
              worktype: item.workorder_type?.title,
              crew: item.crew?.id,
              // crewColor: <div className="crewTitleColorScheduler" style={{backgroundColor:getCrewColor(item)}}> </div>,
              crewMapColor: <div className="crewTitleColorScheduler" style={{backgroundColor:item.crew?.map_color}}> </div>,
              createddate: item.created,
              eststartdate: item.date_scheduled_start,
              fieldstatus: item.workorder_status?.title,
              wostatus: item.workorder_status?.title,
              contractorstatus: item.workorder_status?.title,
              // suburb: tempAddress,
              location: item.workorder_location,
              startDate: item.date_scheduled_start ? new Date(item.date_scheduled_start) : null,
              endDate: item.date_scheduled_end ? new Date(item.date_scheduled_end) : null,
              lat: lat ? lat : '',
              long: lng ? lng : '',
              WOComments: item.comments,
              newCreated: newWorkorder,
              selectedWO: false,
              assetIds: item.asset_ids ? item.asset_ids : [item.asset_id]
            })
          }
        }
        })
        utility._sortItems(UnassignedworkOrdersFromList, 'id', true)
      }

      if (refresh) {
        setTimeout(() => showFilterMapIcon(), 200)
      }

      setShowAlert(false)
      setWorkorderData(AssignedworkOrdersFromList)
      setUnassignedWorkorderData(UnassignedworkOrdersFromList)
      setDateRange(range ? range : {startDate: startDate, endDate: endDate})
      setState(prevState => ({ ...prevState, onCrewSelected: true, loading: false }))
      setTimeout(() => showFilterMapIcon(), 200)
    } catch (err) {
      console.log(err)
      setAlertMessage('Something went wrong! Unable to get assigned work orders')
      setHideAlertButton(false)
      setShowAlert(true)
      setState(prevState => ({ ...prevState, loading: false }))
    }
  }

  const handleSelectTabStrip = (e: any) => {
    const selected: any = e.selected
    if (e.selected === 1) {
      setWoType('')
      setCompletedchk(false)
    }
  }

  const getObjectKeys = (obj: any) => {
    let newArray: any = []
    if (obj) {
      newArray = Object.keys(obj)
    }
    return newArray
  }

  useEffect(() => {
    if (window.document.getElementsByClassName('MuiDrawer-paper').length > 0 && !schedulerGridDivWidth1) {
      const drawerWidth = window.document.getElementsByClassName('MuiDrawer-paper')[0].clientWidth
      const newSchedulerGridDivWidth = window.innerWidth - drawerWidth
      setSchedulerGridDivWidth(newSchedulerGridDivWidth)
      setMaxSchedulerWidth(newSchedulerGridDivWidth)
      if (schedulerSplitterView === 'viewOne') {
        setSchedulerGridDivWidth(schedulerGridDivWidth - 400)
        setMaxSchedulerWidth(schedulerGridDivWidth)
      }
      setSchedulerGridDivWidth(schedulerGridDivWidth + 'px')
      setSchedulerGridDivWidth1(true)
    }

    const newIsNarrow = window.innerWidth < 984
    setIsNarrow(newIsNarrow)
  }, [schedulerGridDivWidth1])

  const renderMapView = (showList?: any, isNarrow?: boolean) => {
    const statusKeys = state.statusGroup && Object.keys(state.statusGroup)

    return (
      <div>
        {!state.loading ? (
          <>
            <div className='mapBtnGroupContainer'>
              <div className='mapBtnGroup'>
                {/* <div className={selectedMapBtnCrew?"selectedMapBtn":''} onClick={()=>filerMapIcon("selectedMapBtnCrew")}>Crew</div> */}
                <div className='mapBtnAssign selectedMapBtn'>
                  {' '}
                  <CheckBoxInput
                    value={state.selectedMapBtnAssign}
                    onValueChanged={() => filerMapIcon('selectedMapBtnAssign')}
                    text={global.Assigned}
                  />
                </div>
                <div className='mapBtnAssign selectedMapBtn'>
                  {' '}
                  <CheckBoxInput
                    value={state.selectedMapBtnUnassign}
                    onValueChanged={() => filerMapIcon('selectedMapBtnUnassign')}
                    text={global.Unassigned}
                  />
                </div>
                {zoom > 8 && (
                  <Tooltip title='Zoom out'>
                    <IconButton onClick={() => zoomOutWO()}>
                    <ZoomOutMapIcon titleAccess="Zoom Out" />
                    </IconButton>
                  </Tooltip>
                )}
              </div>
            </div>
            <div className='legendWrapper'>
              {statusKeys &&
                statusKeys
                  .filter((status: any) => status !== 'undefined' && status !== 'null')
                  .map((status: any, index:number) => (
                    <div key={index} className='legendDiv' style={{ backgroundColor: Config.statusColors[status] }}>
                      <span className='legendContrastColor'>{status}</span>
                    </div>
                  ))}
              {selectedWOItems && selectedWOItems.length > 0 && (
                <div className='legendDiv' style={{ backgroundColor: '#888888' }}>
                  Selected Workorder
                </div>
              )}
            </div>
            {showList && (
              <div className={isNarrow ? 'listOnMapWrapperNarrow' : 'listOnMapWrapper'}>
                <MapSchedulerList
                  items={state.workordersForMap}
                  viewItem={(e: any) => viewDetials(e)}
                  viewWO={(e: any) => viewWO(e)}
                  zoomToSelectWO={(e: any) => zoomToSelectWO(e, false)}
                  callAfterInit={state.callAfterInit}
                  stopCommsAfterInit={() => stopCommsAfterInit()}
                  view={cardView}
                  onUnassignedSelection={(e: any) => onUnassignedSelection(e)}
                  onUnassignedSelectionOption={false}
                  schedulerlistDraggingAllowed={false}
                  unassignWO={(e: any) => unassignWO(e)}
                />
              </div>
            )}
            <MyMapComponent
              schedulerMarkers={state.WOMarker}
              useStyle={useStyle}
              showSchedulerMarker={true}
              center={center}
              zoom={zoom}
              crewLocation={crewLocation}
              draggingGroupName={draggingGroupName}
              onListDragStart={(e: any) => onListDragStart(e)}
              items={unassignedWorkorderData}
              onItemDragStart={(e: any) => onItemDragStart(e)}
              onItemDragEnd={(e: any) => onItemDragEnd(e)}
              viewItem={(e: any) => viewDetials(e)}
              viewWO={(e: any) => viewWO(e)}
              zoomToSelectWO={(e: any) => zoomToSelectWO(e, false)}
              callAfterInit={state.callAfterInitMap}
              stopCommsAfterInit={() => stopCommsAfterInit()}
              view={cardView}
              onUnassignedSelection={(e: any) => onUnassignedSelection(e)}
              onUnassignedSelectionOption={false}
              showMarker={true}
              showMap={true}
            />
          </>
        ) : (
          <>
            <br />
            <Spinner size='large' height={60} width={60} />
          </>
        )}
      </div>
    )
  }

  const renderListView = (schedulerlistDraggingAllowed: any, onUnassignedSelectionOption: any, showOnMap?: any) => {
    return (
      <>
        <div className={'list-title ptlr-20'}>Unassigned Work Orders ({unassignedWorkorderData.length})</div>

        {unassignedWorkorderData?.length > 0 ? (
          // <Draggable id='list' data='dropArea' group={draggingGroupName} onDragStart={(e: any) => onListDragStart(e)}>
          <ListView
            items={unassignedWorkorderData}
            draggingGroupName={draggingGroupName}
            onListDragStart={(e: any) => onListDragStart(e)}
            onItemDragStart={(e: any) => onItemDragStart(e)}
            onItemDragEnd={(e: any) => onItemDragEnd(e)}
            viewItem={(e: any) => viewDetials(e)}
            viewWO={(e: any) => viewWO(e)}
            zoomToSelectWO={(e: any) => zoomToSelectWO(e, true)}
            callAfterInit={state.callAfterInit}
            stopCommsAfterInit={() => stopCommsAfterInit()}
            onDragStart={(e: any, woData: any) => onDragStart(e, woData)}
            view={cardView}
            onUnassignedSelection={(e: any) => onUnassignedSelection(e)}
            onUnassignedSelectionOption={onUnassignedSelectionOption}
            schedulerlistDraggingAllowed={schedulerlistDraggingAllowed}
          />
        ) : (
          // </Draggable>
          <div className='ptlr-20 alignCenter'>No data to display</div>
        )}
      </>
    )
  }

  const renderMapModal = () => {
    return (
      <MapModal
        polylines={false}
        center={center}
        showModal={openMapModal}
        zoom={zoom}
        onClick={() => _closeMapModal()}
        address={address}
        crewLocation={crewLocation}
      />
    )
  }

  const _closeMapModal = (): void => {
    setOpenMapModal(false)
  }

  const handleActionButton = () => {
    setShowAssignMultipleModal(true)
  }

  const renderAssignMultipleModal = () => {
    return (
      <AssignMultiple
        showModal={showAssignMultipleModal}
        // selectedWOItems={selectedWOItems}
        selectedCrew={
          selectedCrewInSchedulerListforCrewListDrop
            ? selectedCrewInSchedulerListforCrewListDrop
            : state.selectedCrewInSchedulerList
        }
        StatusItems={StatusItems}
        crewItems={crewItems}
        assignMultipleWO={(item: any) => assignMultipleWO(item)}
        onClose={() => _closeAssignMultiple()}
      />
    )
  }

  const _closeAssignMultiple = () => {
    setShowAssignMultipleModal(false)
    setMultiSelectbyDragDrop(false)
  }

  const assignMultipleWO = async (item: any) => {
    setState(prevState => ({ ...prevState, loading: true }))
    const tempStatus = StatusItems
    const tempFieldStatus = tempStatus.find((r: any) => r.text === global.Assigned)
    const tempUnassignedWorkorderData = unassignedWorkorderData
    const tempWorkorderData = workorderData
    const selectedWOItemsData: any = selectedWOItems

    const crewName = resources.find((r: any) => r.id === item.crew.id)
    const comments = `Work order dispatched to ${crewName.text} from scheduler`

    try {
      const promises: Promise<any>[] = []
      let assetIds: any = []
      selectedWOItemsData.forEach(async (wo: any) => {
        const index = unassignedWorkorderData.findIndex((w: any) => w.id === wo.id)

        if (index >= 0) {
          wo.fieldstatus = global.Assigned
          // wo.assetIds = assetIds
          tempUnassignedWorkorderData.splice(index, 1)
          tempWorkorderData.push(wo)
          
        }
        const dataToUpdate: any = {}
        // dataToUpdate.id = +wo.id
        dataToUpdate.crew_id = item.crew.id
        dataToUpdate.workorder_status_id = tempFieldStatus.id
        dataToUpdate.comments = comments
        //dataToUpdate.asset_ids = wo.assetIds
        dataToUpdate.date_scheduled_start = item.startDate.toISOString()
        dataToUpdate.date_scheduled_end = item.endDate.toISOString()
        showSchedulingPopup()

        promises.push(utility.genericUpdateNatureStripAPICallForWO(Config.UpdateWO, +wo.id, dataToUpdate))
       
      })
      await Promise.all(promises);

      getWorkOrders()
      setHideAlertButton(false)
      setShowAlert(false)
      setSelectedWOItems([])
      setShowAssignMultipleModal(false)
      setMultiSelectbyDragDrop(false)
    } catch (err: any) {
      console.log(err)
      showErrorSchedulingPopup()
    }
  }

  const createItems = (forAll?: any) => {
    const taskListC: any = []
    if (workorderData && unassignedWorkorderData) {
      let tasks = workorderData.concat(unassignedWorkorderData)
      if (!forAll) {
        tasks = tasks.filter((t: any) => t.fieldStatus === global.Unassigned)
      }
      tasks.forEach((item: any) => {
        // }
        taskListC.push({
          key: item.id,
          id: item.id,
          text: item.text,
          worktype: item.worktype,
          Workordernumber: item.Workordernumber,
          location: item.location,
          // suburb: tempAddress,
          priority: item.priority,
          priorityColor: item.priority?.color_code,
          lat: item.lat,
          long: item.long,
          fieldstatus: item.fieldstatus,
          wostatus: item.wostatus,
          contractorstatus: item.contractorstatus,
          servicerequest: item.servicerequest,
          selectedWO: item.selectedWO,
          WOComments: item.WOComments,
          // crewColor: (
          //   <div className='crewTitleColorScheduler' style={{ backgroundColor: getCrewColor(item) }}>
          //     {' '}
          //   </div>
          // )
          crewColor: (
            <div className='crewTitleColorScheduler' style={{ backgroundColor: item.crewMapColor }}>
              {' '}
            </div>
          )
        })
      })
    }
    return taskListC
  }

  const getCrewColor = (item: any) => {
    let crewColor = '#000'
    if (item.crew) {
      const tempCrewColor = resources.find((r: any) => r.id === item.crew?.id)

      if (tempCrewColor) {
        crewColor = tempCrewColor.color
      }
    }
    return crewColor
  }

  useEffect(() => {
    showFilterMapIcon();
  }, [state.selectedMapBtnAssign, state.selectedMapBtnUnassign]);

  const filerMapIcon = (view: keyof StateType) => {
    setState(prevState => ({ 
      ...prevState, 
      [view]: !prevState[view], 
      loading: true 
    }))
    //setTimeout(showFilterMapIcon, 500)
  }

  const showFilterMapIcon = () => {
    let filterdWOMarker: any = []
    let filterOption: any = ''
    let tempWOMarker: any = []
    let resultGroup: any = null
    if (state.selectedMapBtnAssign && state.selectedMapBtnUnassign) {
      tempWOMarker = utility._sortItems(createItems(true), 'location', false)
      filterdWOMarker = tempWOMarker
      resultGroup = utility.groupBy(tempWOMarker, 'lat')
    } else {
      const showAssing = state.selectedMapBtnAssign
      const showUnassign = state.selectedMapBtnUnassign

      filterOption = showAssing ? global.Assigned : showUnassign ? global.Unassigned : ''

      tempWOMarker = utility._sortItems(createItems(true), 'location', false)

      tempWOMarker.forEach((element: any) => {
        if (element.fieldstatus === filterOption) {
          filterdWOMarker.push(element)
        }
      })
      resultGroup = utility.groupBy(filterdWOMarker, 'lat')
    }
    const statusGroup = utility.groupBy(tempWOMarker, 'contractorstatus')
    filterdWOMarker = utility._sortItems(filterdWOMarker, 'fieldstatus', false)

    setState(prevState => ({ 
      ...prevState, 
      statusGroup: statusGroup,
      WOMarker: resultGroup,
      workordersForMap: filterdWOMarker,
      loading: false,
      callAfterInitMap: true,
      callAfterInit: true
    }))
  }

  const _multiFieldChanged = (event: any, fieldName: string) => {
    if (fieldName === 'crewteam') {
      if (event.length > 0) {
        setResources(event)
      } else if (crewItems) {
        setResources(crewItems)
      }
    }
    setState(prevState => ({
      ...prevState,
      [fieldName]: event,
      onCrewSelected: true,
      selectedWorkorderbyCrew: []
    }))
  }

  const handleWorkingHourChange = (event: any, newValue: any) => {
    if (newValue[0] + 1 < newValue[1]) {
      setWorkingHour(newValue)
    }
  }

  const clearFilterWO = () => {
    setFilterWordOrders('')
    setUnassignedWorkorderData(UnassignedworkOrdersFromList)
    setTimeout(() => setState(prevState => ({ ...prevState, callAfterInit: true })), 500)
  }

  const handleValueChange = (e: any, fieldName: string) => {
    setState(prevState => ({ ...prevState, [fieldName]: e.value }))
  }

  useEffect(() => {
    getTeamsAndCrew(selectedDeptKey)
  }, [selectedDeptKey])

  const handleDropdownChange = async (event: any, fieldName: string) => {
    ////console.log(newValue);
    if (fieldName === 'department' && event.selectedItem) {
      setSelectedDeptKey(event.selectedItem?.id)
      //await getTeamsAndCrew(event.selectedItem?.id)
      setDepartment(event.selectedItem)
    }
    if (fieldName === 'team' && event.selectedItem) {
      setState(prevState => ({ ...prevState, loading: true }))
      const tempCrew = allCrewItems

      const filteredCrew: any = []
      tempCrew.forEach((c: any) => {
        if (c.teamID === (event.selectedItem && event.selectedItem.id)) {
          filteredCrew.push(c)
        }
      })

      setState(prevState => ({
        ...prevState,
        crewteam: [],
        loading: false,
      }))

      setAllCrewItems(
        !event.selectedItem || (event.selectedItem && event.selectedItem.id === 'All') ? tempCrew : filteredCrew
      )
      setResources(
        !event.selectedItem || (event.selectedItem && event.selectedItem.id === 'All') ? tempCrew : filteredCrew
      )
      setTeam(event.selectedItem)
    }
    //const value = event.selectedItem
    // if (!value && fieldName == 'team') {
    //   value = { id: 'All', text: 'All' }
    // }

    setState(prevState => ({
      ...prevState,
      onCrewSelected: true,
      selectedWorkorderbyCrew: []
    }))
  }

  const onHorizontalChange = (fieldName: any, event: any) => {
    setState(prevState => ({ ...prevState, [fieldName]: event.newState }))
  }

  const handleSelect = (event: any, newValue: any) => {
    setSelected(newValue)
  }

  const onResizeDiv = (e: any) => {
    setViewState(prevState => ({ ...prevState, scheduleResizableWidth: e.width }))
  }

  const changeSchedulerSplitterView = (view: string) => {
    if (viewState[view as keyof StateViewType]) {
      return
    }
    setSchedulerSplitterView(view)
    if (view === 'viewOne') {
      setViewState({
        ...viewState,
        viewOne: true,
        viewTwo: false,
        horizontalTopPanes: [{ size: '100%' }, { min: '30%' }],
        horizontalBottomPanes: [{}, {}],
        schedulerViewChange: 'list',
        scheduleResizableWidth: null
      })
      
    }
    if (view === 'viewTwo') {
      setViewState({
        ...viewState,
        viewOne: false,
        viewTwo: true,
        horizontalTopPanes: [{}, {}],
        horizontalBottomPanes: [{ min: '25%' }, { size: '70%', collapsible: true }],
        schedulerViewChange: 'list',
        scheduleResizableWidth: null
      })
      localStorage.setItem('schedulerSplitterView', view)
    }
  }

  const onChangeListItemView = (view: any) => {
    setCardView(view)
    localStorage.setItem('cardView', view)
  }

  const renderAppointment = (model: any) => {
    const { appointmentData } = model.data

    if (currentView === 'agenda') {
      return (
        <div>
          <div className='dx-item-content dx-scheduler-appointment-content'>
            <div className='dx-scheduler-agenda-appointment-left-layout'>
              <div
                className='dx-scheduler-agenda-appointment-marker'
                style={{ backgroundColor: getCrewColor(appointmentData) }}
                // style={{ backgroundColor: 'red' }}
              ></div>
            </div>
            <div className='dx-scheduler-agenda-appointment-right-layout'>
              <div className='dx-scheduler-appointment-title'>{appointmentData.text}</div>
              <div className='dx-scheduler-appointment-content-details'>
                <div className='dx-scheduler-appointment-content-date'>
                  {moment(appointmentData.startDate).format('h:mm a') +
                    '-' +
                    moment(appointmentData.endDate).format('h:mm a')}
                </div>
                <div className='dx-scheduler-appointment-resource-list'>
                  <div className='dx-scheduler-appointment-resource-item'>
                    <div>Status:</div>
                    <div className='dx-scheduler-appointment-resource-item-value'>{appointmentData.fieldstatus}</div>
                  </div>
                  <div className='dx-scheduler-appointment-resource-item'>
                    <div>WO #:</div>
                    <div className='dx-scheduler-appointment-resource-item-value'>
                      {appointmentData.Workordernumber}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      const statusColor = Config.statusColors[appointmentData.fieldstatus]
      return (
        <div className='showtime-preview'>
          <div>
            {' '}
            {appointmentData.text} ({appointmentData.Workordernumber})
          </div>
          <div>
            <div className='schedulerStatusWrapper' style={{ backgroundColor: statusColor ? statusColor : '#ddd' }}>
              <strong>{appointmentData.fieldstatus}</strong>
            </div>
          </div>
          <div></div>
        </div>
      )
    }
  }

  const renderAppointmentTooltip = (model: any) => {
    const woData = model.appointmentData
    return (
      <SchedulerCard
        woData={woData}
        viewItem={(e: any) => viewDetials(e)}
        viewWO={(e: any) => viewWO(e)}
        zoomToSelectWO={(e: any) => zoomToSelectWO(e, true)}
        draggingGroupName={''}
        draggingAllowed={false}
        view={cardView}
        mapView={false}
        showUnassignWO={true}
        unassignWO={() => unassignWO(woData)}
      />
    )
  }

  const onUnassignedSelection = (item: any) => {
    let tempSelectedWOItems = [...selectedWOItems]

    if (item && item.fieldstatus === global.Unassigned) {
      const updatedUnassignedWorkorderData = unassignedWorkorderData.map((woi: any) =>
        woi.id === item.id ? { ...woi, selectedWO: !woi.selectedWO } : woi
      )

      tempSelectedWOItems = updatedUnassignedWorkorderData.filter((unwo: any) => unwo.selectedWO === true)
      setUnassignedWorkorderData(updatedUnassignedWorkorderData)
    }

    setSelectedWOItems(tempSelectedWOItems)
    setState(prevState => ({ ...prevState, loading: true }))
    setWorkorderData([...workorderData])
    AssignedworkOrdersFromList = [...workorderData]
    UnassignedworkOrdersFromList = [...unassignedWorkorderData]

    showFilterMapIcon()
    setHideAlertButton(false)
    setShowAlert(false)
  }

  const unassignWO = (event: any) => {
    if (checkWOStatusInProgress(event.fieldstatus)) {
      let msg = 'This work order is in progress and cannot be modified.'
      if (event.fieldstatus === 'Complete') {
        msg = 'This work order is completed and cannot be modified.'
      }

      setAlertMessage(msg)
      setHideAlertButton(false)
      setShowAlert(true)
      return
    }
    setState(prevState => ({ ...prevState, loading: true }))
    const dataToUpdate: any = {}

    const tempStatus = StatusItems
    const tempFieldStatus = tempStatus.filter((r: any) => r.text === global.Unassigned)
    const fieldStatus = tempFieldStatus[0].id

    const comments = 'Unassigned workorder from scheduler'
    // const Created = `${currentUser.DisplayName} ${utility.cuniq()}`
    // const description = `${event.WOComments}\n [${Created}] ${comments}`;
    // event.WOComments = description;
    // dataToUpdate.id = +event.id
    //dataToUpdate.crewId = -1;
    // dataToUpdate.StatusID = fieldStatus;
    dataToUpdate.workorder_status_id = fieldStatus
    dataToUpdate.comments = comments
    // dataToUpdate.WoComments = description;
    //dataToUpdate.updatedBy = currentUser.Email

    showSchedulingPopup()

    utility.genericUpdateNatureStripAPICallForWO(Config.UpdateWO, +event.id, dataToUpdate).then(
      () => {
        const index = workorderData.findIndex((w: any) => w.id === event.id)

        if (index >= 0) {
          workorderData.splice(index, 1)
          // console.log({event});
          event.crew = -1
          event.fieldstatus = global.Unassigned
          unassignedWorkorderData.push(event)

          setUnassignedWorkorderData([...unassignedWorkorderData])
          setWorkorderData([...workorderData])

          AssignedworkOrdersFromList = [...workorderData]
          UnassignedworkOrdersFromList = [...unassignedWorkorderData]
        }
        showFilterMapIcon()

        setHideAlertButton(false)
        setShowAlert(false)
        setState(prevState => ({ ...prevState, onCrewSelected: true }))
      },
      err => {
        console.log(err)
        showErrorSchedulingPopup()
      }
    )
  }

  const onAppointmentRemove = (e: any) => {
    if (checkWOStatusInProgress(e.itemData.fieldstatus)) {
      let msg = 'This work order is in progress and cannot be modified.'
      if (e.itemData.fieldstatus === 'Complete') {
        msg = 'This work order is completed and cannot be modified.'
      }
      setAlertMessage(msg)
      setHideAlertButton(false)
      setShowAlert(true)
      return
    }
    // let dataToUpdate = {
    //     Crewid: '',
    //     StatusID: 8
    // };
    const dataToUpdate: any = {}

    const tempStatus = StatusItems
    const tempFieldStatus = tempStatus.filter((r: any) => r.text === global.Unassigned)
    const fieldStatus = tempFieldStatus[0].id

    const comments = 'Unassigned workorder from scheduler'
    // const Created = `${currentUser.DisplayName} ${utility.cuniq()}`
    // const description = `${e.itemData.WOComments}\n [${Created}] ${comments}`;
    // e.itemData.WOComments = description;

    // dataToUpdate.id = +e.itemData.id
    //dataToUpdate.crewId = -1;
    // dataToUpdate.StatusID = fieldStatus;
    dataToUpdate.workorder_status_id = fieldStatus
    dataToUpdate.comments = comments
    // dataToUpdate.WoComments = description;
    //dataToUpdate.updatedBy = currentUser.Email

    showSchedulingPopup()

    utility.genericUpdateNatureStripAPICallForWO(Config.UpdateWO, +e.itemData.id, dataToUpdate).then(
      () => {
        const index = workorderData.findIndex((w: any) => w.id === e.itemData.id)

        if (index >= 0) {
          e.itemData.fieldstatus = global.Unassigned
          workorderData.splice(index, 1)
          unassignedWorkorderData.push(e.itemData)

          setUnassignedWorkorderData([...unassignedWorkorderData])
          setWorkorderData([...workorderData])

          AssignedworkOrdersFromList = [...workorderData]
          UnassignedworkOrdersFromList = [...unassignedWorkorderData]
        }
        showFilterMapIcon()
        setHideAlertButton(false)
        setShowAlert(false)
      },
      err => {
        console.log(err)
        showErrorSchedulingPopup()
      }
    )
  }

  const onAppointmentAdd = (e: any) => {
    const dataToUpdate: any = {}

    const tempStatus = StatusItems
    const tempFieldStatus = tempStatus.filter((r: any) => r.text === global.Assigned)
    e.itemData.startDate.setHours(e.itemData.startDate.getHours() + 1)
    const fieldStatus = tempFieldStatus[0].id
    const crewName = resources.find((r: any) => r.id === e.itemData.crew)
    const comments = `Work order dispatched to ${crewName.text} from scheduler`
    // dataToUpdate.id = +e.fromData.id
    dataToUpdate.crew_id = e.itemData.crew
    dataToUpdate.workorder_status_id = fieldStatus
    dataToUpdate.comments = comments
    //dataToUpdate.updatedBy = currentUser.Email
    dataToUpdate.date_scheduled_start = e.itemData.startDate.toISOString()
    dataToUpdate.date_scheduled_end = e.itemData.endDate.toISOString()

    showSchedulingPopup()

    utility.genericUpdateNatureStripAPICallForWO(Config.UpdateWO, +e.fromData.id, dataToUpdate).then(
      () => {
        const index = unassignedWorkorderData.findIndex((w: any) => w.id === e.fromData.id)

        if (index >= 0) {
          e.itemData.fieldstatus = global.Assigned

          UnassignedworkOrdersFromList.splice(index, 1)
          workorderData.push(e.itemData)

          setUnassignedWorkorderData(UnassignedworkOrdersFromList)
          setWorkorderData([...workorderData])

          AssignedworkOrdersFromList = [...workorderData]
          // UnassignedworkOrdersFromList = [...unassignedWorkorderData];
        }
        showFilterMapIcon()

        setHideAlertButton(false)
        setShowAlert(false)
      },
      err => {
        console.log(err)
        showErrorSchedulingPopup()
      }
    )
  }

  const onAppointmentUpdating = (data: any) => {
    if (checkWOStatusInProgress(data.oldData.fieldstatus)) {
      let msg = 'This work order is in progress and cannot be modified.'
      if (data.oldData.fieldstatus === 'Complete') {
        msg = 'This work order is completed and cannot be modified.'
      }

      setAlertMessage(msg)
      setHideAlertButton(false)
      setShowAlert(true)

      // return;
      data.cancel = true
    }
  }

  const onAppointmentUpdated = (data: any) => {
    const dataToUpdate: any = {}

    // dataToUpdate.id = +data.appointmentData.id
    dataToUpdate.crew_id = data.appointmentData.crew
    dataToUpdate.date_scheduled_start = new Date(data.appointmentData.startDate).toISOString()
    dataToUpdate.date_scheduled_end = new Date(data.appointmentData.endDate).toISOString()

    // showSchedulingPopup();

    utility.genericUpdateNatureStripAPICallForWO(Config.UpdateWO, +data.appointmentData.id, dataToUpdate).then(
      () => {
        const index = workorderData.findIndex((woi: any) => woi.id === data.appointmentData.id)

        if (index >= 0) {
          const updatedWorkorderData = [...workorderData]
          const updatedWorkorder = {
            ...updatedWorkorderData[index],
            crew: data.appointmentData.crew,
            startDate: data.appointmentData.date_started,
            endDate: data.appointmentData.date_completed
          }
          updatedWorkorderData[index] = updatedWorkorder

          setWorkorderData(updatedWorkorderData)
          AssignedworkOrdersFromList = [...updatedWorkorderData]
          UnassignedworkOrdersFromList = [...unassignedWorkorderData]
          showFilterMapIcon()
        }
      },
      err => {
        console.log(err)
        showErrorSchedulingPopup()
      }
    )
  }

  const onAppointmentFormOpening = (data: any) => {
    data.cancel = true
  }

  const onListDragStart = (e: any) => {
    e.cancel = true
  }

  const onItemDragStart = (e: any) => {
    e.itemData = e.fromData
  }

  const onItemDragEnd = (e: any) => {
    if (e.toData) {
      e.cancel = true
    }
  }

  const onDragStart = (e: any, woData: any, crew?: any) => {
    e.dataTransfer.setData('wo', JSON.stringify(woData))
  }

  const onAdd = (e: any) => {
    // const tasks = state[e.toData];
    // setState({
    //   [e.toData]: [...tasks.slice(0, e.toIndex), e.itemData, ...tasks.slice(e.toIndex)],
    // });
  }

  const onDragOver = (ev: any) => {
    ev.preventDefault()
  }

  const onDrop = (ev: any, crew?: any) => {
    const data = ev.dataTransfer.getData('wo')
    const wo = JSON.parse(data)

    setShowAssignMultipleModal(true)
    setSelectedWOItems([wo])
    setMultiSelectbyDragDrop(true)
    setSelectedCrewInSchedulerListforCrewListDrop(crew ? crew : null)
  }

  const renderResourceCell = (model: any) => {
    const range = getRange(currentDate, currentView)
    const totalWOPerCrew = workorderData.filter((wo: any) => wo.crew === model.id)
    // const link = `/search/Workorders/searchWorkOrders?crew=${model.id}&department=${
    //   department.id
    // }&startenddate${JSON.stringify(range)}`
    const woProps: any = {
      pathname: '/Searchworkorder',
      state: {
        crew: { id: model.id, text: model.text },
        department: department,
        range: range
      }
    }
    return (
      <div className='schedulerResourceCell'>
        <Grid container spacing={1}>
          {/* <Grid item xs={2} sm={2}>
            <div
              className='crewTitleColorScheduler'
              style={{ backgroundColor: model.data.color ? model.data.color : '#333' }}
            ></div>
          </Grid> */}
          <Grid item xs={12}>
            <div className='schedulerResourceCellText'>{model.data.text}</div>
            {totalWOPerCrew.length > 0 ? (
              <NavLink to={woProps}>
                <div className='schedulerResourceCellWO'>{`${totalWOPerCrew.length} workorder${
                  totalWOPerCrew.length > 1 ? 's' : ''
                }`}</div>
              </NavLink>
            ) : (
              <div className='schedulerResourceCellWO'>{`${totalWOPerCrew.length} workorder${
                totalWOPerCrew.length > 1 ? 's' : ''
              }`}</div>
            )}
          </Grid>
        </Grid>
      </div>
    )
  }

  const renderAgendaResourceCell = (model: any) => {
    const totalWOPerCrew = workorderData.filter((wo: any) => wo.crew === model.id)
    const woProps: any = {
      pathname: '/Searchworkorder',
      state: {
        crew: [{ id: model.id, text: model.text }],
        department: department,
        range: dateRange
      }
    }
    // const link = `/Searchworkorder?crew=${model.id}&department=${JSON.stringify(department)}&startenddate${JSON.stringify(range)}`
    return (
      <div className='schedulerResourceCell'>
        <Grid container spacing={1}>
          <Grid item xs={10} sm={10}>
            {totalWOPerCrew.length > 0 ? (
              <NavLink to={woProps}>
                <a className='schedulerResourceCellWO'>{`${totalWOPerCrew.length} workorder${
                  totalWOPerCrew.length > 1 ? 's' : ''
                }`}</a>
              </NavLink>
            ) : (
              <div className='schedulerResourceCellWO'>{`${totalWOPerCrew.length} workorder${
                totalWOPerCrew.length > 1 ? 's' : ''
              }`}</div>
            )}
          </Grid>
        </Grid>
      </div>
    )
  }

  const renderTimeCell = (data: any, index: any) => {
    if (currentView === 'Day') {
      return <p className='renderTimeCellStyle'>{data.text}</p>
    } else {
      return (
        <p className='renderTimeCellStyle'>
          {moment(workingHour[0], 'HH').format('h:mm a')} - {moment(workingHour[1], 'HH').format('h:mm a')}
        </p>
      )
    }
  }

  const renderDateCell = (data: any, index: any) => {
    return <p className='renderDateCellStyle'>{data.text}</p>
  }

  const onCurrentViewChange = (updatedView: any) => {
    setCurrentView(updatedView)
    getWorkOrdersForDepartment(currentDate, updatedView, true)
  }

  const onCurrentDateChange = (updatedDate: any) => {
    setCurrentDate(updatedDate)
    getWorkOrdersForDepartment(updatedDate, currentView, true)
  }

  const onViewDateChange = (date: any, view: any) => {
    setCurrentDate(date)
    setCurrentView(view)
    getWorkOrdersForDepartment(date, view, true)
  }

  const getRange = (date: any, view: any) => {
    if (view === 'Day'|| view === 'agenda') {
      return { startDate: date, endDate: date }
    }
    if (view === 'Week' || view === 'Work Week') {
      const firstDay = date.getDate() - date.getDay()
      // const lastDay = firstDay + 6;
      const tempStartDate = new Date(date)
      const startDate = tempStartDate.setDate(firstDay)
      const tempEndDate = new Date(startDate)
      const endDate = tempEndDate.setDate(tempEndDate.getDate() + 6)

      return {
        startDate: new Date(startDate),
        endDate: new Date(endDate)
      }
    }
    if (view === 'Month' || view === 'Calendar') {
      const firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
      const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0)
      return {
        startDate: firstDay,
        endDate: lastDay
      }
    }
  }

  const handleShowHidePopOver = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
    setShowMoreSettingMenu(true)
  }

  const handleCloseShowHidePopOver = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(null)
    setShowMoreSettingMenu(false)
  }

  const renderShowMoreSettingMenu = () => {
    return (
      <Popover
        id={'i'}
        open={showMoreSettingMenu}
        anchorEl={anchorEl}
        onClose={handleCloseShowHidePopOver}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        sx={{ width: 300 }}
      >
        <div className='popOverContent'>
          {selected === 0 && (
            <div className='workingHoursDiv mb-10'>
              <div className='displayFlex'>
                <Tooltip title='Select working hours'>
                <QueryBuilderIcon className="workingHoursIcon" titleAccess='Select working hours'></QueryBuilderIcon>
                </Tooltip>
                <Slider
                  getAriaLabel={() => 'Temperature range'}
                  value={workingHour}
                  onChange={handleWorkingHourChange}
                  valueLabelDisplay='auto'
                  min={0}
                  step={1}
                  max={24}
                  getAriaValueText={(e: any) => `${e}`}
                />
              </div>
            </div>
          )}
          <div className={'mtb-20 displayFlex'}>
            {selected === 0 && (
              <div className={'toggleViewScheduler'}>
                <div
                  title='Change view of scheduler'
                  className={
                    schedulerSplitterView === 'viewOne'
                      ? 'selectedToggleViewSchedulerIconDiv'
                      : 'toggleViewSchedulerIconDiv'
                  }
                  onClick={() => changeSchedulerSplitterView('viewOne')}
                >
                  <div className='toggleViewSchedulerIcon icon-reverse'>
                  <ViewCompactIcon className="toggleViewSchedulerIcon iconInverse" ></ViewCompactIcon>
                  </div>
                </div>
                <div
                  title='Change view of scheduler'
                  className={
                    schedulerSplitterView === 'viewTwo'
                      ? 'selectedToggleViewSchedulerIconDiv'
                      : 'toggleViewSchedulerIconDiv'
                  }
                  onClick={() => changeSchedulerSplitterView('viewTwo')}
                >
                  <div className='toggleViewSchedulerIcon'>
                  <ViewCompactIcon className="toggleViewSchedulerIcon" ></ViewCompactIcon>
                   
                  </div>
                </div>
              </div>
            )}
            <IconButton aria-label='view' color='primary' onClick={() => getWorkOrders()}>
            <RefreshIcon titleAccess='Refresh the data' className={'primary'} />
            
            </IconButton>
            <IconButton aria-label='view' color='primary' onClick={() => setShowColorLegend(!showColorLegend)}>
            <InfoOutlinedIcon titleAccess='View the colour coding of the statuses' className={'primary'} />
              
            </IconButton>
          </div>
          <div>
            <p>List Item View</p>
            <div className='displayFlex uWOSection'>
              <div
                className={`SchedulerViewChangeDiv ${cardView === 'List' ? 'SchedulerViewSelected' : ''}`}
                onClick={() => onChangeListItemView('List')}
              >
                List
              </div>
              <div
                className={`SchedulerViewChangeDiv ${cardView === 'Card' ? 'SchedulerViewSelected' : ''}`}
                onClick={() => onChangeListItemView('Card')}
              >
                Card
              </div>
            </div>
          </div>
        </div>
      </Popover>
    )
  }

  const alert = () => {
    return (
      <AlertDialog
        showDialog={showAlert}
        message={alertMessage}
        title={Config.productTitle}
        onClose={() => _closeDialog()}
        hideAlertButton={hideAlertButton}
      />
    )
  }

  const _closeDialog = (): void => {
    if (goHome) {
      setAlertMessage('')
      setShowAlert(false)
      setGoHome(false)
      window.open('#/', '_self')
    } else {
      setAlertMessage('')
      setShowAlert(false)
    }
  }

  const onSelecteWoFromCrewList = (wo: any, crew: any) => {
    console.log('Selected Work Orders:', wo);
    console.log('Selected Crew:', crew);
    setState(prevState => ({ ...prevState, selectedWorkorderbyCrew: wo, selectedCrewInSchedulerList: crew, onCrewSelected: true }))
  }

  const stopOnCrewSelected = (): void => {
    setState(prevState => ({ ...prevState, onCrewSelected: false }))
  }

  const viewDetials = (item: any) => {
    const selected = item
    //console.log(selected);
    setDetails(selected)
    setShowModal(true)
  }

  const viewWO = (item: any) => {
    const selected = item
    const a = window.location.href
    const b = a.substring(0, a.lastIndexOf('/') + 1)
    window.open(b + `WorkOrderDetail?WOID=${selected.id}&ReqId=${selected.servicerequest || null}`, '_blank')
  }

  const zoomToSelectWO = (item: any, openModal?: any) => {
    if (item.lat && item.long) {
      setZoom(20)
      setCenter({ lat: item.lat, lng: item.long })
      setState(prevState => ({ ...prevState, loading: true }))
      setAddress(item.location)
      setOpenMapModal(openModal ? true : false)

      setTimeout(() => setState({ loading: false }), 500);
    } else {
      setAlertMessage('Map data not found.')
      setShowAlert(true)
      setHideAlertButton(false)
    }
  }

  const zoomOutWO = () => {
    setZoom(8)
    setState(prevState => ({ ...prevState, loading: true }))

    setTimeout(() => setState({ loading: false }), 500);
  }

  const renderDetialsModal = () => {
    return <DetailsModal details={details} showModal={showModal} onClick={() => _closeModal()} />
  }

  const _closeModal = (): void => {
    setShowModal(false)
  }

  const showSchedulingPopup = () => {
    setAlertMessage(<Spinner label='Scheduling...' />)
    setHideAlertButton(true)
    setShowAlert(true)
   setState(prevState => ({ ...prevState, loading: true }))
  }

  const showErrorSchedulingPopup = () => {
    setAlertMessage('Unable to process the request. Please try again later')
    setHideAlertButton(false)
  }

  const checkWOStatusInProgress = (status: any) => {
    if (status === 'Closed' || status === 'Complete') {
      return true
    }
    return false
  }

  const stopCommsAfterInit = (): void => {
    setState(prevState => ({ ...prevState, callAfterInit: false, callAfterInitMap: false }))
  }

  return (
    <div className={'dashboardHome'}>
      {showModal && renderDetialsModal()}
      {showAlert && alert()}
      {showMoreSettingMenu && renderShowMoreSettingMenu()}
      {openMapModal && renderMapModal()}

      <div className='Tabs-container'>
        
          <Grid container spacing={3}>
            <Grid item sm={12} md={4}>
              {/* <Paper square> */}
              <Tabs value={selected} onChange={handleSelect} className='tabs-section'>
                <Tab
                  value={0}
                  label={
                    <span className='tabHeading'>
                      {/* <Icon icon={'mdi:calendar-text'} className='subMenuLinkIcon' /> Calendar */}
                      Calendar
                    </span>
                  }
                />
                <Tab
                  value={1}
                  label={
                    <span className='tabHeading'>
                      {/* <Icon icon={'la:list-alt'} className='subMenuLinkIcon' /> */}
                      List
                    </span>
                  }
                />
                <Tab
                  value={2}
                  label={
                    <span className='tabHeading'>
                      {/* <Icon icon={'mdi:map-marker-outline'} className='subMenuLinkIcon' />  */}
                      Map
                    </span>
                  }
                />
                </Tabs>
              {/* </Paper> */}
            </Grid>
            <Grid item sm={12} md={8}>
              <div className={'schedulerDiv select-contrainer'}>
                {!state.loading ? (
                  // <Paper>
                  <div className={''}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={11}>
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={4}>
                            <SelectBoxInput
                              stylingMode='outlined'
                              displayExpr='title'
                              valueExpr='id'
                              showClearButton={false}
                              label='Department'
                              items={deptOptions}
                              selectedItem={department}
                              onSelectionChanged={(e: any) => handleDropdownChange(e, 'department')}
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <SelectBoxInput
                              stylingMode='outlined'
                              displayExpr='text'
                              valueExpr='id'
                              showClearButton={false}
                              label='Teams'
                              items={teamsItems}
                              selectedItem={team}
                              onSelectionChanged={(e: any) => handleDropdownChange(e, 'team')}
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <MultiSelect
                              items={crewItems}
                              onChange={(e: any) => _multiFieldChanged(e, 'crewteam')}
                              selectedItem={state.crewteam}
                              // opened={true}
                              label='Crew'
                              stylingMode='outlined'
                              displayExpr='text'
                              showClearButton={true}
                              placeholder='Select a value...'
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={2} sm={1}>
                        <Grid item xs={12} sm={12}>
                          <IconButton
                            aria-label='view'
                            color='primary'
                            className='schedulerSettingBtn'
                            onClick={handleShowHidePopOver}
                          >
                            <SettingsIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                ) : (
                  // </Paper>
                  <Spinner size='large' label='Loading all component. Please wait...' />
                )}
              </div>
            </Grid>
          </Grid>
          {showColorLegend && (
          <div style={{ animation: 'fadeIn 2s' }}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12}>
                <Paper elevation={6}>
                  <div className='displayFlex'>
                    <h4 className='subHeading'>Priority Colors</h4>
                    <CloseOutlinedIcon style={{ margin: '10px', cursor: 'pointer' }} className={'primary'} onClick={() => setShowColorLegend(false)} />
                  </div>
                  <div className={'ColorLegendDiv'}>
                    {getObjectKeys(Config.priorityColors).map((item: any) => {
                      return (
                        <div className={'ColorLegendItem'} style={{ backgroundColor: Config.priorityColors[item] }}>{item}</div>
                      );
                    })
                    }
                  </div>
                </Paper>
              </Grid>
            </Grid>
          </div>
        )}
        <br />

          <Grid item xs={12} sm={12}>
            <TabPanel value={selected} index={0}>
              <div className='schedulerDiv'>
                <Grid container spacing={3}>
                  <Grid className='schedulerGridDiv' item xs={12}>
                    <div
                      className={schedulerSplitterView === 'viewOne' ? 'displayFlex' : ''}
                      style={{
                        //width: schedulerGridDivWidth,
                        display: schedulerSplitterView === 'viewOne' ? 'flex' : 'block'
                      }}
                    >
                      <Resizable
                        width={
                          viewState.scheduleResizableWidth
                            ? viewState.scheduleResizableWidth
                            : (isNarrow || viewState.viewTwo) ? '100%' : '70%'
                            // : '100%'
                           
                        }
                        handles='right'
                        minWidth={maxSchedulerWidth - 400}
                        maxWidth={maxSchedulerWidth}
                        onResize={(e: any) => onResizeDiv(e)}
                      >
                        {/* <Paper elevation={6} style={{ height: '70vh' }}> */}
                        <div className={'p-10'}>
                          {workorderData && workorderData.length > 0 && (
                            <div>
                              <DScheduler
                                timeZone='Australia/Sydney'
                                //dataSource={workorderData}
                                dataSource={{
                                  store: {
                                    type: 'array',
                                    data: workorderData,
                                    key: 'id',
                                    onInserting: (value) => {
                                      value.id = workorderData.length + 1; // Assuming `workorderData` is the correct reference for length
                                    },
                                  },
                                }}
                                currentView={currentView}
                                currentDate={currentDate}
                                groups={groups}
                                // groups={currentView == 'agenda' ? [''] : groups}
                                // height={600}
                                height={'68vh'}
                                // width={scheduleResizableWidth-10}
                                firstDayOfWeek={0}
                                startDayHour={workingHour ? workingHour[0] : 9}
                                endDayHour={workingHour ? workingHour[1] : 17}
                                showAllDayPanel={true}
                                crossScrollingEnabled={true}
                                resourceCellRender={renderResourceCell}
                                //  adaptivityEnabled={true}
                                editing={{ allowAdding: false }}
                                showCurrentTimeIndicator={true}
                                appointmentComponent={renderAppointment}
                                appointmentTooltipRender={renderAppointmentTooltip}
                                onAppointmentFormOpening={onAppointmentFormOpening}
                                onAppointmentUpdating={onAppointmentUpdating}
                                onAppointmentUpdated={onAppointmentUpdated}
                                onCurrentViewChange={(e: any) => onCurrentViewChange(e)}
                                onCurrentDateChange={onCurrentDateChange}
                                timeCellRender={renderTimeCell}
                                dateCellRender={renderDateCell}
                                // onOptionChanged={onOptionChanged}
                                // getStartViewDate={onAppointmentUpdated}
                              >
                                <View
                                  name='Day'
                                  type='timelineDay'
                                  groupOrientation='vertical'
                                  cellDuration={60}
                                  intervalCount={1}
                                />
                                <View
                                  name={'Work Week'}
                                  type={'timelineWorkWeek'}
                                  groupOrientation='vertical'
                                  cellDuration={(workingHour[1] - workingHour[0]) * 60}
                                  intervalCount={1}
                                  startDayHour={workingHour ? workingHour[0] : 9}
                                  endDayHour={workingHour ? workingHour[1] : 17}
                                />
                                <View
                                  name={'Week'}
                                  type={'timelineWeek'}
                                  groupOrientation='vertical'
                                  cellDuration={(workingHour[1] - workingHour[0]) * 60}
                                  intervalCount={1}
                                  startDayHour={workingHour ? workingHour[0] : 9}
                                  endDayHour={workingHour ? workingHour[1] : 17}
                                />
                                <View
                                  name='Month'
                                  type='timelineMonth'
                                  groupOrientation='vertical'
                                  cellDuration={60}
                                  intervalCount={1}
                                />
                                <View type='agenda' resourceCellRender={renderAgendaResourceCell} agendaDuration={1} />
                                <Resource
                                  dataSource={resources}
                                  fieldExpr='crew'
                                  label='Crew'
                                  useColorAsDefault={true}
                                />
                                <AppointmentDragging
                                  group={draggingGroupName}
                                  onRemove={onAppointmentRemove}
                                  onAdd={onAppointmentAdd}
                                />

                                <Scrolling mode='virtual' />
                              </DScheduler>
                            </div>
                          )}
                        </div>
                        {/* </Paper> */}
                      </Resizable>

                      {schedulerSplitterView === 'viewOne' && (
                        <Grid item xs={12} sm={12}>
                          <Paper
                            elevation={6}
                            className='fill-available'
                            style={{ height: '70vh', marginLeft: '10px', marginTop: '10px', overflow: 'auto' }}
                          >
                            {renderListView(false, false)}
                          </Paper>
                        </Grid>
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Paper elevation={6}>{schedulerSplitterView === 'viewTwo' && renderListView(false, false)}</Paper>
                  </Grid>
                </Grid>
                <br />
              </div>
            </TabPanel>

            <TabPanel value={selected} index={1}>
              <div className='schedulerDiv mt-20'>
                {/* <Grid item xs={12} sm={6}> */}
                <Grid container spacing={3}>
                  <Grid className='schedulerGridDiv' item xs={12} sm={12} md={6} lg={4}>
                    <Paper
                      elevation={6}
                      style={{ minHeight: isNarrow ? 'auto' : '70vh', maxHeight: '70vh', overflow: 'auto' }}
                    >
                      <div>
                        {workorderData && (
                          <div
                          // style={{ height: "80vh"}}
                          >
                            {!state.loading ? (
                              <SchedulerCrewListView
                                crews={resources}
                                workorders={workorderData}
                                //dataSource={workorderData}
                                // currentView={currentView}
                                // currentDate={currentDate}
                                view={cardView}
                                onDragOver={(e: any) => onDragOver(e)}
                                onDrop={(e: any, crew: any) => onDrop(e, crew)}
                                selectedWorkorderbyCrew={(wo: any, crew: any) => onSelecteWoFromCrewList(wo, crew)}
                                onCrewSelected={state.onCrewSelected ? state.onCrewSelected : false}
                                stopOnCrewSelected={stopOnCrewSelected}
                              />
                            ) : (
                              <>
                                <br />
                                <Spinner size='large' height={60} width={60} />
                              </>
                            )}
                          </div>
                        )}
                      </div>
                    </Paper>
                  </Grid>
                  <Grid className='schedulerGridDiv' item xs={12} sm={12} md={6} lg={4}>
                    <Paper
                      elevation={6}
                      style={{ minHeight: isNarrow ? 'auto' : '70vh', maxHeight: '70vh', overflow: 'auto' }}
                    >
                      <div>
                        {workorderData && (
                          <div>
                            {!state.loading ? (
                              <SchedulerWorkorderListView
                                crews={resources}
                                workorders={workorderData}
                                selectedWorkorderbyCrew={state.selectedWorkorderbyCrew}
                                // dataSource={workorderData}
                                currentView={currentView}
                                currentDate={currentDate}
                                viewItem={(e: any) => viewDetials(e)}
                                viewWO={(e: any) => viewWO(e)}
                                zoomToSelectWO={(e: any) => zoomToSelectWO(e, true)}
                                view={cardView}
                                onDragOver={(e: any) => onDragOver(e)}
                                onDrop={(e: any) => onDrop(e)}
                                selectedCrewInSchedulerList={state.selectedCrewInSchedulerList}
                                onDragStart={(e: any, woData: any) => onDragStart(e, woData)}
                                onCrewSelected={state.onCrewSelected}
                                stopOnCrewSelected={stopOnCrewSelected}
                                onViewDateChange={(date: any, view: any) => onViewDateChange(date, view)}
                                unassignWO={(woData: any) => unassignWO(woData)}
                              />
                            ) : (
                              <>
                                <br />
                                <Spinner size='large' height={60} width={60} />
                              </>
                            )}
                          </div>
                        )}
                      </div>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={4}>
                    <Paper
                      elevation={6}
                      style={{ minHeight: isNarrow ? 'auto' : '70vh', maxHeight: '70vh', overflow: 'auto' }}
                    >
                      {renderListView(true, true, true)}
                    </Paper>
                  </Grid>
                </Grid>
                {/* <Grid item xs={12} sm={12}>
                        <Paper elevation={6} className='mt-10'>
                          {renderMapView()}
                        </Paper>
                      </Grid> */}
                {/* </Grid> */}

                <br />
              </div>
            </TabPanel>

            <TabPanel value={selected} index={2}>
              <div className='schedulerDiv mt-20'>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12}>
                    <Paper elevation={6} className='mt-10'>
                      {renderMapView(true, isNarrow)}
                    </Paper>
                    <br />
                  </Grid>
                </Grid>
              </div>
            </TabPanel>
          </Grid>
          {/* </Grid> */}
      </div>

      {selectedWOItems && selectedWOItems.length > 0 && !multiSelectbyDragDrop && (
        <div className='buttonDiv'>
          <SpeedDialAction
            icon='add'
            label={`Assign ${selectedWOItems.length} WOs`}
            // visible={false}
            onClick={() => handleActionButton()}
            style={{ zIndex: 999 }}
          />
        </div>
      )}

      {showAssignMultipleModal && renderAssignMultipleModal()}
    </div>
  )
}

export default Scheduler
