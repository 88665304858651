import React, { useState, useEffect } from 'react'
import Grid from '@mui/material/Grid'
import moment from 'moment'
import Popup, { ToolbarItem } from 'devextreme-react/popup'
import ScrollView from 'devextreme-react/scroll-view'
import Divider from '@mui/material/Divider'

interface DetailsModalProps {
  details: any
  showModal: boolean
  onClick: () => void
}

const DetailsModal: React.FC<DetailsModalProps> = props => {
  const [showModal, setShowModal] = useState<boolean>(false)

  useEffect(() => {
    setShowModal(props.showModal)
  }, [props.showModal])

  return (
    <div>
      <Popup
        // width={'60vw'}
        maxHeight={'85vh'}
        minHeight={200}
        height={'auto'}
        showTitle={true}
        showCloseButton={true}
        title={'Work Order Details'}
        dragEnabled={false}
        hideOnOutsideClick={false}
        visible={showModal}
        onVisibleChange={() => setShowModal(false)}
      >
        <ScrollView width='100%' height='100%'>
          <div className='popContentDiv'>
            {!props.details ? (
              <div>
                <h4 className='alignCenter'>No data available.</h4>
                <br />
              </div>
            ) : (
              <div>
                <Grid container spacing={3}>
                  <Grid item xs={6} sm={3}>
                    Work Order Number
                  </Grid>
                  <Grid item xs={6} sm={9} className='ms-fontWeight-semibold'>
                    {props.details.Workordernumber}
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={6} sm={3}>
                    Title
                  </Grid>
                  <Grid item xs={6} sm={9} className='ms-fontWeight-semibold'>
                    {props.details.text}
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={6} sm={3}>
                    Street Address
                  </Grid>
                  <Grid item xs={6} sm={9} className='ms-fontWeight-semibold'>
                    {props.details.location}
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={6} sm={3}>
                    Suburb
                  </Grid>
                  <Grid item xs={6} sm={9} className='ms-fontWeight-semibold'>
                    {props.details.suburb}
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={6} sm={3}>
                    Priority
                  </Grid>
                  <Grid item xs={6} sm={9} className='ms-fontWeight-semibold'>
                    {props.details.priority}
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={6} sm={3}>
                    Status
                  </Grid>
                  <Grid item xs={6} sm={9} className='ms-fontWeight-semibold'>
                    {props.details.wostatus}
                  </Grid>
                </Grid>
                {props.details.crewtitle && (
                  <Grid container spacing={3}>
                    <Grid item xs={6} sm={3}>
                      Crew
                    </Grid>
                    <Grid item xs={6} sm={9} className='ms-fontWeight-semibold'>
                      {props.details.crewtitle}
                    </Grid>
                  </Grid>
                )}
                {props.details.startDate && (
                  <Grid container spacing={3}>
                    <Grid item xs={6} sm={3}>
                      Start Date
                    </Grid>
                    <Grid item xs={6} sm={9} className='ms-fontWeight-semibold'>
                      {props.details.startDate ? moment(props.details.startDate).format('DD/MM/YY h:mm a') : ''}
                    </Grid>
                  </Grid>
                )}
                {props.details.endDate && (
                  <Grid container spacing={3}>
                    <Grid item xs={6} sm={3}>
                      End Date
                    </Grid>
                    <Grid item xs={6} sm={9} className='ms-fontWeight-semibold'>
                      {props.details.endDate ? moment(props.details.endDate).format('DD/MM/YY h:mm a') : ''}
                    </Grid>
                  </Grid>
                )}
              </div>
            )}
          </div>
        </ScrollView>
        {/* <Divider /> */}
        <ToolbarItem
          widget='dxButton'
          toolbar='bottom'
          location='after'
          options={{
            icon: 'close',
            text: 'Cancel',
            stylingMode: 'outlined',
            onClick: props.onClick
          }}
        />
      </Popup>
    </div>
  )
}

export default DetailsModal
