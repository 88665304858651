import React, { useEffect, useState, useRef, useMemo } from 'react'
import { saveAs } from 'file-saver-es'
import DataGrid, {
  Column,
  Lookup,
  Editing,
  Export,
  Scrolling,
  Selection,
  SearchPanel,
  Sorting,
  GroupPanel,
  Grouping,
  FilterRow,
  HeaderFilter,
  FilterPanel,
  ColumnFixing,
  Pager,
  Paging,
  ColumnChooser,
  ColumnChooserSearch,
  MasterDetail,
  RequiredRule,
  Toolbar,
  Item,
  Summary
} from 'devextreme-react/data-grid'
import { Button } from 'devextreme-react/button'
import ContextMenu from 'devextreme/ui/context_menu'
import { SpeedDialAction } from 'devextreme-react/speed-dial-action'
import { exportDataGrid } from 'devextreme/excel_exporter'
import { Workbook } from 'exceljs'
import { NavLink } from 'react-router-dom'
// import TimesheetDetailsModal from '../../Common/ReusableComponents/TimesheetDetailsModal';

//import { NavLink } from 'react-router-dom'
// Our demo infrastructure requires us to use 'column.field-saver-es'. We recommend that you use the official 'file-saver' package in your applications.

import IconButton from '@mui/material/IconButton'
import DetailTemplate from './Detailtemplate'
import { Switch as DevXSwitch } from 'devextreme-react'
import global from '../../classes/global'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import ViewListIcon from '@mui/icons-material/ViewList'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation'

const allowedPageSizes = [100, 200, 400, 800]
const searchEditorOptions = { placeholder: 'Search column' }

interface CustomASDataGridProps {
  stopCommsAfterInit?(): void
  columnChooser?: any
  currentFilter?: any
  searchValue?: any
  showNavButtons?: any
  showInfo?: any
  showPageSizeSelector?: any
  displayMode?: any
  editItem?: (arg0: any) => void
  data?: any
  columns: {
    field?: string
    title?: string
    type?: any
    width?: number | undefined
    allowHiding?: boolean | undefined
    visible?: boolean | undefined
    link?: string | undefined
    cellRender?: any
    editCellRender?: any
  }[]
  selectionMode?: any
  autoExpandAll?: boolean
  showSearchPanel?: boolean
  showFilterRow?: boolean
  showHeaderFilter?: boolean
  callAfterInit?: boolean
  fileName?: string
  defaultfiltervalue?: any
  wordWrapEnabled?: boolean
  showHideColumnMenu?: boolean
  hideSearchPanel?: boolean
  showCommandBtn?: boolean
  showViewDetailBtn?: boolean
  showEditPlantBtn?: boolean
  filteralbe?: boolean
  groupable?: boolean
  hideExport?: boolean
  allowExportSelectedData?: boolean
  showDetailTable?: boolean
  showNewBtn?: boolean
  hideNewBtn?: boolean
  detailColumns?: any
  showNewModal?: (show: boolean) => void
  defaultValue?: boolean
  columnList?: any[]
  isNarrow?: boolean
  //defaultvalue?: string
  loading?: boolean
  selectItem?: (i: any) => void
  viewItem?: any
  allowExportSelecteditems?: boolean
  hideCommandEditBtn?: boolean
  deleteItem?: (e: any) => void
  viewFile?: (e: any) => void
  selectMode?: boolean
  selectScheduleMode?: boolean
  selectCreateScheduleMode?: boolean
  style?: any
  hideCommandDeleteBtn?: boolean
  onSelectionChanged?: ((e: any) => void) | null
  hideCommandViewBtn?: boolean
  moreItem?: (e: any) => void
  showMoreBtn?: boolean
  visible?: boolean
  dataSource?: any
  setCellValue?: any
  columnHidingEnabled?: boolean
  showAddIcon?: boolean
  showDeleteIcon?: boolean
  onCellValueChange?: (value: any) => void
  onEditorPreparing?: (e: any) => void
  customSelectRows?: boolean
  onAssetsSelected?: (e: any) => void
  selectedRowKeys?: any
  customToolbarButtonText?: string
  toolbarItemsVisible?: boolean
  customToolbarButtonIcon?: any
  onEditorPrepared?: (e: any) => void
  onOptionChanged?: (e: any) => void
}

const CustomASDataGrid: React.FC<CustomASDataGridProps> = props => {
  //let searchvalue = ''
  const [data, setData] = useState<any[]>([])
  const [columns, setColumns] = useState<any[]>([])
  const [showCommandBtn, setShowCommandBtn] = useState(false)
  const [showViewDetailBtn, setShowViewDetailBtn] = useState<boolean>(false)
  const [showEditPlantBtn, setShowEditPlantBtn] = useState<boolean>(true)
  const [currentFilter, setCurrentFilter] = useState<any>('auto')
  const [displayMode, setDisplayMode] = useState<string>('full')
  const [showPageSizeSelector, setShowPageSizeSelector] = useState<boolean>(true)
  const [showInfo, setShowInfo] = useState<boolean>(true)
  const [showNavButtons, setShowNavButtons] = useState<boolean>(true)
  const [showFilterRow, setShowFilterRow] = useState<boolean>(true)
  const [showHeaderFilter, setShowHeaderFilter] = useState<boolean>(true)
  const [showNewBtn, setShowNewBtn] = useState(false)
  const [mode, setMode] = useState<string>('widget')
  const [filterValue, setFilterValue] = useState<any>(null)
  const [loadMasterDetail, setLoadMasterDetail] = useState<boolean>(false)
  const [columnList, setColumnList] = useState([])
  const [detailColumns, setDetailColumns] = useState([])
  const [dataGrid, setDataGrid] = useState<any>(null)
  const [applyFilterTypes] = useState<any>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [selectMode, setSelectMode] = useState<boolean>(false)
  const [selectScheduleMode, setSelectScheduleMode] = useState<boolean>(false)
  const [selectCreateScheduleMode, setSelectCreateScheduleMode] = useState<boolean>(false)
  const [selectedItem, setSelectedItem] = useState<any>(null)
  const [viewFile, setViewFile] = useState<any>(null)
  const [showMoreBtn, setShowMoreBtn] = useState<boolean>(false)
  const [customSelectRows, setCustomSelectRows] = useState(props.customSelectRows)
  const [customToolbarButtonText, setCustomToolbarButtonText] = useState(props.customToolbarButtonText)
  const [selectedRowData, setSelectedRowData] = useState<any>([])
  const [showTimesheetModal, setShowTimesheetModal] = useState(false)
  const [timeEditDetails, setTimeEditDetails] = useState<any>(null)

  const dataGridRef = useRef<DataGrid>(null)
  const selectionMode = props.selectionMode ? 'multiple' : 'single'

  useEffect(() => {
    const filterValueStorage = localStorage.getItem('datagridFilterValue')
    const searchPanelValueStorage = localStorage.getItem('datagridSearchPanelValue')
    const defaultfilterValue = props.defaultValue ? props.defaultfiltervalue : ''
    let filterValue = []

    if (filterValueStorage) {
      const tempFilterValue = JSON.parse(filterValueStorage)
      if (tempFilterValue.listName === props.fileName) {
        filterValue = JSON.parse(tempFilterValue.value)
      } else {
        filterValue = []
        localStorage.removeItem('datagridFilterValue')
      }
    }

    setData(props.dataSource)
    setColumns(props.columns)
    setShowNewBtn(props.showNewBtn ? props.showNewBtn : false)
    setShowCommandBtn(props.showCommandBtn ? props.showCommandBtn : false)
    setFilterValue(filterValue ? filterValue : defaultfilterValue)
    setShowMoreBtn(props.showMoreBtn ? props.showMoreBtn : false)
    setLoadMasterDetail(true)
    setSelectMode(props.selectMode ? props.selectMode : false)
    setSelectScheduleMode(props.selectScheduleMode ? props.selectScheduleMode : false)
    setSelectCreateScheduleMode(props.selectCreateScheduleMode ? props.selectCreateScheduleMode : false)
    setViewFile(props.viewFile)

    return () => {
      // Cleanup function
      localStorage.removeItem('datagridFilterValue')
      localStorage.removeItem('datagridSearchPanelValue')
    }
  }, [])

  useEffect(() => {
    setData(props.dataSource)
    setColumns(props.columns)
  }, [props.dataSource, props.columns])

//   const renderTimesheetDetailsModal =() => {
//     return (
//         <TimesheetDetailsModal
//             details={timeEditDetails}
//             showModal={showTimesheetModal}
//             onClick={() => setShowTimesheetModal(false)}
//         />
//     );
// }

  const Cell = (cellProps: any, item: any) => {
    const URL = window.location.href
    const parentURL = URL.split('#')
    let isMultiAsset = (cellProps.data && cellProps.data.assetIds && cellProps.data.assetIds.length > 1) ? true : false;

    const handleSelectItem = () => {
      if (props.selectItem && cellProps.data) {
        props.selectItem(cellProps.data)
      }
    }

    const viewSelectedFile = () => {
      if (props.viewFile && cellProps.data) {
        props.viewFile(cellProps.data)
      }
    }
    if (item.link === "customCellEditedData") {
      let classNames = ' pl-10';
      let tempField = `Edited${cellProps.column.dataField}`
      let showFieldName;

      if (cellProps.key[tempField]) {
          showFieldName = cellProps.key[tempField];
          classNames += ' danger';

      } else {
          showFieldName = cellProps.key[cellProps.column.dataField];
      }
      return (
          <>
              <span className={classNames} >
                  {showFieldName}
              </span>
          </>
      );
  }

    if (item.link === 'workorderLink') {
      let link = ''

      if (cellProps.data.fieldstatus === 'Draft') {
        link = '/CreateWorkorder?WONum='
      } else {
        if (cellProps.data.relatedWO && cellProps.data.relatedWO === 'Yes') {
          link =
            parentURL[0] +
            '/#/WorkOrderDetail?WOID=' +
            (cellProps.data.Workordernumber ? cellProps.data.Workordernumber : cellProps.data.WorkorderID)
        } else {
          link =
            '/WorkOrderDetail?WOID=' +
            (cellProps.data.Workordernumber ? cellProps.data.Workordernumber : cellProps.data.WorkorderID)
        }
      }
      if (cellProps.data.relatedWO && cellProps.data.relatedWO === 'Yes') {
        return (
          <div>
            <NavLink to={link} className={'WOlink'}>
              {cellProps.data.Workordernumber ? cellProps.data.Workordernumber : cellProps.data.WorkorderID}
            </NavLink>
          </div>
        )
      } 
      else {
        return (
            <div className='WoCol assetDet'>
                {isMultiAsset && (
                    <EmojiTransportationIcon className={"MultiAssetIcon"} />
                )}
                <NavLink
                    to={link}
                    className={"WOlink"}

                >
                    {cellProps.data.Workordernumber ? cellProps.data.Workordernumber : cellProps.data.WorkorderID}
                </NavLink>
            </div>
        );
    }

    } else if (item.link === 'scheduleLink') {
      const link = '/EditScheduleWork?ID=' + cellProps.data.id
      return <div></div>
    } else if (item.link === 'requestNumberLink') {
      const link = '/WRDetail?ReqId=' + cellProps.data.id
      return <div></div>
    } else if (item.link === 'assetLink') {
      //const link = '/AssetDetail?AssetID=' + cellProps.data.AssetNumber
      if (selectMode || selectScheduleMode || selectCreateScheduleMode) {
        return (
          <div className='URLItem' onClick={handleSelectItem}>
            {cellProps.text}
          </div>
        )
      } else {
        return (
          <div>
            <NavLink to={{
              pathname: '/AssetDetail',
              search: `?AssetID=${cellProps.data.AssetNumber}`,
              state: { assetDetailItems: { assetData: cellProps.data }, fromAssetDetail: false }
            }} 
            className={"WOlink"}>
              {cellProps.text}
            </NavLink>
          </div>
        )
      }
    } else if (item.link === 'asseticassetLink') {
      return (
        <div>
          <a
            href={global.AsseticAssetURL + cellProps.data.erpAssetId}
            className='WOlink'
            rel='noreferrer'
            target='_blank'
          >
            {cellProps.data.erpAssetId}
          </a>
        </div>
      )
    } else if (item.link === 'file') {
      if (cellProps.data?.file_url?.toLowerCase().includes('<a href=')) {
        return (
          <div
            className={'dataGridText WOlink'}
            dangerouslySetInnerHTML={{ __html: cellProps.data?.file_url?.toLowerCase() }}
          ></div>
        )
      } else {
        return (
          <div className='URLItem' onClick={viewSelectedFile}>
            {cellProps.text}
          </div>
        )
      }
    // } else if(item.link === 'timesheetLink') {

    //   return (
    //       <div>
    //           <IconButton onClick={() => {setShowTimesheetModal(true); setTimeEditDetails(props.data)}} >
    //               <ViewListIcon titleAccess="Work order time edited by user click to view more" className="icon" />
    //           </IconButton>

    //       </div>
    //   );

  }else if (item.link === 'toggle') {
      return (
        <div className={'alignCenter'}>
          <DevXSwitch
            onValueChange={(e: any) => cellProps._changeInspectiponFailed(e, cellProps.data)}
            value={cellProps.data.inspectionFailed}
          />
        </div>
      )
    } else if (item.isArray) {
      const tempArray: any = []
      if (Array.isArray(cellProps.value)) {
        cellProps.value.forEach((val: any, index: number) => {
          tempArray.push(<p key={index}>{val}</p>)
        })
      }
      return tempArray
    } else {
      return (
        <div className={'dataGridText'} title={cellProps.text}>
          {cellProps.text}
        </div>
      )
    }
  }

  // const renderDataGrid = () => {
  //   dataGrid.current.instance().updateDimensions();
  // };

  const commandCell = (commandCellProps: {
    hideCommandDeleteBtn: any
    data: { id: '' }
    hideCommandViewBtn: any
    hideCommandEditBtn: any
    showViewDetailBtn: any
    //viewItem: (arg0: any) => void
    //editItem: (arg0: any) => void
  }) => {
    const handleEditItem = () => {
      if (props.editItem) {
        props.editItem(commandCellProps.data)
      }
    }
    const handleDeleteItem = () => {
      if (props.deleteItem) {
        props.deleteItem(commandCellProps.data)
      }
    }
    const handleViewItem = () => {
      if (props.viewItem) {
        props.viewItem(commandCellProps.data)
      }
    }

    return (
      <div>
        {!props.hideCommandEditBtn && (
          <IconButton className={'primary edit dataGridIcon'} onClick={handleEditItem}>
            <EditIcon className={'primary dataGridIcon'} />
          </IconButton>
        )}
        {!props.hideCommandDeleteBtn && (
          <IconButton className={'danger dataGridIcon'} onClick={handleDeleteItem}>
            <DeleteIcon className={'danger dataGridIcon'} />
          </IconButton>
        )}
        {props.showViewDetailBtn && (
          <IconButton className={'primary dataGridIcon'} onClick={handleViewItem}>
            <ViewListIcon className={'primary dataGridIcon viewMoreIcon'} />
          </IconButton>
        )}
      </div>
    )
  }

  const onExporting = (e: any, name: any) => {
    const workbook = new Workbook()
    const worksheet = workbook.addWorksheet('Sheet 1')
    exportDataGrid({
      component: e.component,
      worksheet: worksheet,
      autoFilterEnabled: true
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer: any) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), props.fileName + '.xlsx')
      })
    })
    e.cancel = true
  }

  const renderColumns = () => {
    const columnsPerRow = 3;
    const columnData =
      columns &&
      columns.map((item: any, index: number) => (
        <Column
          key={item.field}
          dataField={item.field}
          width={item.width}
          caption={item.title}
          dataType={item.type}
          visible={item.visibleColumn}
          groupIndex={item.groupIndex}
          visibleIndex={item.visibleIndex}
          allowHiding={item.allowHiding}
          onVisibleChange={item.onChange}
          format={
            item.type === 'date'
              ? 'dd/MM/yyyy'
              : item.type === 'datetime'
              ? 'dd/MM/yyyy h:mm a'
              : item.type === 'time'
              ? 'HH:mm'
              : ''
          }
          cellRender={
            item.cellRender ? item.cellRender : item.link ? (cellProps: any) => Cell(cellProps, item) : undefined
          }
          sortOrder={item.sortOrder}
          setCellValue={item.setCellValue}
          allowEditing={item.allowEditing}
          formItem={{
            ...item.formItem,
            disabled: item.disabled,
            name: item.title,
            dataField: item.title,
            label: { location: 'top' } // Set label location to top
          }}
          editCellComponent={item.editCellComponent}
          editCellRender={item.editCellRender}
          calculateCellValue={item.calculateCellValue}
          validationRules={
            item.validationRules || item.isRequired === true ? [
              ...(item.validationRules || []),
              { type: 'required', message: `${item.title} is required` }
            ] : undefined
          }
        >
          {item.Lookup && (
            <Lookup
              dataSource={item.Lookup.dataSource}
              valueExpr={item.Lookup.valueExpr}
              displayExpr={item.Lookup.displayExpr}
            />
          )}
          {/* {item.isRequired === true && <RequiredRule />} */}
        </Column>
      ))

    return columnData
  }

  const onPopupInitialized = (e: any) => {
    e.component.content().classList.add('custom-datagrid-popup')
  }

  const renderDetailTemplate = (e: any, data: any[], detailColumns: any[]) => {
    return <DetailTemplate {...e} columns={detailColumns} dataSource={data} />
  }

  const onSelectionChanged = (e: any) => {
    const exportSelectedDisabled = !!e.component.getSelectedRowKeys().length
    const exportMenu = e.element.querySelector('.dx-datagrid-export-menu')
    if (exportMenu) {
      const instance = ContextMenu.getInstance(exportMenu) as ContextMenu
      instance.option('items[1].disabled', exportSelectedDisabled)
    }
    if (customSelectRows) {
      setSelectedRowData(e.component.getSelectedRowsData())
      //props.onSelectionChanged(e.component.getSelectedRowKeys());
    }
  }

  return (
    <div className='dataTableDiv'>
      {data && (
        <>
          <DataGrid
            className='custom-datagrid'
            ref={dataGridRef}
            id='gridContainer'
            dataSource={data}
            showBorders={false}
            showColumnLines={false}
            showRowLines={true}
            rowAlternationEnabled={false}
            allowColumnResizing={true}
            allowColumnReordering={true}
            columnMinWidth={10}
            columnAutoWidth={true}
            onExporting={e => onExporting(e, props.fileName)}
            columnHidingEnabled={props.columnHidingEnabled ? props.columnHidingEnabled : false}
            defaultFilterValue={props.defaultfiltervalue}
            remoteOperations={false}
            hoverStateEnabled={true}
            wordWrapEnabled={props.wordWrapEnabled ? props.wordWrapEnabled : false}
            visible={props.visible ? props.visible : true}
            onEditorPreparing={props.onEditorPreparing}
            onSelectionChanged={onSelectionChanged}
            // onEditorPrepared={props.onEditorPrepared}
            // onOptionChanged={props.onOptionChanged}
          >
            <Scrolling rowRenderingMode='virtual'></Scrolling>
            <Sorting mode='multiple' />
            {props.showHideColumnMenu && (
              <ColumnChooser enabled={true} mode='select' height={400}>
                <ColumnChooserSearch enabled={true} editorOptions={searchEditorOptions} />
              </ColumnChooser>
            )}
            <ColumnFixing enabled={true} />
            {props.selectionMode !== undefined && <Selection mode={selectionMode} />}
            {!props.hideSearchPanel && <SearchPanel visible={true} width={300} />}
            {columns && renderColumns()}
            {props.showViewDetailBtn && (
              <Column
                caption='View'
                width={70}
                cellRender={commandCell}
                fixed={true}
                fixedPosition={'right'}
                allowHiding={false}
                allowExporting={false}
              />
            )}
            {props.showMoreBtn && (
              <Editing
                mode='popup'
                allowUpdating={true}
                allowAdding={props.showAddIcon ? props.showAddIcon : true}
                allowDeleting={props.showDeleteIcon ? props.showDeleteIcon : true}
                useIcons={true}
                popup={{ 
                //   minWidth:700,   
                //   width: 'auto',
                //   minHeight: 500,
                //   maxHeight: 600,
                //   height: 'auto',
                minWidth:'70%',
                width:'auto',
                height: '60%',
                  onShowing: onPopupInitialized,
                  position: { my: 'center', at: 'center' }
                  // Other popup options
                }}
                form={{
                  colCount: 3 // Ensures three fields per row
                }}
              />
            )}
            <Paging defaultPageSize={100} />
            <Pager
              visible={true}
              allowedPageSizes={allowedPageSizes}
              displayMode={props.displayMode}
              showPageSizeSelector={showPageSizeSelector}
              showInfo={showInfo}
              showNavigationButtons={showNavButtons}
            />
            {props.filteralbe && <HeaderFilter visible={showHeaderFilter} />}
            {props.groupable && <GroupPanel visible={props.isNarrow ? false : true} />}
            {props.groupable && <Grouping autoExpandAll={false} />}
            {!props.hideExport && <Export enabled={true} allowExportSelectedData={props.allowExportSelectedData} />}
            {props.showDetailTable && (
              <MasterDetail
                autoExpandAll={false}
                enabled={true}
                component={e => renderDetailTemplate(e, props.dataSource, props.detailColumns)}
              />
            )}
            {props.customSelectRows && (
              <Toolbar>
                {/* <Item name="addRowButton" showText="always" /> */}
                <Item location='after'>
                  <Button
                    className='custom-datagrid-toolbar'
                    onClick={() => props.onSelectionChanged?.(selectedRowData)}
                    icon={props.customToolbarButtonIcon ? props.customToolbarButtonIcon : ''}
                    disabled={!selectedRowData.length}
                    text={props.customToolbarButtonText}
                  />
                </Item>
                {props.toolbarItemsVisible && <Item name='searchPanel' />}
                {props.toolbarItemsVisible && <Item name='exportButton' />}
                {props.toolbarItemsVisible && <Item name='columnChooserButton' />}
                {props.toolbarItemsVisible && <Item name='groupPanel' />}
              </Toolbar>
            )}
          </DataGrid>
        </>
      )}
    </div>
  )
}

export default CustomASDataGrid
