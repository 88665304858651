import React from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import './index.css';
import './css/dropzone.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from "./AuthContext";
import Home from './Home'; 
import SignIn from './components/Login/Login';
import './css/home.css';
import 'devextreme/dist/css/dx.light.css';

// Import Sentry
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

Sentry.init({dsn: "http://98954d1bd5f98ad65c9989713edf28b7@sentry.atensystems.com.au:9000/7",
  integrations: [    
    Sentry.browserTracingIntegration(),    
    Sentry.replayIntegration(),
  ],
  // TracingtracesSampleRate: 1.0, 
  //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabledtracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session ReplayreplaysSessionSampleRate: 0.1, 
  // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.replaysOnErrorSampleRate: 1.0, 
  // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

  ReactDOM.render(
    <Sentry.ErrorBoundary fallback={<p>Something went wrong.</p>}>
      <AuthProvider>
        <Home />
      </AuthProvider>
    </Sentry.ErrorBoundary>,
    document.getElementById('root')
  );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
