import React, { useState } from 'react';
import { Typography, Link, Tooltip } from '@mui/material';

interface ReadMoreProps {
  text: string;
  maxWords?: number;
}

const ReadMore: React.FC<ReadMoreProps> = ({ text, maxWords = 20 }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const words = text?.split(' ');

  const toggleIsExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div>
      <Typography variant="body2">
        {isExpanded || words?.length <= maxWords
          ? text
          : `${words?.slice(0, maxWords).join(' ')}...`}
        {words?.length > maxWords && (
          <Tooltip title={isExpanded ? '' : text} placement="right" arrow>
            <Link
              component="button"
              variant="body2"
              onClick={toggleIsExpanded}
              style={{ marginLeft: '5px' }}
              className="readMoreLink"
            >
              {isExpanded ? 'Read Less' : 'Read More'}
            </Link>
          </Tooltip>
        )}
      </Typography>
    </div>
  );
};

export default ReadMore;
