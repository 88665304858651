import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography, Switch, Grid, Avatar, Box } from '@mui/material';
import global from '../../classes/global'
import { Config } from '../../classes/config'
import AlertDialog from '../Common/AlertDialog'
import Spinner from '../Common/ReusableComponents/Spinner'
import utility from '../../classes/utility'
import moment from 'moment';

interface WOProps {
    role?: any
    Department?: any
    currentUser?:any
    pageListItems?:any
    isContractor?:any
    team?:any
}


interface ProfileState {
    themeMode: boolean;
    loading: boolean;
    info: any;
    showAlert: boolean;
    alertMessage: string;
    isVisible: boolean;
    [key: string]: any;
  };


const Profile = (props: WOProps) => {
    const [state, setState] = useState<ProfileState>({
        themeMode: localStorage.getItem('theme') === 'theme-dark',
        loading: true,
        info: null,
        showAlert: false,
        alertMessage: '',
        isVisible: false,
      });

      useEffect(() => {
        if (!props.Department) {
          window.open('#/AccessRequest', '_self');
          return;
        }
        getProfileData();
      }, [props.Department]);

      const getProfileData = () => {
        const dataToPassToService = { Email: props.currentUser?.Email };
        setState((prevState) => ({ ...prevState, loading: true, info: [] }));
    
        utility.genericPostAPICall(Config.SearchEmployeeApi, dataToPassToService)
          .then((data: any) => {
            const profileData = data[0];
            setState((prevState) => ({
              ...prevState,
              ...profileData,
              updatedOn: profileData.updated ? moment(profileData.updated).format('DD/MM/YYYY h:mm') : '',
              createdOn: profileData.created ? moment(profileData.created).format('DD/MM/YYYY h:mm') : '',
              info: profileData,
              loading: false,
            }));
          })
          .catch((err) => {
            console.error(err);
            setState((prevState) => ({ ...prevState, loading: false }));
          });
      };
    
      const themeProvider = () => {
        const newTheme = state.themeMode ? 'theme-light' : 'theme-dark';
        setTheme(newTheme);
        window.location.reload();
      };
    
      const setTheme = (themeName: string) => {
        localStorage.setItem('theme', themeName);
        document.documentElement.className = themeName;
        setState((prevState) => ({ ...prevState, themeMode: themeName === 'theme-dark' }));
      };
    
      const clearStorage = () => {
        localStorage.clear();
        sessionStorage.clear();
        const indexedDB = window.indexedDB;
        const request = indexedDB.deleteDatabase('coa-worxOnline');
    
        request.onsuccess = () => {
          setState((prevState) => ({
            ...prevState,
            alertMessage: 'Local storage has been cleared successfully.',
            showAlert: true,
            loading: false,
            isVisible: true,
          }));
        };
    
        request.onerror = () => {
          setState((prevState) => ({
            ...prevState,
            alertMessage: 'Cannot clear local storage.',
            showAlert: true,
            loading: false,
          }));
        };
      };
    
      const alert = () => (
        <AlertDialog
          showDialog={state.showAlert}
          message={state.alertMessage}
          title={Config.productTitle}
          onClose={() => setState((prevState) => ({ ...prevState, alertMessage: '', showAlert: false }))}
        />
      );
    
      if (!state.info && state.loading) {
        return <Spinner label="Loading" />;
      }


  return (
    <Box display="flex" justifyContent="space-between" alignItems="stretch" width="100%" className="profile-container">
      {/* Profile Card */}
      <Card style={{ flex: 1, margin: '0 10px', borderRadius: '10px', boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)', display: 'flex', alignItems: 'center' }}>
        <CardContent style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
          <Avatar 
            alt="{props.currentUser.FirstName} {props.currentUser.LastName}"
            src="" 
            style={{ width: 80, height: 80, marginRight: '15px', backgroundColor: '#E57373', fontSize: '30px', color: 'white' }}
          >
            JD
          </Avatar>
          <Box>
            <Typography variant="h5" style={{ fontWeight: 'bold' }}>Naga Rao</Typography>
            <Typography variant="body2" color="textSecondary" style={{ margin: '5px 0' }}>Admin</Typography>
            <Typography variant="body1">naga@atensystems.com</Typography>
            <Typography variant="body1">0426508470</Typography>
            <Typography variant="body1">ATEN0001</Typography>
          </Box>
        </CardContent>
      </Card>

      {/* Settings Card */}
      <Card style={{ flex: 1, margin: '0 10px', borderRadius: '10px', boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)', display: 'flex', alignItems: 'center' }}>
        <CardContent style={{ width: '100%' }}>
          <Typography variant="h6" style={{ fontWeight: 'bold', marginBottom: '20px' }}>Settings</Typography>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={6}>
              <Typography variant="body1">Switch Theme</Typography>
            </Grid>
            <Grid item xs={6} style={{ textAlign: 'right' }}>
              <Switch defaultChecked color="primary" />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">Clear Storage</Typography>
            </Grid>
            <Grid item xs={6} style={{ textAlign: 'right' }}>
              <Switch color="primary" />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};

export default Profile;