import React, { useEffect, useState, useRef } from 'react'
import { useAssetContext } from '../../../../AssetContext';
import utility from '../../../../classes/utility'
import { Config } from '../../../../classes/config'
import AlertDialog from '../../../Common/AlertDialog'
import AssetSearch from '../../Assets/Assets'
import AssetMap from '../../Assets/AssetMap'
import { Popup } from 'devextreme-react/popup'
import ScrollView from 'devextreme-react/scroll-view'
import { DropzoneComponent } from 'react-dropzone-component'
import Spinner from '../../../Common/ReusableComponents/Spinner'
import DateBoxInput from '../../../Common/ReusableComponents/DateBox'
import SelectBoxInput from '../../../Common/ReusableComponents/SelectInputBox'
import TextBoxInput from '../../../Common/ReusableComponents/TextBox'
import CheckBoxInput from '../../../Common/ReusableComponents/CheckBox'
import TextAreaInput from '../../../Common/ReusableComponents/TextArea'
import { Label } from 'devextreme-react/form'
import { TreeView } from 'devextreme-react'
import Accordion from 'devextreme-react/accordion'
import { TextArea } from 'devextreme-react/text-area'
import DataSource from 'devextreme/data/data_source'
import { SelectBox } from 'devextreme-react/select-box'
import Geocode from 'react-geocode'
import global from '../../../../classes/global'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import IconButton from '@mui/material/IconButton'
import Icon from '@mui/material/Icon'
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation'
import * as Iconly from 'react-iconly'
import InputLabel from '@mui/material/InputLabel'
import DeleteIcon from '@mui/icons-material/Delete'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

let myDropzone: any

interface CreateWOProps {
  data?: any[]
  Assets?: any
  showServiceReq?: boolean
  newWOModal?: boolean
  onClose?: () => void
  onClick?: () => void
  startDate?: Date | null
  endDate?: Date | null
  crewId?: any
  crewName?: string
  myhttpclient?: any
  aadHttpClient?: any
  onAssetSelected?: any
}

interface WOProps {
  role?: any
  Department?: any
  currentUser?: any
  pageListItems?: any
  isContractor?: any
  team?: any
}

interface State {
  Assets: any[]
  defaultAsset: string
  serviceRequest?: string
  location?: string
  department?: { id: any; text: string }
  description?: string
  WRTType?: { id: any; text: string }
  priority?: any
  Eststartdate?: Date | null
  Estenddate?: Date | null
  befpicreq?: boolean
  aftpicreq?: boolean
  riskassessment?: boolean
  crewteam?: { id: any; text: string }
  comment: string
  uploadedFiles?: string
  //Assets?: { AssetNumber: any; AssetName: string }
  lat?: string
  lng?: string
  latLng?: { lat: number; lng: number }
  AssetLocation: string
  Failure?: { id: any; Name: string }
  Cause?: { id: any; Name: string }
  Remedy?: { id: any; text: string }
  team?: { id: any; text: string }
  crew?: { id: any; text: string }
  Address?: any
  assetType?: any
}

const CreateWO: React.FC<CreateWOProps & WOProps> = props => {
  const { previousPage, assetDetailItems } = useAssetContext();
  const [prevPage, setPrevPage] = useState(false)
  const [folderName, setFolderName] = useState('')
  const [departmentID, setDepartmentID] = useState('')
  const [loadingFields, setLoadingFields] = useState(false)
  const [showServiceReq, setShowServiceReq] = useState(false)
  const [WRdata, setWRData] = useState<any[]>([])
  const [suburbItems, setSuburbItems] = useState<any[]>([])
  const [StatusItems, setStatusItems] = useState<any[]>([])
  const [allStatusItems, setAllStatusItems] = useState<any[]>([])
  const [crewItems, setCrewItems] = useState<any[]>([])
  const [departmentItems, setDepartmentItems] = useState<any[]>([])
  const [WRTTypeItems, setWRTTypeItems] = useState<any[]>([])
  const [employeeItems, setEmployeeItems] = useState<any[]>([])
  const [priorityItems, setPriorityItems] = useState<any[]>([])
  const [allPriorityItems, setAllPriorityItems] = useState<any[]>([])
  const [fileTitle, setFileTitle] = useState<string>('')
  const [loadingAssetDetail, setLoadingAssetDetail] = useState(false)
  const assetTypeRef = useRef('')

  //const [Assets, setAssets] = useState<any>('')
  const [uploadedFiles, setUploadedFiles] = useState<any[]>([])
  const [dataURL, setDataURL] = useState<any[]>([])
  const [currentUser, setCurrentUser] = useState<any>({})
  const [responseData, setResponseData] = useState<any[]>([])
  const [treeView, setTreeView] = useState<any>(null)
  const [teamsItems, setTeamsItems] = useState<any[]>([])
  const [tempTeamItems, setTempTeamItems] = useState<any[]>([])
  const [location, setLocation] = useState('')
  const [suburb, setSuburb] = useState('')
  const [WRTType, setWRTType] = useState<any>(null)
  const [title, setTitle] = useState('')

  //const [Eststartdate, setEstStartDate] = useState(new Date())
  //const [Estenddate, setEstEndDate] = useState(new Date())
  //const [befpicreq, setBefPicReq] = useState(false)
  //const [aftpicreq, setAftPicReq] = useState(false)
  //const [riskassessment, setRiskAssessment] = useState(false)
  const [crewteam, setCrewTeam] = useState('')
  const [comments, setComments] = useState('')
  const [description, setDescription] = useState('')

  //const [failureItem, setFailureItem] = useState<any[]>([])
  const [allDepartmentItems, setAllDepartmentItems] = useState<any[]>([])
  const [allSuburbItems, setAllSuburbItems] = useState<any[]>([])
  const [tempCrewItems, setTempCrewItems] = useState<any[]>([])
  const [FailureItems, setFailureItems] = useState<any[]>([])
  const [CauseItems, setCauseItems] = useState<any[]>([])
  const [RemedyItems, setRemedyItems] = useState<any[]>([])
  const [alertMessage, setAlertMessage] = useState<React.ReactNode | string>('')
  const [showAlert, setShowAlert] = useState(false)
  const [woData, setWoData] = useState<any>({})
  const [hideAlertButton, setHideAlertButton] = useState(false)
  const [loading, setLoading] = useState(false)
  const [showPanel, setShowPanel] = useState(false)
  const [loadingWODashboard, setLoadingWODashboard] = useState(false)
  const [IframePanelOpened, setIframePanelOpened] = useState(false)
  const [selectModeMap, setSelectModeMap] = useState(false)
  const [selectParent, setSelectParent] = useState(false)
  const [allTempCrewItems, setAllTempCrewItems] = useState<any[]>([])
  const [allTempTeamItems, setAllTempTeamItems] = useState<any[]>([])
  const [disableCrew, setDisableCrew] = useState(false)
  const [isTreeBoxOpened, setIsTreeBoxOpened] = useState(false)
  const [isPaperVisible, setIsPaperVisible] = useState(false)
  const [allWRTTypeItems, setAllWRTTypeItems] = useState<any[]>([])
  const [allworkGroupItems, setAllworkGroupItems] = useState<any[]>([])
  const [workGroupItems, setWorkGroupItems] = useState<any[]>([])
  const [workGroup, setWorkGroup] = useState([])

  const [state, setState] = useState<State>({
    Assets: [],
    defaultAsset: '',
    serviceRequest: '',
    //location: '',
    //department: { id: '', text: '' },
    description: '',
    //WRTType: { id: '', text: '' },
    priority: null,
    Eststartdate: null,
    Estenddate: null,
    befpicreq: false,
    aftpicreq: false,
    riskassessment: false,
    crewteam: { id: '', text: '' },
    comment: '',
    uploadedFiles: '',
    lat: '',
    lng: '',
    latLng: { lat: 0, lng: 0 },
    AssetLocation: '',
    Failure: { id: '', Name: '' },
    Cause: { id: '', Name: '' },
    Remedy: { id: '', text: '' },
    team: { id: '', text: '' },
    Address: '',
    assetType: ''
  })

  useEffect(() => {
    const currentDetail = localStorage.getItem('currentDetail')
    setCurrentUser({ Email: currentDetail })

    const endDate = new Date()
    endDate.setHours(endDate.getHours() + 1)

    setLocation(props.data ? props.data[0]?.location : '')
    setComments(props.data ? props.data[0]?.comments : '')
    setDescription(props.data ? props.data[0]?.workRequestDescription : '')

    if (
      props.data &&
      Array.isArray(props.data) &&
      props.data.length > 0 &&
      Array.isArray(props.data[0].assetIds) &&
      props.data[0].assetIds !== null
    ) {
      loadAssetDetails(props.data[0].assetIds)
    }
    else if(previousPage === '/AssetDetail') { 
        if(assetDetailItems
        && assetDetailItems.assetData.AssetID && assetDetailItems.assetData.AssetID !== null) {
        loadAssetDetails(assetDetailItems.assetData.AssetID)
      }
    }
    

    const fetchData = async () => {
      try {
        await _getworkGroupData()
        await _getDepartmentData()
        await _getSuburbDataAsync()
        _getWOSchedulerDate()
        await _getTeamDataAsync()
        await _getStatusDataAsync()
        await _getWorkTypeDataAsync(false)
        await _getCrewDataAsync()
        await _getPrioritiesData()

        console.log('---all async/await done---')
        setLoadingFields(true)
        setShowServiceReq(props.showServiceReq ? props.showServiceReq : false)
        setWRData(props.data ? props.data : [])
      } catch (error) {
        console.error('Error:', error)
      }
    }

    fetchData()
  }, [props.data])

  useEffect(() => {
    assetTypeRef.current = state.assetType
  }, [state.assetType])

  const loadAssetDetails = (assetId: any) => {
    setLoadingAssetDetail(true)
    //let dataToPassToService: any = {}
    //dataToPassToService.id = assetId

    utility
      .genericGetDataByIdAPICall(Config.GetAssetDetails, assetId)
      .then((data: any) => {
        let assetDetailsItems: any[] = []
        if (data.length > 0) {
          data.forEach((item: any) => {
            assetDetailsItems.push({
              AssetClass: item.asset_class?.title,
              AssetID: item.id,
              AssetName: item.title,
              AssetNumber: item.id,
              AssetStatus: item.asset_status?.status,
              AssetType: item.asset_type?.title,
              AssetTypeId: item.asset_type?.id,
              Category: item.asset_category?.title,
              Description: item.description,
              Location: item.functional_location_l1_name,
              Register: item.registerTitle,
              accntSystemId: item.accnt_system_id,
              assetCriticality: item.asset_criticality,
              assetsubclass: item.asset_sub_class?.title,
              coordinates: item.coordinates,
              departmentTitle: item.department_title,
              erpAssetId: item.erp_asset_id,
              erpExtAssetId: item.erp_asset_id,
              erpExtrenalAsssetName: item.ext_system_name,
              id: item.id,
              maintenanceAssetType: item.maintenance_asset_type,
              masterAssetId: item.master_asset_id,
              parentAssetId: item.parent_asset_id,
              subTypeTitle: item.asset_sub_type?.title,
              teamTitle: item.team_title,
              typeTitle: item.type_title,
              workGroupIds: item.work_group_ids,
              Selected: false
            })
          })
          selectAssets(assetDetailsItems)
          setState(prevState => ({
            ...prevState,
            Assets: assetDetailsItems[0]
          }))
          setLoadingAssetDetail(false)
        } else {
          setState(prevState => ({
            ...prevState,
            loadingAssetDetail: false
          }))
        }
      })
      .catch((err: any) => {
        console.log(err)
        setState(prevState => ({
          ...prevState,
          assetdetails: []
        }))
      })
  }

  const _getworkGroupData = async () => {
    try {
      const data: any = await utility.genericGetAPICall(Config.workGroup)
      let selectedWG: any = []
      const workGroupItems = data.map((r: any) => ({
        id: r.id,
        text: r.title,
        description: r.description,
        erpId: r.erp_id,
        defaultSkillId: r.default_skill_id,
        'Select All': r.title
      }))
      const sortedItems = utility._sortItems(workGroupItems, 'text', false)
      setWorkGroupItems(sortedItems)
      setAllworkGroupItems(sortedItems)
      setWorkGroup(selectedWG)
    } catch (err) {
      console.log(err)
    }
  }

  const _getDepartmentData = async () => {
    utility
      .genericGetAPICall(Config.DepartmentList)
      .then((data: any) => {
        const departmentItems = data.map((r: any) => ({ id: r.id, text: r.title }))
        const sortedDepartmentItems = utility._sortItems(departmentItems, 'text', false)

        setDepartmentItems(sortedDepartmentItems)
        setAllDepartmentItems(sortedDepartmentItems)
      })
      .catch(err => {
        console.log(err)
      })
  }

  const _getSuburbDataAsync = async () => {
    utility
      .genericGetAPICall(Config.suburblistname)
      .then((data: any) => {
        const suburbItems = data.map((r: any) => ({
          id: r.Id,
          text: r.Title,
          lat: r.Latitude,
          lng: r.Longitude
        }))

        setSuburbItems(suburbItems)
        setAllSuburbItems(suburbItems)
      })
      .catch(err => {
        console.log(err)
      })
  }

  const _getWOSchedulerDate = () => {
    if (props.startDate && props.endDate) {
      setState(prevState => ({
        ...prevState,
        Eststartdate: new Date(props.startDate as Date),
        Estenddate: new Date(props.endDate as Date)
      }))
    }

    if (props.crewId && props.crewName) {
      setState(prevState => ({
        ...prevState,
        crewteam: { id: props.crewId, text: props.crewName || '' }
      }))
    }
  }

  const _getTeamDataAsync = async () => {
    utility
      .genericGetAPICall(Config.TeamList)
      .then((data: any) => {
        const teamItems: any = data.map((r: any) => ({ id: r.id, text: r.title, department_id: r.department_id }))
        const sortedItems = utility._sortItems(teamItems, 'text', false)
        setTeamsItems(sortedItems)
        setTempTeamItems(sortedItems)
      })
      .catch(err => {
        console.log(err)
      })
  }

  const _getStatusDataAsync = async () => {
    const dataToPassToService: any = {}
    dataToPassToService.isActive = true
    utility
      .genericGetAPICall(Config.statuslist)
      .then((data: any) => {
        const filteredData = data.filter((r: any) => r.is_active)
        const statusItems = filteredData.map((r: any) => ({ id: r.id, text: r.title }))
        setStatusItems(statusItems)
        setAllStatusItems(statusItems)
      })
      .catch(err => {
        console.log(err)
      })
  }

  const _getWorkTypeDataAsync = async (
    isAssetSelected: any,
    workGroupIds?: any,
    projectIds?: any,
    activitys?: any,
    taskIds?: any
  ) => {
    let workGroupId = workGroupIds
      ? workGroupIds
      : props.Assets !== undefined && props.Assets.length > 0
      ? props.Assets[0]?.workGroupIds
      : ''
    let projectId = projectIds
      ? projectIds
      : props.Assets !== undefined && props.Assets.length > 0
      ? props?.Assets[0]?.accntSystemId
      : ''
    let taskId = taskIds
      ? taskIds
      : props.Assets !== undefined && props.Assets.length > 0
      ? props?.Assets[0]?.taskId
      : ''
    let activity = activitys
      ? activitys
      : props.Assets !== undefined && props.Assets.length > 0
      ? props?.Assets[0]?.activityId
      : ''
    let sortedItems: any = []
    let filtereddata: any
    try {
      const data: any = await utility.genericGetAPICall(`${Config.WTlistname}`)
      let isActivity: boolean = false
      filtereddata = workGroupId
        ? data.filter((f: any) => {
            const hasWorkGroupIds = f.work_group_ids && f.work_group_ids.length > 0
            const hasMatchingWorkGroup =
              hasWorkGroupIds && f.work_group_ids.some((value: any) => workGroupId.includes(value))
            const isMatchingCategory = f.category === global.filterWOType
            const hasMatchingProject = projectId ? f.project_id === projectId : true
            const hasMatchingActivity = activity ? f.activity === activity : true
            const hasMatchingTask = taskId ? f.task_id === taskId : true
            if (workGroupId) {
              isActivity = hasMatchingWorkGroup && isMatchingCategory
              if (projectId !== '' || projectId !== null) {
                isActivity = isActivity && hasMatchingProject
              }
              if (activity !== '' || projectId !== null) {
                isActivity = isActivity && hasMatchingActivity
              }
              if (taskId !== '' || projectId !== null) {
                isActivity = isActivity && hasMatchingTask
              }
              return isActivity
            } else {
              return isMatchingCategory
            }
          })
        : data.filter((f: any) => f.category === global.filterWOType)
      const allWorkTypeItems = data.filter((f: any) => f.category === global.filterWOType)

      const mapItem = (item: any) => ({
        id: item.id,
        text: item.title,
        categoryId: item.workRequestTypeId,
        category: item.category,
        befpicreq: item.photo_required_before ? true : false,
        aftpicreq: item.photo_required_after ? true : false,
        riskassessment: item.risk_assessment_required ? true : false,
        department_id: item.department_id,
        teamId: item.team_ids,
        workGroupId: item.work_group_ids
      })
      const WRTTypeItems = filtereddata.map(mapItem)
      const allMappedWorkTypeItems = allWorkTypeItems.map(mapItem)

      const sortedItems = utility._sortItems(WRTTypeItems, 'text', false)
      const sortedAllItems = utility._sortItems(allMappedWorkTypeItems, 'text', false)
      setWRTTypeItems(sortedItems)
      setAllWRTTypeItems(sortedAllItems)

      if (sortedItems && sortedItems.length === 1) {
        const selectedeprt = allDepartmentItems.filter((d: any) => d.id === sortedItems[0].department_id)
        const selectedteam = teamsItems.filter((d: any) => sortedItems[0].teamId.includes(d.id))
        const teamArray = selectedteam.map((item: any) => item.id)
        const selectedcrew = crewItems.filter((d: any) => teamArray.includes(d.teamId))
        setState(prevState => ({
          ...prevState,
          WRTType: sortedItems[0],
          department: selectedeprt ? selectedeprt[0] : '',
          team: selectedteam && selectedteam.length === 1 ? selectedteam[0] : ''
        }))
        setDepartmentItems(selectedeprt)
        setTempTeamItems(selectedteam)
        setTempCrewItems(selectedcrew)
      } else if (sortedItems.length <= 0) {
        setAlertMessage('Work Group is not available for this Asset. Please select different Asset.')
        setShowAlert(true)
      }
    } catch (err) {
      console.log(err)
      throw err
    }
  }

  const processFCRCode = (codes: any, FailureItems: any[], CauseItems: any[]) => {
    const category = codes.code + ' - ' + codes.title

    if (codes.failureSubCodes) {
      codes.failureSubCodes.forEach((subCode: any) => {
        FailureItems.push({
          Category: category,
          ID: subCode.id,
          Name: codes.code + ',' + subCode.code + ' - ' + subCode.title
        })
      })
    }

    if (codes.causeSubCodes) {
      codes.causeSubCodes.forEach((causeSubCode: any) => {
        CauseItems.push({
          Category: category,
          ID: causeSubCode.id,
          Name: codes.code + ',' + causeSubCode.code + ' - ' + causeSubCode.title,
          isDefault: causeSubCode.isDefault
        })
      })
    }
  }

  const _getCrewDataAsync = async () => {
    utility
      .genericGetAPICall(Config.crewlistname)
      .then((data: any) => {
        const crewItems = data.map((r: any) => ({
          id: r.id,
          text: r.title,
          teamId: r.team?.id,
          department_id: r.team?.department?.id
        }))
        const sortedCrewItems = utility._sortItems(crewItems, 'text', false)

        setCrewItems(sortedCrewItems)
        setTempCrewItems(sortedCrewItems)
      })
      .catch(err => {
        console.log(err)
      })
  }

  const _getPrioritiesData = async() => {
    try {
      const data: any = await utility.genericGetAPICall(Config.PriorityListName);
      const filteredItems = data
        .filter((r: any) => r.is_active && r.type === "WorkOrder")
        .map((r: any) => ({ id: r.id, text: r.title }));

      const sortedItems = utility._sortItems(filteredItems, "text", false);
      setPriorityItems(sortedItems);
      setAllPriorityItems(sortedItems);
    } catch (err) {
      console.log(err);
    }
  }
  const clearFilter = () => {
    myDropzone.removeAllFiles(true)
    setIsPaperVisible(false)
    setFileTitle('')
    setState(prevState => ({
      ...prevState,
      Assets: [],
      defaultAsset: '',
      serviceRequest: '',
      location: '',
      department: { id: '', text: '' },
      WRTType: { id: '', text: '' },
      priority: null,
      Eststartdate: null,
      Estenddate: null,
      befpicreq: false,
      aftpicreq: false,
      riskassessment: false,
      crewteam: { id: '', text: '' },
      comment: '',
      uploadedFiles: '',
      //Assets: { AssetNumber: '', AssetName: '' },
      team: { id: '', text: '' },
      lat: '',
      lng: ''
    }))

    if (props.newWOModal && props.onClose) {
      props.onClose()
    }
  }

  const _getFCRData = async (maintenanceType: string) => {
    const api = Config.FcrCategorisation + maintenanceType

    try {
      const data: any = await utility.genericGetAPICallForList(api)

      if (data && data.failureCodes.length > 0) {
        const updatedFailureItems: any[] = []
        const updatedCauseItems: any[] = []
        const updatedRemedyItems: any[] = []
        let defaultRemedy: any | null = null
        let defaultCause: any | null = null

        data.failureCodes.forEach((failureCode: any) => {
          processFCRCode(failureCode, updatedFailureItems, updatedCauseItems)
        })

        data.causeCodes.forEach((causeCode: any) => {
          processFCRCode(causeCode, updatedFailureItems, updatedCauseItems)
        })

        data.remedyCodes.forEach((remedyCode: any) => {
          updatedRemedyItems.push({
            id: remedyCode.id,
            text: `${remedyCode.code} - ${remedyCode.title}`,
            isDefault: remedyCode.isDefault
          })

          if (remedyCode.isDefault === true) {
            defaultRemedy = {
              id: remedyCode.id,
              text: `${remedyCode.code} - ${remedyCode.title}`,
              isDefault: remedyCode.isDefault
            }
          }
        })

        const fromUngroupedData: any = new DataSource({
          store: {
            type: 'array',
            data: updatedFailureItems,
            key: 'ID'
          },
          group: 'Category'
        })

        const fromCauseUngroupedData: any = new DataSource({
          store: {
            type: 'array',
            data: updatedCauseItems,
            key: 'ID'
          },
          group: 'Category'
        })

        defaultCause = updatedCauseItems
          .filter((item: any) => item.isDefault)
          .map((r: any) => ({ id: r.ID, Name: r.Name }))

        setFailureItems(fromUngroupedData)
        setCauseItems(fromCauseUngroupedData)
        setRemedyItems(updatedRemedyItems)
        setState(prevState => ({
          ...prevState,
          Remedy: defaultRemedy,
          Cause: defaultCause[0]
        }))
      } else {
        setAlertMessage('You cannot select this Asset for the work order. Please select a different Asset.')
        setShowAlert(true)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const _closeDialog = (): void => {
    setAlertMessage('')
    setShowAlert(false)
    if (prevPage) {
      if (props.newWOModal && props.onClick) {
        props.onClick()
      } else {
        const location = window.location.origin + window.location.pathname
        const SearchWO = `${location}#/WorkOrderDetail?WOID=${woData[0].id}`
        window.open(SearchWO, '_self')
      }
    }
  }

  const handleEvent = (file: any) => {
    onDropGeneral(file)
    setUploadedFiles([...uploadedFiles, { file }])
    setFileTitle(file.name)
  }

  const onDropGeneral = (file: any) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = event => {
      setDataURL([...dataURL, { file: file.name, dataUrl: event ? event.currentTarget : '' }])
    }
  }

  const deleteFile = (file: any) => {
    let deletingIndex = 0
    uploadedFiles.forEach((element: any, index: any) => {
      if (element.file.name === file.name) {
        deletingIndex = index
      }
    })
    uploadedFiles.splice(deletingIndex, 1)
    dataURL.forEach((element: any, index: any) => {
      if (element.file.name === file.name) {
        deletingIndex = index
      }
    })
    dataURL.splice(deletingIndex, 1)
  }

  const createWorkOrder = () => {
    let accepted = true

    if (uploadedFiles) {
      uploadedFiles.forEach((item: any) => {
        if (item.file.accepted === false) {
          accepted = false
        }
      })

      if (!accepted) {
        setAlertMessage('Selected file is not accepted. Please select another file.')
        setShowAlert(true)

        return
      }
    }

    // if (!state.WRTType || Object.keys(state.WRTType).length == 0) {
    //   setAlertMessage('Please select Work Order')
    //   setShowAlert(true)

    //   return
    // }

    if (!state.location) {
      setAlertMessage('Please enter Location')
      setShowAlert(true)

      return
    }

    if (!state.department) {
      setAlertMessage('Please select Department')
      setShowAlert(true)

      return
    }

    if (state.Eststartdate && state.Estenddate) {
      const Eststartdate = new Date(state.Eststartdate)
      const Estenddate = new Date(state.Estenddate)

      if (Estenddate.getTime() < Eststartdate.getTime()) {
        setAlertMessage('Scheduled Start Date should be greater than Scheduled Finish Date')
        setShowAlert(true)

        return
      }
    }

    if (!state.Assets) {
      setAlertMessage('Please select Asset')
      setShowAlert(true)
      return
    }

    if (!state.description) {
      setAlertMessage('Please select Description')
      setShowAlert(true)
      return
    }

    setAlertMessage(<Spinner label='Creating work order' />)
    setHideAlertButton(true)
    setShowAlert(true)

    const JSONAsset = ''
    const dataToPassToService: any = {}

    if (props.data) {
      dataToPassToService.work_request_id = props.data[0].id
    }

    if (state.Eststartdate && state.Estenddate && state.crewteam) {
      dataToPassToService.workorder_status_id = 2
    } else {
      dataToPassToService.workorder_status_id = 5
    }

    if (state.Eststartdate && state.Eststartdate != null) {
      dataToPassToService.date_scheduled_start = new Date(state.Eststartdate).toISOString()
    }

    if (state.Estenddate && state.Estenddate != null) {
      dataToPassToService.date_scheduled_end = new Date(state.Estenddate).toISOString()
    }

    if (state.priority) {
      dataToPassToService.priority_id = state.priority?.id
    }

    dataToPassToService.title = state.WRTType ? state.WRTType.text : ''
    dataToPassToService.description = state.description ? state.description : ''
    dataToPassToService.workorder_type_id = state.WRTType ? state.WRTType.id : 0
    dataToPassToService.department_id = state.department.id
    dataToPassToService.workorder_location = state.location
    //dataToPassToService.failure_code_id = state.Failure ? state.Failure.id : 0
    //dataToPassToService.cause_code_id = state.Cause ? state.Cause.id : 0
    //dataToPassToService.remedy_code_id = state.Remedy ? state.Remedy.id : 0
    dataToPassToService.crew_id = state.crewteam ? +state.crewteam.id : null
    dataToPassToService.risk_assessment_required = state.riskassessment ? true : false
    dataToPassToService.photo_required_before = state.befpicreq ? true : false
    dataToPassToService.photo_required_after = state.aftpicreq ? true : false
    dataToPassToService.team_id = state.team ? state.team.id : 0
    
    if (state.lat && state.lng) {
      dataToPassToService.geom =  `{"point(${state.lng} ${state.lat})"}`;
    } 
  
    if (state.Assets && state.Assets.length > 0) {
      //JSONAsset = JSON.stringify(state.Assets)
      dataToPassToService.asset_ids = state.Assets.map((asset: any) => (asset.id ? asset.id : asset.AssetID))
    }

    utility
      .genericPostAPICall(Config.workorderlistname, dataToPassToService)
      .then((data: any) => {
        setWoData(data)

        if (uploadedFiles.length > 0) {
          getWorkOrderData(data)
        } else {
          setAlertMessage(`Work order (${data[0].id}) has been created`)
          setShowAlert(true)
          setHideAlertButton(false)
          setPrevPage(true)
          clearFilter()
        }

        if (props.data) {
          updateWRStatus(props.data[0].id)
        }
      })
      .catch(err => {
        console.log(err)
        setAlertMessage('Something went wrong. Please try again')
        setShowAlert(true)
        setHideAlertButton(false)
        setLoading(false)
      })
  }

  const updateWRStatus = (id: any) => {
    const dataToPassToService: any = {}
    dataToPassToService.workRequestStatusId = 3
    dataToPassToService.id = id
    utility.genericUpdateAPICall(Config.WRList, id, dataToPassToService).then((results: any) => {})
  }

  const getWorkOrderData = (wodata: any) => {
    setAlertMessage(<Spinner label='Uploading documents...' />)
    setHideAlertButton(true)
    setShowAlert(true)

    const file = uploadedFiles
    const formData = new FormData()
    const dataToPassToService: any = {}

    for (let i = 0; i < file.length; i++) {
      const tempFileExt = file[i].file.name.split('.')
      tempFileExt.reverse()
      const fileExtension = `.${tempFileExt[0]}`
      const fileName = fileTitle

      formData.append(
        'data',
        JSON.stringify({ workorder_id: wodata.id, title: tempFileExt[1], document_type: 'photo' })
      )
      console.log('data', JSON.stringify({ workorder_id: wodata.id, title: tempFileExt[1], document_type: 'photo' }))
      formData.append('files', file[i].file)
      console.log('files', file[i].file)

      utility
        .genericPostAPIcallForWODocument(Config.workorderDocumentList, formData)
        .then((data: any) => {
          setAlertMessage('Work order (' + wodata.id + ') has been created')
          setHideAlertButton(true)
          setShowAlert(false)
          setLoading(false)
          clearFilter()
        })
        .catch(err => {
          console.log(err)
          setAlertMessage('Something went wrong. Please try again')
          setHideAlertButton(true)
          setShowAlert(false)
        })
    }
  }

  const renderAssetPanel = () => {
    return (
      <div>
        <Popup
          width={'80vw'}
          maxHeight={'85vh'}
          minHeight={200}
          height={'auto'}
          showTitle={true}
          showCloseButton={true}
          dragEnabled={true}
          hideOnOutsideClick={false}
          visible={showPanel}
          onVisibleChange={_onClosePanel}
        >
          <ScrollView width='100%' height='100%' showScrollbar='onScroll' scrollByThumb={true}>
            <AssetSearch
              selectMode={true}
              onClick={(Asset: any) => selectAssets(Asset)}
              heading={false}
              selectWO={true}
              hideAssetMapButton={true}
              myhttpclient={props.myhttpclient}
              aadHttpClient={props.aadHttpClient}
              onAssetsSelected={(Asset: any) => selectAssets(Asset)}
            />
          </ScrollView>
        </Popup>
      </div>
    )
  }

  const CustomItem = (data: any) => {
    return (
      <div>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                Request #
              </Grid>
              <Grid item xs={12} sm={8} className={'semiBold'}>
                {data.id}
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                Request Type
              </Grid>
              <Grid item xs={12} sm={8} className={'semiBold'}>
                {data.workRequestTypeTitle}
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                Request Status
              </Grid>
              <Grid item xs={12} sm={8} className={'semiBold'}>
                {data.workRequestStatusTitle}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    )
  }

  const CustomTitle = (data: any, i: any) => {
    return (
      <div style={{ width: '100%' }} className='displayFlex'>
        <div className='workTypeListItemTitle'>Work Request Details</div>
        <div
          className='displayFlex'
          style={{ float: 'right', marginRight: '25px', width: '47%', flexDirection: 'row', justifyContent: 'end' }}
        ></div>
      </div>
    )
  }

  const selectAssets = async (selectedAssetArray: any) => {
    if (!Array.isArray(selectedAssetArray)) {
      selectedAssetArray = [selectedAssetArray]
    }
    if (selectedAssetArray.length > 1) {
      // Extract asset types and check if they are all the same
      const assetTypes = selectedAssetArray.map((asset: any) => asset.AssetType)
      const allSameType = assetTypes.every((type: string, _: any, array: string[]) => type === array[0])
      if (!allSameType) {
        setAlertMessage(
          'The selected assets do not all have the same asset type. Please select assets of the same type.'
        )
        setShowAlert(true)
        return
      }
    }

    let isFirstAsset: boolean = state.Assets.length === 0
    const selectedAssets: any = state.Assets
    const initialAssetType = state.assetType
    let duplicateAssets: any[] = []

    for (let i = 0; i < selectedAssetArray.length; i++) {
      let Asset = selectedAssetArray[i]
      // check for duplicates

      if (isFirstAsset) {
        const chosenAssetType = Asset.AssetType
        const chosenAssetypeID = Asset.AssetID
        try {
          await _getWorkTypeDataAsync(true, Asset.workGroupIds, Asset.accntSystemId, Asset.activityId, Asset.taskId)
        } catch (error) {
          setAlertMessage(
            `Work Group is not available for this Asset ${chosenAssetypeID}. Please choose different Asset.`
          )
          setShowAlert(true)
          return
        }

        //_getFCRData(Asset.maintenanceAssetType)
        selectedAssets.push(Asset)
        setState(prevState => ({
          ...prevState,
          defaultAsset: Asset,
          Assets: selectedAssets,
          assetType: chosenAssetType,
          Address: Asset.Location,
          location: Asset.Location,
          latLong: Asset.coordinates,
          mode: 'SearchAssetList',
          showPanel: false
        }))
        assetTypeRef.current = chosenAssetType
        isFirstAsset = false
      } else {
        const isDuplicateAsset = selectedAssets.some(
          (existingAsset: any) => existingAsset.AssetID === Asset.AssetID || existingAsset.AssetID == Asset.AssetID
        )
        if (isDuplicateAsset) {
          duplicateAssets.push(Asset.AssetID)
          setAlertMessage(`Asset ID ${duplicateAssets} is already added. Please select a different Asset`)
          setShowAlert(true)
          continue
        }

        const assetType = assetTypeRef.current // Get the stored assetType
        if (Asset.AssetType === assetType) {
          // If assetType matches, add the asset
          selectedAssets.push(Asset)
          setState(prevState => ({
            ...prevState,
            Assets: selectedAssets
          }))
          setShowPanel(false)
        } else {
          setAlertMessage(
            `The selected asset does not match the asset type of the initially selected asset. You can only select assets of the same type (${assetType}). Please choose a different asset.`
          )
          setShowAlert(true)
          continue // skip to next Asset
        }
      }
    }
    setShowPanel(false)
    setIsPaperVisible(true)
  }

  const getLatLongbyAddress = (place: any) => {
    let address: any = place.toLowerCase()
    if (address.includes('street')) {
      address = address.replace('street', 'st')
    }
    Geocode.fromAddress(address)
      .then(response => {
        const { lat, lng } = response.results[0].geometry.location
        if (lat && lng) {
          setState(prevState => ({
            ...prevState,
            lat: lat,
            lng: lng
          }))
        }
      })
      .catch(err => {
        console.error(err)
        setLoading(false)
        setLoadingWODashboard(false)
      })
  }

  const SearchClicked = () => {
    setIframePanelOpened(true)
    setSelectModeMap(true)
    setSelectParent(false)
  }

  const renderAssetMap = () => {
    return (
      <AssetMap
        address={state.location}
        polylines={false}
        selectAssets={(Asset: any) => selectAssetsonMap(Asset)}
        iFramePanelOpened={IframePanelOpened}
        onClose={() => _onDismissMap()}
        selectParent={selectParent}
        selectModeMap={selectModeMap}
      />
    )
  }

  const selectAssetsonMap = async (selectedAssetArray: any[]) => {
    if (selectedAssetArray.length > 1) {
      // Extract asset types and check if they are all the same
      const assetTypes = selectedAssetArray.map((asset: any) => asset.type)
      const allSameType = assetTypes.every((type: string, _: any, array: string[]) => type === array[0])
      if (!allSameType) {
        setAlertMessage(
          'The selected assets do not all have the same asset type. Please select assets of the same type.'
        )
        setShowAlert(true)
        return
      }
    }
    const flatCoordinates: { lat: number; lng: number }[] = []
    let tempAsset: any
    let isFirstAsset: boolean = state.Assets.length === 0
    const selectedAssets: any = [...state.Assets]
    const initialAssetType = state.assetType
    let duplicateAssets: any[] = []

    for (let i = 0; i < selectedAssetArray.length; i++) {
      let Asset = selectedAssetArray[i]
      let AssetProperties = JSON.parse(Asset.properties)

      if (isFirstAsset) {
        const chosenAssetType = Asset.type

        const workgroup = workGroupItems
          .filter((r: any) => r.text === AssetProperties.Asset_Workgroup)
          .map((item: any) => item.id)
        if (workgroup.length > 0 && workgroup !== null) {
          try {
            await _getWorkTypeDataAsync(
              true,
              workgroup,
              AssetProperties.Ext_Accounting_System_1_ID2,
              AssetProperties.activityId,
              AssetProperties.taskId
            )
          } catch (error) {
            setAlertMessage(`Work Group is not available for this Asset ${Asset.id}. Please choose different Asset.`)
            setShowAlert(true)
            return
          }
        } else {
          setAlertMessage(`Work Group is not available for Asset ${Asset.id}. Please choose different Asset.`)
          setShowAlert(true)
          setShowPanel(false)
          return
        }
        //_getFCRData(AssetProperties.Maintenance_Asset_Type);
        let flatCoordinatesArray = AssetProperties?.geometry?.flatCoordinates
        if (flatCoordinatesArray) {
          flatCoordinatesArray.forEach((element: any, i: any) => {
            if (i % 2 === 0) {
              flatCoordinates.push({
                lat: flatCoordinatesArray[i + 1],
                lng: flatCoordinatesArray[i]
              })
            }
          })
        }

        let Parseddata = JSON.parse(selectedAssetArray[0].properties)
        selectedAssets.push(Asset)
        setState(prevState => ({
          ...prevState,
          defaultAsset: Asset,
          Assets: selectedAssets,
          mode: 'MapList',
          assetType: chosenAssetType,
          lat: selectedAssetArray[0].center[1].toString(),
          lng: selectedAssetArray[0].center[0].toString(),
          latLong: flatCoordinates[0],
          location: Parseddata.Location ? Parseddata.Location : '',
          assetlocation: Parseddata.Location + ' Adelaide SA Australia',
          iFramePanelOpened: false,
          selectParent: false,
          selectModeMap: false
        }))
        assetTypeRef.current = chosenAssetType
        isFirstAsset = false
      } else {
        // check for duplicates
        const isDuplicateAsset = selectedAssets.some(
          (existingAsset: any) =>
            existingAsset.AsseticAssetID === Asset.AsseticAssetID || existingAsset.erpAssetId == Asset.AsseticAssetID
        )
        if (isDuplicateAsset) {
          duplicateAssets.push(Asset.AsseticAssetID)
          setAlertMessage(`Asset ID ${duplicateAssets} is already added. Please select a different Asset`)
          setShowAlert(true)
          continue // Skip to next asset
        }

        // Check if the asset type matches the first asset
        const assetType = assetTypeRef.current // Get the stored assetType
        if (Asset.type === assetType) {
          // If assetType matches, add the asset
          selectedAssets.push(Asset)
          setState(prevState => ({
            ...prevState,
            Assets: selectedAssets
          }))
          setIframePanelOpened(false)
        } else {
          setAlertMessage(
            `The selected asset does not match the asset type of the initially selected asset. You can only select assets of the same type (${assetType}). Please choose a different asset.`
          )
          setShowAlert(true)
          setIframePanelOpened(false)
          continue // Skip to next asset
        }
      }
    }
    setIframePanelOpened(false)
    setSelectParent(false)
    setSelectModeMap(false)
    setIsPaperVisible(true)
  }

  const _onDismissMap = () => {
    setIframePanelOpened(false)
  }

  const _onShowPanel = (): void => {
    setShowPanel(true)
  }

  const _onClosePanel = (): void => {
    setShowPanel(false)
    setIsPaperVisible(true)
  }

  const removeAssetNumber = (index: number) => {
    // Check if the index is valid
    if (index >= 0 && index < state.Assets.length) {
      // Create a new array of assets excluding the one at the specified index
      const updatedAssets: any = [...state.Assets]
      updatedAssets.splice(index, 1)

      // Check if the removed asset was the last one
      if (updatedAssets.length === 0) {
        let endDate = new Date()
        endDate.setHours(endDate.getHours() + 1)

        setState(prevState => ({
          ...prevState,
          Assets: [],
          defaultAsset: '',
          serviceRequest: '',
          location: '',
          department: { id: '', text: '' },
          WRTType: { id: '', text: '' },
          priority: null,
          Eststartdate: new Date(),
          Estenddate: new Date(endDate),
          befpicreq: false,
          aftpicreq: false,
          riskassessment: false,
          crewteam: { id: '', text: '' },
          comment: '',
          uploadedFiles: '',
          //Assets: { AssetNumber: '', AssetName: '' },
          team: { id: '', text: '' },
          lat: '',
          lng: ''
        }))
        setIsPaperVisible(false)
      } else {
        setState(prevState => ({
          ...prevState,
          Assets: updatedAssets
        }))
      }
    }
  }

  const handleValueChange = (event: any, fieldName: string) => {
    if (fieldName === 'Eststartdate' && event.value) {
      const endDate = new Date(event.value)
      endDate.setHours(event.value.getHours() + 1)
      setState(prevState => ({
        ...prevState,
        Estenddate: new Date(endDate)
      }))
    }

    setState(prevState => ({
      ...prevState,
      [fieldName]: event.value
    }))
  }

  const handleDropdownChange = (e: any, fieldName: string) => {
    if (fieldName === 'WRTType' && e.selectedItem) {
      setDepartmentItems([])
      setTempTeamItems([])

      const filteredTeam: any = []
      teamsItems.forEach((element: any) => {
        if (e.selectedItem?.teamId && e.selectedItem?.teamId.some((g: any) => g === element.id)) {
          filteredTeam.push(element)
        }
      })
      const selectedDeptItem = allDepartmentItems
        .filter((r: any) => r.id === e.selectedItem.department_id)
        .map((r: any) => ({ id: r.id, text: r.text }))
      const filteredCrew: any = []
      crewItems.forEach((element: any) => {
        if (
          e.selectedItem?.teamId &&
          e.selectedItem?.teamId.some(
            (g: any) => g === element.teamId && element.department_id === e.selectedItem.department_id
          )
        ) {
          filteredCrew.push(element)
        }
      })
      setState(prevState => ({
        ...prevState,
        befpicreq: Boolean(e.selectedItem.befpicreq),
        aftpicreq: Boolean(e.selectedItem.aftpicreq),
        riskassessment: Boolean(e.selectedItem.riskassessment),
        department: selectedDeptItem ? selectedDeptItem[0] : { id: '', text: '' },
        team: filteredTeam ? filteredTeam[0] : { id: '', text: '' },
        crew: filteredCrew ? filteredCrew[0] : { id: '', text: '' }
      }))
      setDepartmentItems(selectedDeptItem)
      setTempTeamItems(filteredTeam)
      setAllTempTeamItems(filteredTeam)
      setTempCrewItems(filteredCrew)
      setAllTempCrewItems(filteredCrew)
    }
    if (fieldName === 'department' && e.selectedItem !== null) {
      setState(prevState => ({
        ...prevState,
        team: { id: '', text: '' },
        crew: { id: '', text: '' }
      }))
      setTempCrewItems([])
      setTempTeamItems([])

      const filteredTeam = teamsItems.filter((t: any) => t.department_id === e.selectedItem.id)
      let filteredCrew: any = ''
      if (filteredTeam && filteredTeam.length === 1) {
        filteredCrew = crewItems.filter(
          (c: any) => c.teamId === filteredTeam[0]?.id && c.departmentId === e.selectedItem?.id
        )
      }

      setTempTeamItems(filteredTeam)
      setAllTempTeamItems(filteredTeam)
      setState(prevState => ({
        ...prevState,
        team: filteredTeam && filteredTeam.length === 1 ? filteredTeam[0] : '',
        tempCrewItems: filteredCrew ? filteredCrew : [],
        crew: filteredCrew && filteredCrew.length === 1 ? filteredCrew[0] : ''
      }))
      setTimeout(() => setLoading(false), 1000)
    }
    if (fieldName === 'team' && e.selectedItem !== null) {
      setState(prevState => ({
        ...prevState,
        crew: { id: '', text: '' }
      }))
      setTempCrewItems([])

      const filteredCrew = crewItems.filter(
        (c: any) => c.teamId === e.selectedItem?.id && c.department_id === (state.department ? state.department.id : '')
      )
      setState(prevState => ({
        ...prevState,
        crew: filteredCrew && filteredCrew.length === 1 ? filteredCrew[0] : ''
      }))
      setDisableCrew(false)
      setTempCrewItems(filteredCrew)
      setAllTempCrewItems(filteredCrew)
    }

    setState(prevState => ({
      ...prevState,
      [fieldName]: e.selectedItem
    }))
  }

  const _textAreaChanged = (e: any, fieldName: string) => {
    setState(prevState => ({
      ...prevState,
      [fieldName]: e.value
    }))
  }

  const alert = () => {
    return (
      <AlertDialog
        showDialog={showAlert}
        message={alertMessage}
        title={Config.productTitle}
        hideAlertButton={hideAlertButton}
        onClose={() => _closeDialog()}
      />
    )
  }

  const treeViewOnContentReady = (e: any) => {
    e.component.selectItem(WRTType)
  }

  const onTreeItemClick = () => {
    setIsTreeBoxOpened(false)
  }

  const onTreeBoxOpened = (e: any) => {
    if (e.name === 'opened') {
      setIsTreeBoxOpened(e.value)
    }
  }

  const treeViewItemSelectionChanged = (e: any) => {
    if (e.node.children && e.node.children.length === 0) {
      setState(prevState => ({
        ...prevState,
        WRTType: e.component.getSelectedNodeKeys()
      }))
      setIsTreeBoxOpened(false)
    } else {
      setIsTreeBoxOpened(true)
    }
  }

  const syncTreeViewSelection = (e: any) => {
    setState(prevState => ({
      ...prevState,
      WRTType: e.value
    }))
    if (!treeView) return

    if (!e.value) {
      treeView.instance.unselectAll()
    } else {
      treeView.instance.selectItem(e.value)
    }
  }

  const treeViewRender = () => {
    return (
      <TreeView
        dataSource={[woData]}
        keyExpr='id'
        // ref={ref => {
        //   treeView = ref
        // }}
        dataStructure='plain'
        parentIdExpr='categoryId'
        selectionMode='single'
        selectByClick={true}
        displayExpr='name'
        onContentReady={treeViewOnContentReady}
        onItemClick={onTreeItemClick}
        onItemSelectionChanged={treeViewItemSelectionChanged}
        searchEnabled={true}
        searchMode={'contains'}
        searchExpr={'text'}
        expandNodesRecursive={true}
      />
    )
  }

  const componentConfig = {
    showFiletypeIcon: true,
    postUrl: 'no-url'
  }
  const djsConfig = {
    autoProcessQueue: false,
    addRemoveLinks: true,
    maxFilesize: 5
  }

  const eventHandlers = {
    init: function (dz: any) {
      myDropzone = dz
    },
    addedfile: (file: any) => {
      handleEvent(file)
    },
    removedfile: (file: any) => {
      deleteFile(file)
    }
  }

  return (
    <div>
      {showAlert && alert()}
      {showPanel && renderAssetPanel()}

      <div className='ui-paper'>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <Paper elevation={6} className={'assetPaper'} onClick={() => setShowPanel(true)}>
              <Grid container spacing={1}>
                <Grid item xs={1} sm={1} className={'AssetLableIcon'} onClick={() => setShowPanel(true)}>
                  <IconButton>
                    <EmojiTransportationIcon className={'AssetIcon'} />
                  </IconButton>
                </Grid>
                <Grid item xs={4} sm={4} className={'AssetLable'} onClick={() => setShowPanel(true)}>
                  <span className={'AssetboxTitle'}>Search Asset from List</span>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper elevation={6} className={'assetPaper'} onClick={SearchClicked}>
              <Grid container spacing={1}>
                <Grid item xs={1} className={'AssetLableIcon'}>
                  <IconButton>
                    <Iconly.Location set='light' primaryColor='#1e1e1e' />
                  </IconButton>
                </Grid>
                <Grid item xs={4} sm={4} className={'AssetLable'}>
                  <span className={'AssetboxTitle'}>Search Asset from Map</span>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        <br />
        <Grid container spacing={1}>
          {state.Assets && state.Assets.length > 0 && isPaperVisible && (
            <MuiAccordion style={{ width: '100%' }} className='ui-paper-accordion' defaultExpanded>
              <MuiAccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1a-content' id='panel1a-header'>
                <Typography className={'selectAssets'}>Selected Assets</Typography>
              </MuiAccordionSummary>
              <MuiAccordionDetails>
                <Grid container spacing={1}>
                  {state.Assets.map((asset: any, index: number) => (
                    <Grid item xs={12} sm={12} key={index}>
                      <Paper elevation={12} className={'assetSelectPaper'}>
                        <span className={''}>
                          {asset.id ? asset.id : asset.AssetID}, {asset.AssetName ? asset.AssetName : ''}
                        </span>
                        <span className='floatRight'>
                          <DeleteIcon className={'danger'} onClick={() => removeAssetNumber(index)} />
                        </span>
                      </Paper>
                    </Grid>
                  ))}
                </Grid>
              </MuiAccordionDetails>
            </MuiAccordion>
          )}
        </Grid>
        <br />
      </div>
      <div className='ui-container'>
        <Grid container spacing={3}>
          {showServiceReq && loadingFields && (
            <div>
              <Grid item xs={12} sm={12}>
                <Accordion
                  dataSource={WRdata}
                  collapsible={true}
                  multiple={false}
                  animationDuration={300}
                  itemTitleRender={CustomTitle}
                  itemRender={CustomItem}
                  id='accordion-container'
                />
              </Grid>
            </div>
          )}

          <Grid item xs={12} sm={6} md={4}>
            <InputLabel shrink className='label bold'>
              Work Order Type
            </InputLabel>
            <SelectBoxInput
              stylingMode='outlined'
              displayExpr='text'
              valueExpr='id'
              showClearButton={true}
              items={WRTTypeItems}
              selectedItem={state.WRTType}
              onSelectionChanged={(e: any) => handleDropdownChange(e, 'WRTType')}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <InputLabel shrink className='label bold'>
              Department *
            </InputLabel>
            <SelectBoxInput
              stylingMode='outlined'
              displayExpr='text'
              valueExpr='id'
              showClearButton={true}
              items={departmentItems}
              selectedItem={state.department}
              onSelectionChanged={(e: any) => handleDropdownChange(e, 'department')}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <InputLabel shrink className='label bold'>
              Teams *
            </InputLabel>
            <SelectBoxInput
              stylingMode='outlined'
              displayExpr='text'
              valueExpr='id'
              showClearButton={true}
              items={tempTeamItems}
              selectedItem={state.team}
              onSelectionChanged={(e: any) => handleDropdownChange(e, 'team')}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <InputLabel shrink className='label bold'>
              Crew
            </InputLabel>
            <SelectBoxInput
              stylingMode='outlined'
              displayExpr='text'
              valueExpr='id'
              showClearButton={true}
              items={tempCrewItems}
              selectedItem={state.crewteam ? state.crewteam : ''}
              onSelectionChanged={(e: any) => handleDropdownChange(e, 'crewteam')}
            />
          </Grid>
          {/* <Grid item xs={12} sm={6}>
            <InputLabel shrink className='label bold'>
              Failure
            </InputLabel>
            <SelectBox
              stylingMode='outlined'
              showClearButton={true}
              valueExpr='ID'
              grouped={true}
              displayExpr='Name'
              dataSource={FailureItems}
              selectedItem={state.Failure}
              disabled={state.Assets ? false : true}
              onSelectionChanged={(e: any) => handleDropdownChange(e, 'Failure')}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputLabel shrink className='label bold'>
              Cause
            </InputLabel>
            <SelectBox
              stylingMode='outlined'
              showClearButton={true}
              valueExpr='ID'
              grouped={true}
              displayExpr='Name'
              dataSource={CauseItems}
              selectedItem={state.Cause}
              defaultValue={state.Cause ? state.Cause : ''}
              disabled={state.Assets ? false : true}
              onSelectionChanged={(e: any) => handleDropdownChange(e, 'Cause')}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputLabel shrink className='label bold'>
              Remedy
            </InputLabel>
            <SelectBoxInput
              stylingMode='outlined'
              displayExpr='text'
              valueExpr='id'
              showClearButton={true}
              items={RemedyItems}
              selectedItem={state.Remedy}
              disabled={state.Assets ? false : true}
              onSelectionChanged={(e: any) => handleDropdownChange(e, 'Remedy')}
            />
          </Grid> */}
          <Grid item xs={12} sm={6} md={4}>
          <InputLabel shrink className='label bold'>
              Priority
            </InputLabel>
              <SelectBoxInput
                stylingMode="outlined"
                displayExpr="text"
                valueExpr="id"
                showClearButton={true}
                items={priorityItems}
                selectedItem={state.priority ? state.priority : ""}
                onSelectionChanged={(e: any) =>
                  handleDropdownChange(e, "priority")
                }
              />
            </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <InputLabel shrink className='label bold'>
              Location *
            </InputLabel>
            <TextBoxInput
              stylingMode='outlined'
              value={state.location ? state.location : state.AssetLocation}
              onValueChanged={(e: any) => handleValueChange(e, 'location')}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <InputLabel shrink className='label bold'>
              Scheduled Start
            </InputLabel>
            <DateBoxInput
              stylingMode='outlined'
              value={state.Eststartdate ? new Date(state.Eststartdate) : null}
              type='datetime'
              displayFormat={'dd/MM/yy hh:mm a'}
              showClearButton={true}
              onValueChanged={(e: any) => handleValueChange(e, 'Eststartdate')}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <InputLabel shrink className='label bold'>
              Scheduled Finish
            </InputLabel>
            <DateBoxInput
              stylingMode='outlined'
              value={state.Estenddate ? new Date(state.Estenddate) : null}
              type='datetime'
              displayFormat={'dd/MM/yy hh:mm a'}
              showClearButton={true}
              onValueChanged={(e: any) => handleValueChange(e, 'Estenddate')}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} className='Multilinetext'>
            <InputLabel shrink className='label bold'>
              Description *
            </InputLabel>
            <TextArea
              stylingMode='outlined'
              value={state.description}
              onValueChanged={(e: any) => _textAreaChanged(e, 'description')}
              maxLength={250}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <InputLabel shrink className='label bold'>
              Comments
            </InputLabel>
            <TextAreaInput value={state.comment} onValueChanged={(e: any) => _textAreaChanged(e, 'comment')} />
          </Grid>

          <Grid item xs={12} sm={6} md={2}>
            <CheckBoxInput
              value={state.befpicreq}
              onValueChanged={(e: any) => handleValueChange(e, 'befpicreq')}
              text={'Before Photo Required'}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <CheckBoxInput
              value={state.aftpicreq}
              onValueChanged={(e: any) => handleValueChange(e, 'aftpicreq')}
              text={'After Photo Required'}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <CheckBoxInput
              value={state.riskassessment}
              onValueChanged={(e: any) => handleValueChange(e, 'riskassessment')}
              text={'Site Risk Assessment'}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Label>Upload attachment related to work order</Label>
            <DropzoneComponent
              eventHandlers={eventHandlers}
              djsConfig={djsConfig}
              config={componentConfig}
              className='dz-message icon ion-upload'
              action='Drop files here or click to upload.'
            />
            <span>Max no. of file: 5. Max file size: 5Mb</span>
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <div className='action-buttons'>
              <Button onClick={clearFilter} variant='outlined' className={'clearButton'}>
                Clear
              </Button>
              <Button onClick={createWorkOrder} variant='outlined' color='primary' className='primary-button'>
                Create
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
      {renderAssetMap()}
      <br />
    </div>
  )
}

export default CreateWO
