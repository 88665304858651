import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

interface AssetContextType {
  assetDetailItems: any | null;
  setAssetDetailItems: React.Dispatch<React.SetStateAction<any | null>>;
  fromAssetDetail: boolean;
  setFromAssetDetail: React.Dispatch<React.SetStateAction<boolean>>;
  previousPage: string | null;
  setPreviousPage: React.Dispatch<React.SetStateAction<string | null>>;
}

const AssetContext = createContext<AssetContextType | null>(null);

interface Props {
  children: ReactNode;
}

export const AssetProvider: React.FC<Props> = ({ children }) => {
  const [assetDetailItems, setAssetDetailItems] = useState<any | null>(null);
  const [fromAssetDetail, setFromAssetDetail] = useState(false);
  const [previousPage, setPreviousPage] = useState<string | null>(null);
  const location = useLocation();
  useEffect(() => {
    if (location.pathname === '/AssetDetail') {
      setPreviousPage('/AssetDetail');
    } else {
      setPreviousPage(null); // Clear if not coming from AssetDetail
    }
  }, [location.pathname]);

  return (
    <AssetContext.Provider value={{ assetDetailItems, setAssetDetailItems, fromAssetDetail, setFromAssetDetail, previousPage, setPreviousPage }}>
      {children}
    </AssetContext.Provider>
  );
};

export const useAssetContext = () => {
  const context = useContext(AssetContext);
  if (!context) {
    throw new Error("useAssetContext must be used within an AssetProvider");
  }
  return context;
};
