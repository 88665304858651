import React, { useState, useEffect } from 'react'
import utility from '../../../classes/utility'
import { Config } from '../../../classes/config'
import DataTable from '../../Common/CustomASDataGrid'
import CustomStore from 'devextreme/data/custom_store'
import { LoadOptions } from 'devextreme/data'
import ColorBox from 'devextreme-react/color-box'
import DateBox from 'devextreme-react/date-box'
import Spinner from '../../Common/ReusableComponents/Spinner'
import { set } from 'react-hook-form'

interface Dept {
  department_id: number
  department_code: string
  department_name: string
}

interface Teams {
  team_id: number
  team_code: string
  team_name: string
  department_id: number
  department_name: string
}

interface Crews {
  crew_id: number
  crew_code: string
  team_id: number
  team_name: string
  department_id: number
  department_name: string
}

interface User {
  first_name: string
  last_name: string
  full_name: string
  resource_code: string
  login_name: string
  email: string
  phone: string
  pin: string
  is_active: boolean
  is_contractor: boolean
  // userType: string;
  job_title: string
  absent_info: string
  photo: string
  erp_code: string
  department_id: number | null
  department_name: string
  department_code: string
  team_id: number | null
  team_name: string
  team_code: string
  crew_id: number | null
  crew_name: string
  crew_code: string
  role_id: number
  role_name: string
  // userRoleId: number;
  is_plant_operator: boolean
  reserved_by: string
  // reservedByFullName: string;
  reserved_on: Date
  id: string
  created_by: string
  // updated_by: string;
  created: Date
  // updated: Date;
  // tenantId: number;
  leading_hand_id: number | null
  leading_hand_name: string
  team: Teams
}

interface LoadResult {
  data: any[]
  totalCount: number
}

const Crews: React.FC<any> = (props: any) => {
  const [depts, setDepts] = useState<Dept[]>([])
  const [teams, setTeams] = useState<Teams[]>([])
  const [crews, setCrews] = useState<Crews[]>([])
  const [leadingHands, setLeadingHands] = useState<User[]>([])
  const filterValue = [['is_active', '=', true]]
  const [items, setItems] = useState<any[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [dataLoaded, setDataLoaded] = useState(false)

  const setTeamValue = (rowData: User, value: number) => {
    rowData.team_id = value || null
    const team = teams.find(t => t.team_id === value)
    if (team) {
      rowData.team_name = team.team_name
      rowData.department_id = team.department_id
      rowData.department_name = depts.find(d => d.department_id === team.department_id)?.department_name || ''
    }
  }

  const setLeadingHandValue = (rowData: User, value: number) => {
    rowData.leading_hand_id = value || null

    const leadingHand = leadingHands.find(lh => lh.leading_hand_id === value)
    if (leadingHand) {
      rowData.leading_hand_name = leadingHand.leading_hand_name
    }
  }

  const timeStringToDate = (timeString: string): Date | null => {
    // Check if the timeString is valid
    if (!timeString || !/^\d{2}:\d{2}:\d{2}$/.test(timeString)) {
      console.error('Invalid time string:', timeString)
      return null
    }
    
    const [hours, minutes, seconds] = timeString.split(':').map(Number)
    
    // Check if the extracted hours, minutes, and seconds are valid numbers
    if (isNaN(hours) || isNaN(minutes) || isNaN(seconds)) {
      console.error('Invalid time components:', { hours, minutes, seconds })
      return null
    }
  
    const date = new Date()
    date.setHours(hours, minutes, seconds, 0)
    return date
  }

  const extractTime = (date: Date): string => {
    if (!date) return ''
    const hours = String(date.getHours()).padStart(2, '0')
    const minutes = String(date.getMinutes()).padStart(2, '0')
    const seconds = String(date.getSeconds()).padStart(2, '0')
    return `${hours}:${minutes}:${seconds}`
  }

  const setDepartmentValue = (rowData: any, value: number) => {
    rowData.department_id = value || null;
    const dept = depts.find(d => d.department_id === value);
    if (dept) {
      rowData.department_name = dept.department_name;
    }
  };

  const getFilteredTeams = (options: { data?: User }) => ({
    store: teams,
    filter: options.data ? ['department_id', '=', options.data.department_id] : null
  })

  const columns = [
    {
      field: 'id',
      title: 'ID',
      width: 80,
      type: 'string',
      allowHiding: false,
      allowEditing: false,
      visibleColumn: false,
      formItem: {
        visible: false
      }
    },
    {
      field: 'title',
      title: 'Title',
      width: 160,
      type: 'string',
      isRequired: true,
      allowHiding: false
    },
    {
      field: 'department_id',
      title: 'Department',
      type: 'string',
      allowHiding: true,
      isRequired: true,
      setCellValue: setDepartmentValue,
      Lookup: {
        dataSource: depts,
        valueExpr: 'department_id',
        displayExpr: 'department_name'
      }
    },
    {
      field: 'team_id',
      title: 'Team',
      // width: 280,
      type: 'string',
      isRequired: true,
      setCellValue: setTeamValue,
      Lookup: {
        dataSource: getFilteredTeams,
        valueExpr: 'team_id',
        displayExpr: 'team_name'
      }
    },
    {
      field: 'leading_hand_id',
      title: 'Leading Hand',
      // width: 160,
      type: 'string',
      allowHiding: true,
      setCellValue: setLeadingHandValue,
      Lookup: {
        dataSource: leadingHands,
        valueExpr: 'leading_hand_id',
        displayExpr: 'leading_hand_name'
      }
    },
    {
      field: 'work_start_time',
      title: 'Start Time',
      // width: 160,
      type: 'time',
      allowHiding: true,
      formItem: {
        visible: true,
        editorType: 'dxDateBox',
        editorOptions: {
          render: (data: any) => {
            return (
              <DateBox
                defaultValue={data.value}
                type='time'
                displayFormat='HH:mm'
                showClearButton={true}
                inputAttr={'clear'}
              />
            )
          }
        }
      }
    },
    {
      field: 'work_finish_time',
      title: 'Finish Time',
      type: 'time',
      allowHiding: true,
      formItem: {
        visible: true,
        editorType: 'dxDateBox',
        editorOptions: {
          render: (data: any) => {
            return (
              <DateBox
                value={data.value}
                type='time'
                displayFormat='HH:mm'
                showClearButton={true}
                inputAttr={'clear'}
              />
            )
          }
        }
      }
    },
    {
      field: 'code',
      title: 'Code',
      // width: 160,
      type: 'string',
      isRequired: true,
      allowHiding: true
    },
    {
      field: 'map_colour',
      title: 'Color',
      // width: 160,
      type: 'string',
      allowHiding: true,
      isRequired: true,
      cellRender: (data: any) => (
        <div
          style={{
            width: '20px',
            height: '20px',
            backgroundColor: data.value,
            border: '1px solid #ccc'
          }}
        />
      ),
      formItem: {
        visible: true,
        editorType: 'dxColorBox',
        editorOptions: {
          render: (data: any) => {
            const handleColorChanged = (cellData: any) => {
              data.setValue(cellData.value)
            }
            return (
              <ColorBox
                value={data.value}
                applyValueMode='instantly'
                stylingMode='outlined'
                showClearButton={true}
                label='Color'
                onValueChanged={handleColorChanged}
              />
            )
          }
        }
      }
    },
    {
      field: 'prestart_required',
      title: 'Prestart Required',
      width: 120,
      type: 'boolean',
      allowHiding: true,
      calculateCellValue: (data: any) => data.prestart_required ?? false, // Default to true
      setCellValue: (newData: any, value: any) => {
        newData.prestart_required = value
      }
    },
    {
      field: 'is_active',
      title: 'Active',
      // width: 160,
      type: 'boolean',
      calculateCellValue: (data: any) => data.is_active ?? true, // Default to true
      setCellValue: (newData: any, value: any) => {
        newData.is_active = value
      }
    },
    {
      field: 'created',
      title: 'Created',
      width: 160,
      type: 'datetime',
      visibleColumn: false,
      allowHiding: true,
      formItem: {
        visible: false
      }
    },
    {
      field: 'created_by',
      title: 'Created By',
      width: 160,
      type: 'string',
      visibleColumn: false,
      allowHiding: true,
      formItem: {
        visible: false
      }
    }
  ]

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    try {
      setLoading(true);
      const deptResponse = await utility.genericGetAPICall(`${Config.DepartmentList}`);
      const deptData = deptResponse
        .filter((r: any) => r.is_active === true)
        .map((r: any) => ({
          department_id: r.id,
          department_name: r.title
        }));
      setDepts(deptData);

      const teamResponse = await utility.genericGetAPICall(`${Config.TeamList}`)
      const teamData = teamResponse.map ((r: any) => ({
         ...r,
            team_id: r.id,
            team_code: r.code,
            team_name: r.title,
            department_id: r.department.id,
            department_name: r.department.title
          }))
      setTeams(teamData)

      const userResponse = await utility.genericGetAPICall(Config.SearchEmployeeApi)
      const userData = Array.isArray(userResponse.data) ? userResponse.data : userResponse

      const activeItems = userData
        .filter((r: any) => r.is_active === true)
        .map((r: any) => ({
          leading_hand_id: r.id,
          leading_hand_name: r.full_name,
          crew_id: r.crew_id,
          crew_name: r.crew_name
        }))
      // const sortedItems = utility._sortItems(activeItems, 'text', false)
     setLeadingHands(activeItems)

     await refreshData();
      
      setDataLoaded(true)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.error(error)
      throw error
    }
  }

  const refreshData: any = async () => {
    setLoading(true);
    try {
    const crewResponse = await utility.genericGetAPICall(Config.crewlistname)
    const crewData = crewResponse.map((r: any) => ({
        ...r,
        crew_id: r.id,
        crew_code: r.code,
        team_id: r.team?.id,
        team_name: r.team?.title,
        department_id: r.team?.department_id,
        department_name: r.team?.department?.title,
        work_start_time: r.work_start_time ? timeStringToDate(r.work_start_time) : null,
        work_finish_time: r.work_finish_time ? timeStringToDate(r.work_finish_time) : null,
      }))
    setCrews(crewData)
    setLoading(false);
  } catch (error) {
    console.error(error);
    setLoading(false);
  }
};

  const dataSource = new CustomStore({
    load: async (loadOptions: LoadOptions): Promise<LoadResult> => {
        return {
          data: crews,
          totalCount: crews.length
        }
    },
    key: 'id',
    onLoaded: () => {
      console.log('Data loaded')
      setLoading(false);
      setDataLoaded(true);
    },
    insert: async (values: any) => {
      try {
        if (values.work_start_time) {
          values.work_start_time = extractTime(new Date(values.work_start_time))
        }
        if (values.work_finish_time) {
          values.work_finish_time = extractTime(new Date(values.work_finish_time))
        }
        await utility.genericPostAPICall(Config.crewlistname, values)
        await refreshData();
      } catch (error) {
        console.error(error)
        throw error
      }
    },
    update: async (key: any, values: any) => {
      try {
        if (values.work_start_time) {
          values.work_start_time = extractTime(new Date(values.work_start_time))
        }
        if (values.work_finish_time) {
          values.work_finish_time = extractTime(new Date(values.work_finish_time))
        }
        await utility.genericUpdatePatchAPICall(Config.crewlistname, key, values)
        await refreshData();
      } catch (error) {
        console.error(error)
        throw error
      }
    },
    remove: async (key: any) => {
      try {
        await utility.genericDeleteAPICall(Config.crewlistname, key)
        await refreshData();
      } catch (error) {
        console.error(error)
        throw error
      }
    }
  })

  return (
    <>
      <div>
        {!loading ? (
        <div className='results-container'>
          <DataTable
            dataSource={dataSource}
            style={{ width: '100%' }}
            filteralbe={true}
            groupable={true}
            columns={columns}
            fileName={'Crews'}
            columnChooser={'crewsColumns'}
            selectionMode={false}
            showHideColumnMenu={true}
            loading={loading}
            allowExportSelectedData={false}
            columnHidingEnabled={true}
            showCommandBtn={false}
            hideSearchPanel={false}
            hideCommandDeleteBtn={false}
            showNewBtn={false}
            showViewDetailBtn={false}
            hideNewBtn={true}
            defaultfiltervalue={filterValue}
            showMoreBtn={true}
            visible={dataLoaded}
          />
        </div>
        ) : (
                    <Spinner size='large' className='loader' />
                )}
      </div>
    </>
  )
}

export default Crews
