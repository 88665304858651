import React, { useEffect, useRef, useState } from 'react'
import utility from '../../../../classes/utility'
import { Config } from '../../../../classes/config'
import ScrollView from 'devextreme-react/scroll-view'
import Grid from '@mui/material/Grid'
import AlertDialog from '../../../Common/AlertDialog'
import CheckBoxInput from '../../../Common/ReusableComponents/CheckBox'
import TextBoxInput from '../../../Common/ReusableComponents/TextBox'
import Spinner from '../../../Common/ReusableComponents/Spinner'
import SelectBoxInput from '../../../Common/ReusableComponents/SelectInputBox'
import DateBoxInput from '../../../Common/ReusableComponents/DateBox'
import TextAreaInput from '../../../Common/ReusableComponents/TextArea'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import AssetMap from '../../Assets/AssetMap'
import AssetSearch from '../../Assets/Assets'

import DataSource from 'devextreme/data/data_source'
import { SelectBox } from 'devextreme-react/select-box'
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace'
import global from '../../../../classes/global'
import Paper from '@mui/material/Paper'
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation'
import MapIcon from '@mui/icons-material/Map'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import InputLabel from '@mui/material/InputLabel'

interface ComponentProps {
  workorder: any
  currentUser: any
  statusId: string
  statustext: string
  priorityId: string
  priority?: string
  StatusItems: any[]
  priorityItems: any[]
  showModal: boolean
  onClose: () => void
  onClick: () => void
  selectedcrew?: any
  callAfterInit?: any
  context?: any
  aadHttpClient?: any
  crewItems: any[]
  AssetDetails: any
  myhttpclient?: any
  status?: any
  workorder_status?: any
}

interface WOProps {
  id?: any
  text?: any
}

interface StateProps {
  id?: any
  departmentId?: any
  teamId?: any
  text?: any
}

interface ComponentState {
  showPanel: boolean
  WOcrew: any
  status: any
  WOpriority: any
  location: string
  Eststartdate: Date | null
  Estenddate: Date | null
  befpicreq: boolean
  aftpicreq: boolean
  riskassessment: boolean
  WOComments: string
  comments: string
  updating: boolean
  showAlert: boolean
  alertMessage: any
  itemID: any
  historyTitle: string
  team: any
  department: any
  StatusItems: any
  priorityItems: any
  crewItems: any
  workorder: any
  loading: any
  Failure: any
  Cause: any
  Remedy: any
  Assets: any
  tempCrewItems: any
  teamsItems: any
  disableCrew: boolean
  tempTeamItems: any
  allCrewItems: any
  description: any
  WRTType: any
  WRTTypeItems: any
  assetType: any
  assetGeometry: any
  al: any
  selectParent: boolean
  selectModeMap: boolean
}

const EditWorkOrder: React.FC<ComponentProps> = props => {
  const [showAlert, setShowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState<string | ReactJSXElement>('')
  const [hideAlertButton, setHideAlertButton] = useState(false)

  //const [loading, setLoading] = useState(true)
  //const [workorder, setWorkorder] = useState(props.workorder)
  //const [status, setStatus] = useState<WOProps>({})
  //const [WOpriority, setWOpriority] = useState<WOProps>({})
  //const [location, setLocation] = useState('')
  //const [Eststartdate, setEststartdate] = useState<Date | null>(null)
  //const [Estenddate, setEstenddate] = useState<Date | null>(null)
  //const [befpicreq, setBefpicreq] = useState(false)
  //const [aftpicreq, setAftpicreq] = useState(false)
  //const [riskassessment, setRiskassessment] = useState(false)
  const [WOComments, setWOComments] = useState('')
  const [comments, setComments] = useState('')
  const [updating, setUpdating] = useState(false)
  const [itemID, setItemID] = useState('')
  const [workTypeLoading, setWorkTypeLoading] = useState(false)
  const [WRTType, setWRTType] = useState<WOProps>({})
  const [allWRTTypeItems, setAllWRTTypeItems] = useState<any[]>([])
  const assetTypeRef = useRef('');

  //const [historyTitle, setHistoryTitle] = useState('')
  //const [department, setDepartment] = useState<WOProps>({})
  const [departmentItems, setDepartmentItems] = useState<any[]>([])
  const [alldepartmentItems, setAllDepartmentItems] = useState<any[]>([])
  const [tempDepartmentItems, setTempDepartmentItems] = useState<any[]>([])
  // const [WRTTypeItems, setWRTTypeItems] = useState<any[]>([])
  const [IframePanelOpened, setIframePanelOpened] = useState(false)

  //const [teamItems, setTeamItems] = useState<any[]>([])
  //const [tempTeamItems, setTempTeamItems] = useState<any[]>([])
  //const [team, setTeam] = useState<WOProps>({})
  //const [tempCrewItems, setTempCrewItems] = useState<any[]>([])
  //const [allCrewItems, setAllCrewItems] = useState<any[]>([])
  //const [WOCrew, setWOCrew] = useState<WOProps>({})
  const [failureItems, setFailureItems] = useState<any[]>([])
  const [causeItems, setCauseItems] = useState<any[]>([])
  const [remedyItems, setRemedyItems] = useState<any[]>([])

  //const [description, setDescription] = useState('')
  const dialogRef = useRef(null)
  const [state, setState] = useState<ComponentState>({
    showPanel: false,
    workorder: props.workorder,
    WOcrew: '',
    status: props.status,
    WOpriority: '',
    location: '',
    Eststartdate: null,
    Estenddate: null,
    befpicreq: false,
    aftpicreq: false,
    riskassessment: false,
    WOComments: '',
    comments: '',
    updating: false,
    showAlert: false,
    alertMessage: '',
    itemID: '',
    historyTitle: '',
    team: '',
    department: {},
    StatusItems: props.StatusItems,
    priorityItems: props.priorityItems,
    crewItems: props.crewItems,
    loading: false,
    Failure: '',
    Cause: '',
    Remedy: '',
    Assets: [],
    tempCrewItems: [],
    teamsItems: [],
    disableCrew: false,
    tempTeamItems: [],
    allCrewItems: [],
    description: '',
    WRTType: {},
    WRTTypeItems: [],
    assetType: '',
    assetGeometry: [],
    al: '',
    selectParent: false,
    selectModeMap: false
  })

  useEffect(() => {
    didMount()
  }, [])

  useEffect(() => {
    setState(prevState => ({
      ...prevState,
      crewItems: props.crewItems
    }))
  }, [props.crewItems])

  useEffect(() => {
    assetTypeRef.current = state.assetType;
  }, [state.assetType]);

  const didMount = () => {
    setState(prevState => ({
      ...prevState,
      loading: true
    }))
    _getworkTypeDataAsync()
    _getTeamDataAsync()
    _getDepartmentData()
    _getCrewData()

    // let updatedWorkorder = props.workorder
    // const cI = props.crewItems
    // if (!Array.isArray(updatedWorkorder)) {
    //   updatedWorkorder = [updatedWorkorder]
    // }
    //updatedWorkorder.forEach((item: any) => {
      const statustextValue = props.workorder?.workorder_status_id ? { id: props.workorder?.workorder_status?.id, text: props.workorder?.workorder_status?.title } : ''
      const prioritytextValue = props.priorityId ? { id: props.priorityId, text: props.priority } : ''
      let JSONAssetData
      let maintenanceType: any
      // if (item.assets_info !== null) {
      //   JSONAssetData = JSON.parse(item.assets_info)
      //   if (JSONAssetData) {
      //     _getFCRData(JSONAssetData.MaintenanceType)
      //     maintenanceType = JSONAssetData.MaintenanceType
      //   }
      // }

      let Assets: any[] = []
      let assetGeometryArray: any[] = []
      if (props.AssetDetails && props.AssetDetails.length > 0) {
        props.AssetDetails.forEach((element: any) => {
          // _getFCRData(
          //   props.AssetDetails && props.AssetDetails[0].maintenanceType
          // );
          Assets.push({
            AssetName: element.AssetTitle,
            AssetNumber: element.AssetNumber,
            AssetGeom: element.geometry,
            AssetID: element.AssetID,
            erpAssetId: element.erpAssetId,
            AssetType: element.AssetType
          })
          if(element.geometry && element.geometry !== '') {
            let assetGeometry = JSON.parse(element.geometry)
            let type = ''
            let polygoncoordinates: any[] = []

            if (assetGeometry?.geometries && assetGeometry?.geometries.length > 0) {
              assetGeometry.geometries.forEach((element: any) => {
                if (element.type === 'MultiPolygon') {
                  type = element.type
                  if (element.coordinates && element.coordinates[0].length > 0) {
                    element.coordinates[0][0].forEach((coordinate: any) => {
                      polygoncoordinates.push({
                        lat: coordinate[1],
                        lng: coordinate[0]
                      })
                    })
                  }
                } else if (element.type === 'Point') {
                  type = element.type
                  polygoncoordinates.push(element.coordinates[0], element.coordinates[1])
                }
              })
              assetGeometryArray.push({
                type: type,
                coordinates: polygoncoordinates
              })
              } else if (assetGeometry?.type === 'Point') {
                assetGeometryArray.push(assetGeometry)
            }
          }
         })
      }

      setState(prevState => ({
        ...prevState,
        location: props.workorder?.workorder_location,
        status: statustextValue ? statustextValue : {},
        WOpriority: prioritytextValue ? prioritytextValue : '',
        WOComments: props.workorder?.comments ? props.workorder?.comments : '',
        aftpicreq: props.workorder?.photo_required_after ? true : false,
        befpicreq: props.workorder?.photo_required_before ? true : false,
        riskassessment: props.workorder?.risk_assessment_required ? true : false,
        Eststartdate: props.workorder?.date_scheduled_start ? new Date(props.workorder?.date_scheduled_start) : null,
        Estenddate: props.workorder?.date_scheduled_end ? new Date(props.workorder?.date_scheduled_end) : null,
        itemID: props.workorder?.id,
        historyTitle: props.workorder?.id,
        comments: '',
        loading: false,
        description: props.workorder?.description,
        Failure: props.workorder?.failure_code_id,
        Cause: props.workorder?.cause_code_id,
        Remedy: props.workorder?.remedy_code_id,
        Assets:Assets,
        assetType: Assets ? Assets[0]?.AssetType : "",
        StatusItems: props.StatusItems,
        priorityItems: props.priorityItems,
        crewItems: props.crewItems,
        workorder: props.workorder
      }))
    //})
  }

  const selectAssets = async (selectedAssetArray: any) => {
    if (!Array.isArray(selectedAssetArray)) {
      selectedAssetArray = [selectedAssetArray]
    }
    if (selectedAssetArray.length > 1) {
      // Extract asset types and check if they are all the same
      const assetTypes = selectedAssetArray.map((asset: any) => asset.AssetType)
      const allSameType = assetTypes.every((type: string, _: any, array: string[]) => type === array[0])
      if (!allSameType) {
        setAlertMessage(
          'The selected assets do not all have the same asset type. Please select assets of the same type.'
        )
        setShowAlert(true)
        return
      }
    }

    const selectedAssets: any = state.Assets
    let isFirstAsset: boolean = state.Assets && state.Assets.length === 0
    const initialAssetType = state.assetType
    let duplicateAssets: any[] = []

    for (let i = 0; i < selectedAssetArray.length; i++) {
      let Asset = selectedAssetArray[i]
      // check for duplicates

      if (isFirstAsset) {
        const chosenAssetType = Asset.AssetType
        const chosenAssetypeID = Asset.AssetID
        try {
          await _getworkTypeDataAsync(Asset.workGroupIds, Asset.accntSystemId, Asset.activityId, Asset.taskId)
        } catch (error) {
          setAlertMessage(
            'Work Group is not available for this Asset ' + chosenAssetypeID + '. Please choose different Asset.'
          )
          setShowAlert(true)
          return
        }

        //_getFCRData(Asset.maintenanceAssetType)
        selectedAssets.push(Asset)
        setState(prevState => ({
          ...prevState,
          defaultAsset: Asset,
          Assets: selectedAssets,
          assetType: chosenAssetType,
          Address: Asset.Location,
          location: Asset.Location,
          latLong: Asset.coordinates,
          mode: 'SearchAssetList',
          showPanel: false
        }))
        assetTypeRef.current = chosenAssetType;
        isFirstAsset = false
      } else {
        const isDuplicateAsset = selectedAssets.some(
          (existingAsset: any) => existingAsset.AssetID === Asset.AssetID || existingAsset.AssetID == Asset.AssetID
        )
        if (isDuplicateAsset) {
          duplicateAssets.push(Asset.erpAssetId)
          setAlertMessage('Asset ID ' + duplicateAssets + ' is already added. Please select a different Asset')
          setShowAlert(true)
          continue
        }

        const assetType = assetTypeRef.current; // Get the stored assetType
        if (Asset.AssetType === assetType) {
          // If assetType matches, add the asset
          selectedAssets.push(Asset)
          setState(prevState => ({
            ...prevState,
            Assets: selectedAssets,
            mode: 'SearchAssetList',
            showPanel: false
          }))
        } else {
          setAlertMessage(
            'The selected asset does not match the asset type of the initially selected asset(' +
              assetType +
              '). You can only select assets of the same type. Please choose a different asset.'
          )
          setShowAlert(true)
          continue // skip to next Asset
        }
      }
    }
    setState(prevState => ({ ...prevState, showPanel: false }))
  }

  const _getworkTypeDataAsync = async (workGroupIds?: any, projectIds?: any, activitys?: any, taskIds?: any) => {
    setWorkTypeLoading(true)
    try {
      const data: any = await utility.genericGetAPICall(Config.WTlistname)
      let filtereddata
      let newValues: any = []
      let selectedValues: any = ''
      const assetDetails = props.AssetDetails
      const workGroupId =
        workGroupIds !== undefined
          ? workGroupIds
          : assetDetails && assetDetails.length > 0
          ? assetDetails[0].workGroupIds
          : ''
      const projectId =
        projectIds !== undefined
          ? projectIds
          : assetDetails && assetDetails.length > 0
          ? assetDetails[0].accntSystemId
          : ''
      const taskId =
        taskIds !== undefined ? taskIds : assetDetails && assetDetails.length > 0 ? assetDetails[0].taskId : ''
      const activity =
        activitys !== undefined ? activitys : assetDetails && assetDetails.length > 0 ? assetDetails[0].activityId : ''
      let isActivity: boolean = false
      filtereddata = workGroupId
        ? data.filter((f: any) => {
            const hasWorkGroupIds = f.workGroupIds && f.workGroupIds.length > 0
            const hasMatchingWorkGroup =
              hasWorkGroupIds && f.workGroupIds.some((value: any) => workGroupId.includes(value))
            const isMatchingCategory = f.category === global.filterWOType
            const hasMatchingProject = projectId ? f.projectId === projectId : true
            const hasMatchingActivity = activity ? f.activity === activity : true
            const hasMatchingTask = taskId ? f.taskId === taskId : true
            if (workGroupId) {
              isActivity = hasMatchingWorkGroup && isMatchingCategory
              if (projectId !== '' || projectId !== null) {
                isActivity = isActivity && hasMatchingProject
              }
              if (activity !== '' || projectId !== null) {
                isActivity = isActivity && hasMatchingActivity
              }
              if (taskId !== '' || projectId !== null) {
                isActivity = isActivity && hasMatchingTask
              }
              return isActivity
            } else {
              return isMatchingCategory
            }
          })
        : data.filter((f: any) => f.category === global.filterWOType)
      const WRTTypeItems = filtereddata.map((item: any) => ({
        id: item.id,
        text: item.title,
        categoryId: item.workRequestTypeId,
        category: item.category,
        befpicreq: item.photo_required_before ? true : false,
        aftpicreq: item.photo_required_after ? true : false,
        riskassessment: item.risk_assessment_required ? true : false,
        departmentId: item.department_id,
        teamId: item.team_ids,
        workGroupId: item.work_group_ids,
        workRequestTypeId: item.workRequestTypeId,
        disabled: item.is_active ? false : true,
        gisLayers: item.gis_layers
      }))
      const sortedItems = utility._sortItems(WRTTypeItems, 'text', false)
      if (sortedItems && sortedItems.length === 1) {
        const selectedeprt =
          alldepartmentItems && alldepartmentItems.filter((d: any) => d.id === sortedItems[0].departmentId)
        const selectedteam = state.tempTeamItems?.filter((d: any) => sortedItems[0].teamId.includes(d.id))
        const teamArray = selectedteam?.map((item: any) => item.id)
        const selectedcrew = props.crewItems.filter((d: any) => teamArray?.includes(d.teamId))
        const selectedWOType = sortedItems && sortedItems.filter((d: any) => d.id === props.workorder.workorderTypeId)
        let layers = sortedItems[0].gisLayers
        let layersToPass = ''
        if (layers) {
          layersToPass = layers.layers.join()
        }
        if (selectedWOType.length <= 0) {
          const wotype: any =
            data &&
            data
              .filter((d: any) => d.id === props.workorder.workorderTypeId)
              .map((item: any) => ({
                id: item.id,
                text: item.title,
                categoryId: item.workRequestTypeId,
                category: item?.category,
                befpicreq: item.photoRequiredBefore ? true : false,
                aftpicreq: item.photoRequiredAfter ? true : false,
                riskassessment: item.riskAssessmentRequired ? true : false,
                departmentId: item.departmentId,
                teamId: item.teamIds,
                workGroupId: item.workGroupIds,
                workRequestTypeId: item.workRequestTypeId,
                disabled: item.isActive ? false : true,
                gisLayers: item.gisLayers
              }))
          newValues = [...wotype, sortedItems[0]]
          selectedValues = newValues && newValues.filter((d: any) => d.id === props.workorder.workorderTypeId)
        }

        setState(prevState => ({
          ...prevState,
          WRTType: selectedWOType.length > 0 ? selectedWOType[0] : selectedValues.length > 0 ? selectedValues[0] : '',
          WRTTypeItems: selectedWOType.length <= 0 ? newValues : sortedItems,
          allWRTTypeItems: selectedWOType.length <= 0 ? newValues : sortedItems,
          departmentItems: selectedeprt,
          department: selectedeprt ? selectedeprt[0] : '',
          teamsItems: selectedteam,
          team: selectedteam && selectedteam.length === 1 ? selectedteam[0] : '',
          tempCrewItems: selectedcrew,
          al: layersToPass
        }))
        setWorkTypeLoading(false)
      } else if (props.workorder.workorder_type_id) {
        const selectedWOType = sortedItems && sortedItems.filter((d: any) => d.id === props.workorder.workorder_type_id)
        if (selectedWOType.length <= 0) {
          const wotype: any =
            data &&
            data
              .filter((d: any) => d.id === props.workorder.workorderTypeId)
              .map((item: any) => ({
                id: item.id,
                text: item.title,
                categoryId: item.workRequestTypeId,
                category: item.category,
                befpicreq: item.photoRequiredBefore ? true : false,
                aftpicreq: item.photoRequiredAfter ? true : false,
                riskassessment: item.riskAssessmentRequired ? true : false,
                departmentId: item.departmentId,
                teamId: item.teamIds,
                workGroupId: item.workGroupIds,
                workRequestTypeId: item.workRequestTypeId,
                disabled: true
              }))
          newValues = [...wotype, sortedItems[0]]
          selectedValues = newValues && newValues.filter((d: any) => d?.id === props.workorder.workorderTypeId)
        }
        setState(prevState => ({
          ...prevState,
          WRTType: selectedWOType.length > 0 ? selectedWOType[0] : selectedValues.length > 0 ? selectedValues[0] : '',
          WRTTypeItems: selectedWOType.length <= 0 ? newValues : sortedItems,
          allWRTTypeItems: selectedWOType.length <= 0 ? newValues : sortedItems
        }))
        setWorkTypeLoading(false)
      } else if (sortedItems.length <= 0) {
        setWorkTypeLoading(false)
        throw new Error('Work Group is not available for this Asset. Please choose a different Asset.')
      }
    } catch (err) {
      console.log(err)
      setWorkTypeLoading(false)
      throw err
    }
  }

  const _onDismissMap = () => {
    setIframePanelOpened(false)
  }

  const renderAssetMap = () => {
    return (
      <AssetMap
        //lat={state.assetGeometry && state.assetGeometry[0].coordinates ? state.assetGeometry[0].coordinates[1] : 0 }
        //long={state.assetGeometry && state.assetGeometry[0].coordinates ? state.assetGeometry[0].coordinates[0] : 0 }
        coordinates={state.assetGeometry}
        al={state.al}
        polylines={false}
        selectAssets={(Asset: any) => selectAssetsonMap(Asset)}
        iFramePanelOpened={IframePanelOpened}
        onClose={() => _onDismissMap()}
        selectParent={state.selectParent}
        selectModeMap={state.selectModeMap}
      />
    )
  }

  const renderAssetPanel = () => {
    return (
      <div>
        <Dialog
          open={state.showPanel}
          onClose={() => setState(prevState => ({ ...prevState, showPanel: false }))}
          fullWidth={true}
          maxWidth={'xl'}
          aria-labelledby='max-width-dialog-title'
        >
          <DialogTitle id='scroll-dialog-title'>
            Search Asset
            <IconButton
              aria-label='close'
              onClick={() => setState(prevState => ({ ...prevState, showPanel: false }))}
              className='closeIcon'
              sx={{ float: 'right' }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <ScrollView width='100%' height='100%' showScrollbar='onScroll' scrollByThumb={true}>
              <AssetSearch
                selectMode={true}
                onClick={(Asset: any) => selectAssets(Asset)}
                heading={false}
                hideAssetMapButton={true}
                selectWO={true}
                myhttpclient={props.myhttpclient}
                aadHttpClient={props.aadHttpClient}
                onAssetsSelected={(Asset: any) => selectAssets(Asset)}
              />
            </ScrollView>
          </DialogContent>
        </Dialog>
      </div>
    )
  }

  const selectAssetsonMap = async (selectedAssetArray: any) => {
    if (selectedAssetArray.length > 1) {
      // Extract asset types and check if they are all the same
      const assetTypes = selectedAssetArray.map((asset: any) => asset.type)
      const allSameType = assetTypes.every((type: string, _: any, array: string[]) => type === array[0])
      if (!allSameType) {
        setAlertMessage(
          'The selected assets do not all have the same asset type. Please select assets of the same type.'
        )
        setShowAlert(true)
        return
      }
    }

    let flatCoordinates: any = []
    let isFirstAsset: boolean = state.Assets.length === 0
    const selectedAssets: any = [...state.Assets]
    const initialAssetType = state.assetType
    let duplicateAssets: any[] = []

    for (let i = 0; i < selectedAssetArray.length; i++) {
      let Asset = selectedAssetArray[i]
      let AssetProperties = JSON.parse(Asset.properties)

      if (isFirstAsset) {
        const chosenAssetType = Asset.type

        try {
          await _getworkTypeDataAsync(
            AssetProperties.Asset_Workgroup,
            AssetProperties.Ext_Accounting_System_1_ID2,
            AssetProperties.activityId,
            AssetProperties.taskId
          )
        } catch (error) {
          setAlertMessage('Work Group is not available for this Asset ' + Asset.id + '. Please choose different Asset.')
          setShowAlert(true)
          return
        }
        // _getFCRData(AssetProperties.Maintenance_Asset_Type);
        let flatCoordinatesArray = AssetProperties.geometry.flatCoordinates
        flatCoordinatesArray.forEach((element: any, i: any) => {
          if (i % 2 === 0) {
            flatCoordinates.push({
              lat: flatCoordinatesArray[i + 1],
              lng: flatCoordinatesArray[i]
            })
          }
        })

        let Parseddata = JSON.parse(selectedAssetArray[0].properties)
        const assetType = state.assetType // Get the stored assetType
        if (Asset.type === assetType) {
          selectedAssets.push(Asset)
          setState(prevState => ({
            ...prevState,
            defaultAsset: Asset,
            Assets: selectedAssets,
            assetSelectionMode: 'MapList',
            lat: selectedAssetArray[0].center[1].toString(),
            lng: selectedAssetArray[0].center[0].toString(),
            latLong: flatCoordinates[0],
            location: Parseddata.Location ? Parseddata.Location : '',
            assetlocation: Parseddata.asset_location + ' Adelaide SA Australia',
            iFramePanelOpened: false,
            selectParent: false,
            selectModeMap: false,
            AssetChanged: true
          }))
          assetTypeRef.current = chosenAssetType;
          isFirstAsset = false
        } else {
          setAlertMessage(
            'The selected asset does not match the asset type of the initially selected asset(' +
              assetType +
              '). You can only select assets of the same type. Please choose a different asset.'
          )
          setShowAlert(true)
          setIframePanelOpened(false)
          return
        }
      } else {
        // check for duplicates
        const isDuplicateAsset = selectedAssets.some(
          (existingAsset: any) =>
            existingAsset.AsseticAssetID === Asset.AsseticAssetID || existingAsset.erpAssetId == Asset.AsseticAssetID
        )
        if (isDuplicateAsset) {
          duplicateAssets.push(Asset.AsseticAssetID)
          setAlertMessage('Asset ID ' + duplicateAssets + ' is already added. Please select a different Asset')
          setShowAlert(true)
          continue // Skip to next asset
        }

        // Check if the asset type matches the first asset
        const assetType = assetTypeRef.current; // Get the stored assetType
        if (Asset.type === assetType) {
          // If assetType matches, add the asset
          selectedAssets.push(Asset)
          setState(preveState => ({
            ...preveState,
            Assets: selectedAssets,
            mode: 'MapList'
          }))
          setIframePanelOpened(false)
        } else {
          setAlertMessage(
            'The selected asset does not match the asset type of the initially selected asset(' +
              assetType +
              '). You can only select assets of the same type. Please choose a different asset.'
          )
          setShowAlert(true)
          setIframePanelOpened(false)
          continue // Skip to next asset
        }
      }
    }
    setIframePanelOpened(false)
  }

  const _getFCRData = async (maintenanceType: string) => {
    const api = Config.FcrCategorisation + maintenanceType

    try {
      const data: any = await utility.genericGetAPICallForList(api)
      const updatedFailureItems: any[] = []
      const updatedCauseItems: any[] = []

      data.failureCodes.forEach((failureCode: any) => {
        processFCRCode(failureCode, updatedFailureItems, updatedCauseItems)
      })

      data.causeCodes.forEach((causeCode: any) => {
        processFCRCode(causeCode, updatedFailureItems, updatedCauseItems)
      })

      const updatedRemedyItems = data.remedyCodes.map((remedyCode: any) => ({
        id: remedyCode.id,
        text: `${remedyCode.code} - ${remedyCode.title}`
      }))

      const fromUngroupedData: any = new DataSource({
        store: {
          type: 'array',
          data: updatedFailureItems,
          key: 'ID'
        },
        group: 'Category'
      })

      const fromCauseUngroupedData: any = new DataSource({
        store: {
          type: 'array',
          data: updatedCauseItems,
          key: 'ID'
        },
        group: 'Category'
      })

      setFailureItems(fromUngroupedData)
      setCauseItems(fromCauseUngroupedData)
      setRemedyItems(updatedRemedyItems)
    } catch (error) {
      console.log(error)
    }
  }

  const processFCRCode = (codes: any, FailureItems: any[], CauseItems: any[]) => {
    const category = codes.code + ' - ' + codes.title

    if (codes.failureSubCodes) {
      codes.failureSubCodes.forEach((subCode: any) => {
        FailureItems.push({
          Category: category,
          ID: subCode.id,
          Name: codes.code + ',' + subCode.code + ' - ' + subCode.title
        })
      })
    }

    if (codes.causeSubCodes) {
      codes.causeSubCodes.forEach((causeSubCode: any) => {
        CauseItems.push({
          Category: category,
          ID: causeSubCode.id,
          Name: codes.code + ',' + causeSubCode.code + ' - ' + causeSubCode.title
        })
      })
    }
  }

  const _getTeamDataAsync = async () => {
    try {
      utility.genericGetAPICall(Config.TeamList).then((data: any) => {
        const teamsItems = data.map((r: any) => ({ id: r.id, text: r.title, departmentId: r.department_id }))
        const sortedItems = utility._sortItems(teamsItems, 'text', false)
        const filteredteam = teamsItems
          .filter(
            (item: any) => item.departmentId === state.workorder.department_id && item.id === state.workorder.team_id
          )
          .map((item: any) => ({
            id: item.id,
            text: item.text,
            departmentId: item.departmentId
          }))
        const filteredteams = sortedItems
          .filter((item: any) => item.departmentId === state.workorder.department_id)
          .map((item: any) => ({
            id: item.id,
            text: item.text,
            departmentId: item.departmentId
          }))

        // const filteredcrew = state.crewItems
        //   .filter(
        //     (item: any) =>
        //       item.departmentId === state.workorder.department_id &&
        //       item.teamId === state.workorder.team_id &&
        //       item.id === state.workorder.crew_id
        //   )
        //   .map((item: any) => ({
        //     id: item.id,
        //     text: item.text,
        //     departmentId: item.departmentId,
        //     teamId: item.teamId
        //   }))

        setState(prevState => ({
          ...prevState,
          teamsItems: filteredteams,
          tempTeamItems: sortedItems,
          team: filteredteam ? filteredteam[0] : {},
          // tempCrewItems: state.crewItems,
          // allCrewItems: state.crewItems,
          // WOcrew: filteredcrew ? filteredcrew[0] : {}
        }))
        console.log('Team Items', filteredteams)
        console.log('All Team Items', sortedItems)
      })
    } catch (error) {
      console.error(error)
    }
  }

  const _getCrewData = () => {
    try {
      utility.genericGetAPICall(Config.crewlistname).then((data: any) => {
        const crewItems = data.map((r: any) => ({
          id: r.id,
          text: r.title,
          departmentId: r.team?.department?.id,
          teamId: r.team?.id
        }))
        const sortedItems = utility._sortItems(crewItems, 'text', false)
        const filteredcrew = sortedItems
          .filter(
            (item: any) =>
              item.departmentId === state.workorder.department_id &&
              item.teamId === state.workorder.team_id &&
              item.id === state.workorder.crew_id
          )
          .map((item: any) => ({
            id: item.id,
            text: item.text,
            departmentId: item.departmentId,
            teamId: item.teamId
          }))

        setState(prevState => ({
          ...prevState,
          tempCrewItems: filteredcrew,
          allCrewItems: sortedItems,
          WOcrew: filteredcrew ? filteredcrew[0] : {}
        }))
        console.log('Crew Items', filteredcrew)
      })
    }catch (error) {
      console.error(error)
    }
  }

  const _getDepartmentData = () => {
    try {
      utility.genericGetAPICall(Config.DepartmentList).then((data: any) => {
        const deptItems = data.map((r: any) => ({ id: r.id, text: r.title }))
        const sortedItems = utility._sortItems(deptItems, 'text', false)
        const filteredDepartment = sortedItems
          .filter((item: any) => item.id === props.workorder.department_id)
          .map((item: any) => ({
            id: item.id,
            text: item.text
          }))
        setDepartmentItems(sortedItems)
        setTempDepartmentItems(sortedItems)
        setState(prevState => ({
          ...prevState,
          department: filteredDepartment ? filteredDepartment[0] : {}
        }))
      })
    } catch (error) {
      console.error(error)
    }
  }

  const update = () => {
    // if (!state.WOcrew) {
    //   setAlertMessage('Please Enter Crew/Team')
    //   setShowAlert(true)

    //   return
    // }
    // if (!state.Eststartdate) {
    //   setAlertMessage('Please select Schedule Start Date')
    //   setShowAlert(true)

    //   return
    // }

    // if (!state.Estenddate) {
    //   setAlertMessage('Please select Schedule End Date')
    //   setShowAlert(true)

    //   return
    // }
    // if (!state.status) {
    //   setAlertMessage('Please select status')
    //   setShowAlert(true)

    //   return
    // }
    if (!state.WRTType) {
     setAlertMessage("Please select Activity")
      setShowAlert(true)
      return;
    }
    if (!state.Assets) {
      setAlertMessage("Please select an Asset")
      setShowAlert(true)
      return;
    }
    if (!state.location) {
      setAlertMessage('Please enter Location')
      setShowAlert(true)
      return
    }
    if (state.Eststartdate && state.Estenddate) {
      const dfrom = new Date(state.Eststartdate)
      const dTo = new Date(state.Estenddate)
      if (dTo.getTime() < dfrom.getTime()) {
        setAlertMessage('Schedule End Date/time should be greater than Schedule Start Date/time')
        setShowAlert(true)

        return
      }
    }

    setUpdating(true)
    setAlertMessage(<Spinner label='Updating work order' />)
    setHideAlertButton(true)
    setShowAlert(true)

    const currentdate = new Date()
    const dataToPassToService: any = {}

    const woID = state.itemID
    // dataToPassToService.id = woID
    dataToPassToService.workorder_location = state.location
    if (state.status && state.status.id) {
    dataToPassToService.workorder_status_id = +state.status.id
    }
    if(state.department && state.department.id){
      dataToPassToService.department_id = state.department.id
    }
    if(state.team && state.team.id){
      dataToPassToService.team_id = state.team.id
    }
    if(state.WOpriority && state.WOpriority.id){
      dataToPassToService.priority_id = state.WOpriority.id
    }
    if(state.WOcrew && state.WOcrew.id){
      dataToPassToService.crew_id = state.WOcrew.id
    }
    dataToPassToService.workorder_type_id = state.WRTType.id
    dataToPassToService.description = state.description
    dataToPassToService.date_scheduled_start = state.Eststartdate ? new Date(state.Eststartdate) : null
    dataToPassToService.date_scheduled_end = state.Estenddate ? new Date(state.Estenddate) : null
    dataToPassToService.photo_required_after = state.aftpicreq
    dataToPassToService.photo_required_before = state.befpicreq
    dataToPassToService.risk_assessment_required = state.riskassessment
     //dataToPassToService.priority_id = state.WOpriority.id
    //dataToPassToService.crew_id = state.WOcrew ? state.WOcrew.id : -1
    //dataToPassToService.tenantId = Config.tenantId
    //dataToPassToService.team_id = state.team.id
    //dataToPassToService.department_id = state.department.id
   if(state.Assets && state.Assets.length > 0){
    dataToPassToService.asset_ids = state.Assets.map((asset: any) => 
      asset.id ? asset.id : asset.AssetID)
   }

    utility.genericUpdatePatchAPICall(Config.UpdateWO, +woID, dataToPassToService).then(
      (results: any) => {
        // console.log(results);
        setHideAlertButton(false)
        setAlertMessage('Work Order has been updated')
        setShowAlert(true)
        props.onClick()
      },
      (err: any) => {
        console.log(err)
        setAlertMessage('An error occurred. Unable to update details.')
        setShowAlert(true)
        setHideAlertButton(false)
      }
    )
  }

  const alert = () => {
    return (
      <AlertDialog
        showDialog={showAlert}
        message={alertMessage}
        title={Config.productTitle}
        onClose={() => _closeDialog()}
        hideAlertButton={hideAlertButton}
      />
    )
  }

  const _closeDialog = (): void => {
    setAlertMessage('')
    setShowAlert(false)
  }

  const handleValueChange = (event: any, fieldName: string) => {
    if (fieldName === 'Eststartdate' && event.value) {
      const endDate = new Date(event.value)
      endDate.setHours(event.value.getHours() + 1)
      setState(prevState => ({
        ...prevState,
        Estenddate: new Date(endDate)
      }))
    }

    setState(prevState => ({
      ...prevState,
      [fieldName]: event.value
    }))
  }

  const handleDropdownChange = (e: any, fieldName: string) => {
    const defaultStatus = state.StatusItems && state.StatusItems.filter((item: any) => item.id === 5)
    if (fieldName === 'department') {
      const { tempTeamItems } = state
      setState(prevState => ({
        ...prevState,
        tempCrewItems: [],
        teamsItems: [],
        team: {},
        WOcrew: {},
        Eststartdate: null,
        Estenddate: null,
        status: defaultStatus && {id: defaultStatus[0].id, text: defaultStatus[0].text}
      }))
      const filteredTeam = tempTeamItems.filter((t: any) => t.departmentId === e.selectedItem?.id)

      setState(prevState => ({
        ...prevState,
        teamsItems: filteredTeam
      }))
      setTimeout(
        () =>
          setState(prevState => ({
            ...prevState,
            loading: false
          })),
        1000
      )
    }
    if (fieldName === 'team') {
      const { allCrewItems, department } = state
      setState(prevState => ({
        ...prevState,
        tempCrewItems: [],
        WOcrew: {},
        Eststartdate: null,
        Estenddate: null,
        status: defaultStatus && {id: defaultStatus[0].id, text: defaultStatus[0].text}
      }))
      const filteredCrew = allCrewItems.filter(
        (c: any) => c.teamId === e.selectedItem?.id && c.departmentId === department.id
      )

      setState(prevState => ({
        ...prevState,
        disableCrew: false,
        tempCrewItems: filteredCrew
      }))
    }

    if(fieldName === 'WOcrew' && e.selectedItem){
      if(e.selectedItem.id !== state.workorder.crew_id){
        setState(prevState => ({
          ...prevState,
          WOcrew: e.selectedItem,
          Eststartdate: null,
          Estenddate: null,
          status: {}
        }))
    }
    }

    // if (!state.WOcrew || Object.keys(state.WOcrew).length === 0) {
    //   setState(prevState => ({
    //     ...prevState,
    //     WOcrew: {},
    //     Eststartdate: null,
    //     Estenddate: null,
    //     status: defaultStatus && {id: defaultStatus[0].id, text: defaultStatus[0].text}
    //   }))
    // }

    setState(prevState => ({
      ...prevState,
      [fieldName]: e.selectedItem
    }))
  }

  const SearchClicked = () => {
    setState(prevState => ({
      ...prevState,
      selectModeMap: true,
      selectParent: false
    }))
    setIframePanelOpened(true)
  }

  const removeAssetNumber = (index: any) => {
    // Check if the index is valid
    if (index >= 0 && index < state.Assets.length) {
      // Create a new array of assets excluding the one at the specified index
      const updatedAssets: any = [...state.Assets]
      updatedAssets.splice(index, 1)

      // Check if the removed asset was the last one
      if (updatedAssets.length === 0) {
        let endDate = new Date()
        endDate.setHours(endDate.getHours() + 1)
        setState(prevState => ({
          ...prevState,
          Assets: [],
          defaultAsset: '',
          WRTType: '',
          department: '',
          crewteam: '',
          location: '',
          assetlocation: '',
          Eststartdate: new Date(),
          Estenddate: new Date(endDate),
          befpicreq: false,
          aftpicreq: false,
          riskassessment: false,
          isWorkorderOvertime: false,
          team: '',
          Cause: '',
          Remedy: '',
          InspectionForm: '',
          leadinghand: '',
          description: '',
          status: '',
          statusItems: state.StatusItems,
          WRTTypeItems: allWRTTypeItems,
          teamItems: state.tempTeamItems,
          departmentItems: alldepartmentItems,
          tempCrewItems: state.crewItems,
          Failure: '',
          FailureItems: [],
          CauseItems: [],
          RemedyItems: []
        }))
      } else {
        // Update the state with the new array of assets
        setState(prevState => ({
          ...prevState,
          Assets: updatedAssets
        }))
      }
    }
  }

  useEffect(() => {
    if (props.showModal) {
      didMount()
    }
  }, [props.showModal])

  return (
    <div>
      {showAlert && alert()}
      {state.showPanel && renderAssetPanel()}
      {renderAssetMap()}
      <Dialog
        open={props.showModal}
        onClose={() => props.onClose()}
        fullWidth={true}
        maxWidth={'lg'}
        aria-labelledby='max-width-dialog-title'
        className='modal-popup'
        ref={dialogRef}
      >
        <DialogTitle id='scroll-dialog-title'>
          Edit Work Order
          <IconButton aria-label='close' onClick={() => props.onClose()} className='closeIcon' sx={{ float: 'right' }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers={false}>
          <ScrollView width='100%' height='100%' showScrollbar='onScroll' scrollByThumb={true}>
            <div className='ui-paper-edit'>
              {/* {!workTypeLoading ? ( */}
              <div>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6}>
                    <Paper elevation={6} className={'assetPaper'} onClick={() => setState(prevState => ({ ...prevState, showPanel: true }))}>
                      <Grid container spacing={1}>
                        <Grid item xs={1} sm={1} className={'AssetIconDiv'}>
                          <EmojiTransportationIcon className={'AssetIcon'} />
                        </Grid>
                        <Grid
                          item
                          xs={4}
                          sm={4}
                          className={'AssetLable'}
                          onClick={() => setState(prevState => ({ ...prevState, showPanel: true }))}
                        >
                          <p className={'AssetboxTitle'}>Search Asset from List</p>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Paper elevation={6} className={'assetPaper'} onClick={SearchClicked}>
                      <Grid container spacing={1}>
                        <Grid item xs={1} sm={1} className={'AssetIconDiv'}>
                          <MapIcon className={'AssetIcon'} />
                        </Grid>
                        <Grid item xs={4} sm={4} className={'AssetLable'}>
                          <p className={'AssetboxTitle'}>Search Asset from Map</p>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                </Grid>
                <br />
                <Grid container spacing={1}>
                  {state.Assets && state.Assets.length > 0 && (
                    // <div className='results-container'>
                    <MuiAccordion style={{ width: '100%' }} className='ui-paper-accordion'>
                      <MuiAccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls='panel1a-content'
                        id='panel1a-header'
                      >
                        <Typography className={'selectAssets'}>Selected Assets</Typography>
                      </MuiAccordionSummary>
                      <MuiAccordionDetails>
                        <Grid container spacing={1}>
                          {state.Assets.map((asset: any, index: number) => (
                            <Grid item xs={12} sm={12} key={index}>
                              <Paper elevation={12} className={'assetSelectPaper'}>
                                <span className={''}>
                                  {asset.id ? asset.id : asset.AssetID},{' '}
                                  {asset.AssetName ? asset.AssetName : ''}
                                </span>
                                <span className='floatRight'>
                                  <DeleteIcon className={'danger'} onClick={() => removeAssetNumber(index)} />
                                </span>
                              </Paper>
                            </Grid>
                          ))}
                        </Grid>
                      </MuiAccordionDetails>
                    </MuiAccordion>
                    // </div>
                  )}
                </Grid>
                <br />
              </div>
              <div className='ui-container'>
                <Grid container spacing={3}>
                  {state.WRTTypeItems && state.WRTTypeItems.length >= 0 && (
                    <Grid item xs={12} sm={6} md={4}>
                       <InputLabel shrink className='label bold'>
                        Activity *
                      </InputLabel>
                      <SelectBoxInput
                        stylingMode='outlined'
                        displayExpr='text'
                        valueExpr='id'
                        showClearButton={true}
                        items={state.WRTTypeItems ? state.WRTTypeItems : ''}
                        selectedItem={state.WRTType ? state.WRTType : ''}
                        onSelectionChanged={(e: any) => handleDropdownChange(e, 'WRTType')}
                        //disabled={state.WRTType.disabled}
                      />
                    </Grid>
                  )}
                  {departmentItems && (
                    <Grid item xs={12} sm={6} md={4}>
                       <InputLabel shrink className='label bold'>
                          Department
                        </InputLabel>
                      <SelectBoxInput
                        stylingMode='outlined'
                        displayExpr='text'
                        valueExpr='id'
                        showClearButton={true}
                        items={departmentItems}
                        selectedItem={state.department}
                        onSelectionChanged={(e: any) => handleDropdownChange(e, 'department')}
                      />
                    </Grid>
                  )}
                  {state.teamsItems && (
                    <Grid item xs={12} sm={6} md={4}>
                       <InputLabel shrink className='label bold'>
                       Teams
                      </InputLabel>
                      <SelectBoxInput
                        stylingMode='outlined'
                        displayExpr='text'
                        valueExpr='id'
                        showClearButton={true}
                        items={state.teamsItems}
                        selectedItem={state.team}
                        onSelectionChanged={(e: any) => handleDropdownChange(e, 'team')}
                      />
                    </Grid>
                  )}
                  {state.tempCrewItems && (
                    <Grid item xs={12} sm={6} md={4}>
                       <InputLabel shrink className='label bold'>
                          Crew
                      </InputLabel>
                      <SelectBoxInput
                        stylingMode='outlined'
                        displayExpr='text'
                        valueExpr='id'
                        showClearButton={true}
                        items={state.tempCrewItems}
                        selectedItem={state.WOcrew}
                        onSelectionChanged={(e: any) => handleDropdownChange(e, 'WOcrew')}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} sm={6} md={4}>
                  <InputLabel shrink className='label bold'>
                    Schedule Start Date
                  </InputLabel>
                    <DateBoxInput
                      stylingMode='outlined'
                      type='datetime'
                      displayFormat={'dd/MM/yy hh:mm a'}
                      showClearButton={true}
                      value={state.Eststartdate ? new Date(state.Eststartdate) : null}
                      onValueChanged={(e: any) => handleValueChange(e, 'Eststartdate')}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                  <InputLabel shrink className='label bold'>
                    Schedule End Date
                  </InputLabel>
                    <DateBoxInput
                      stylingMode='outlined'
                      value={state.Estenddate ? new Date(state.Estenddate) : null}
                      type='datetime'
                      displayFormat={'dd/MM/yy hh:mm a'}
                      showClearButton={true}
                      onValueChanged={(e: any) => handleValueChange(e, 'Estenddate')}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                  <InputLabel shrink className='label bold'>
                   Description
                  </InputLabel>
                    <TextAreaInput
                      value={state.description}
                      onValueChanged={(e: any) => handleValueChange(e, 'description')}
                    />
                  </Grid>
                  {/* <Grid item xs={12} sm={6} md={4}>
                    <SelectBoxInput
                      stylingMode='outlined'
                      displayExpr='text'
                      valueExpr='id'
                      showClearButton={true}
                      label='Department *'
                      items={departmentItems}
                      selectedItem={state.department}
                      onSelectionChanged={(e: any) => handleDropdownChange(e, 'department')}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <SelectBoxInput
                      stylingMode='outlined'
                      displayExpr='text'
                      valueExpr='id'
                      showClearButton={true}
                      items={state.teamsItems}
                      selectedItem={state.team}
                      label='Teams *'
                      onSelectionChanged={(e: any) => handleDropdownChange(e, 'team')}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <SelectBoxInput
                      stylingMode='outlined'
                      displayExpr='text'
                      valueExpr='id'
                      showClearButton={true}
                      items={state.tempCrewItems}
                      selectedItem={state.WOcrew}
                      label='Crew *'
                      onSelectionChanged={(e: any) => handleDropdownChange(e, 'WOcrew')}
                    />
                  </Grid> */}
                  <Grid item xs={12} sm={6} md={4}>
                  <InputLabel shrink className='label bold'>
                    Priority
                  </InputLabel>
                    <SelectBoxInput
                      stylingMode='outlined'
                      displayExpr='text'
                      valueExpr='id'
                      showClearButton={true}
                      items={props.priorityItems}
                      selectedItem={state.WOpriority}
                      onSelectionChanged={(e: any) => handleDropdownChange(e, 'WOpriority')}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                  <InputLabel shrink className='label bold'>
                    Location *
                  </InputLabel>
                    <TextBoxInput
                      stylingMode='outlined'
                      value={state.location}
                      onValueChanged={(e: any) => handleValueChange(e, 'location')}
                      disabled={false}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                  <InputLabel shrink className='label bold'>
                    Status
                  </InputLabel>
                    <SelectBoxInput
                      stylingMode='outlined'
                      displayExpr='text'
                      valueExpr='id'
                      showClearButton={true}
                      items={state.StatusItems}
                      selectedItem={state.status}
                      onSelectionChanged={(e: any) => handleDropdownChange(e, 'status')}
                    />
                  </Grid>
                  {/* <Grid item xs={12} sm={6} md={4}>
                    <SelectBox
                      stylingMode='outlined'
                      showClearButton={true}
                      label='Failure'
                      valueExpr='ID'
                      grouped={true}
                      displayExpr='Name'
                      dataSource={failureItems}
                      selectedItem={state.Failure}
                      disabled={state.Assets ? false : true}
                      defaultValue={state.Failure}
                      onSelectionChanged={(e: any) => handleDropdownChange(e, 'Failure')}
                    />
                  </Grid> */}
                  {/* <Grid item xs={12} sm={6} md={4}>
                    <SelectBox
                      stylingMode='outlined'
                      showClearButton={true}
                      label='Cause'
                      valueExpr='ID'
                      grouped={true}
                      displayExpr='Name'
                      dataSource={causeItems}
                      selectedItem={state.Cause}
                      disabled={state.Assets ? false : true}
                      defaultValue={state.Cause}
                      onSelectionChanged={(e: any) => handleDropdownChange(e, 'Cause')}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <SelectBoxInput
                      stylingMode='outlined'
                      displayExpr='text'
                      valueExpr='id'
                      showClearButton={true}
                      items={remedyItems}
                      selectedItem={state.Remedy}
                      disabled={state.Assets ? false : true}
                      label='Remedy'
                      onSelectionChanged={(e: any) => handleDropdownChange(e, 'Remedy')}
                    />
                  </Grid> */}
                  {/* <Grid item xs={12} sm={12}> */}
                  {/* <Grid container spacing={3}> */}
                  <Grid item xs={12} sm={6} md={2}>
                    <br/>
                    <CheckBoxInput
                      value={state.befpicreq}
                      onValueChanged={(e: any) => handleValueChange(e, 'befpicreq')}
                      text={'Before Photo Required'}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={2}>
                  <br/>
                    <CheckBoxInput
                      value={state.aftpicreq}
                      onValueChanged={(e: any) => handleValueChange(e, 'aftpicreq')}
                      text={'After Photo Required'}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={2}>
                    <br/>
                    <CheckBoxInput
                      value={state.riskassessment}
                      onValueChanged={(e: any) => handleValueChange(e, 'riskassessment')}
                      text={'Site Risk Assessment'}
                    />
                  </Grid>
                </Grid>
                {/* </Grid> */}
                {/* </Grid> */}
              </div>
              {/* ) : (
                  <div>
                    <Spinner
                      size="large"
                      label="Loading all componenent. Please wait..."
                    />
                  </div>
                )} */}
            </div>
          </ScrollView>
        </DialogContent>
        <DialogActions>
          <div className='action-buttons'>
            <Button onClick={() => props.onClose()} variant='outlined'>
              Cancel
            </Button>
            <Button onClick={() => update()} variant='outlined' color='primary' className='primary-button'>
              Save
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default EditWorkOrder
