import React, { useState } from 'react';

import AlertDialog from '../Common/AlertDialog';
import { useAuth } from '../../AuthContext';

import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import useMediaQuery from '@mui/material/useMediaQuery';
import OutlinedInput from '@mui/material/OutlinedInput';
import { styled, useTheme } from '@mui/material/styles';
import FormHelperText from '@mui/material/FormHelperText';
import InputAdornment from '@mui/material/InputAdornment';
import LoginBg from '../../images/login-bg.svg';
import Logo from '../../images/logo.svg'; // Import LHS Logo
import FooterImage from '../../images/footer-image.svg'; // Import Footer Image
import Typography from '@mui/material/Typography';
import WOLogo from '../../images/menu/wo-logo.svg';
import NavLogo from '../../images/menu/nav-logo.png';

interface LoginForm {
  email: string;
  password: string;
}

const schema = yup.object().shape({
  email: yup.string().required('User ID is required').email('Invalid email format'),
  password: yup.string().required('Password is required').min(3, 'Password must be at least 3 characters'),
});

const LoginPage: React.FC = () => {
  const [rememberMe, setRememberMe] = useState<boolean>(true);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [password, setPassword] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>('');
  const [hideAlertButton, setHideAlertButton] = useState<boolean>(false);
  const auth = useAuth();

  const defaultValues = {
    password: password,
    email: email,
  };

  const {
    control,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: LoginForm) => {
    console.log(data);
    const { email, password } = data;
    auth.login({ email, password, rememberMe }, () => {
      setError('email', {
        type: 'manual',
        message: 'Email or Password is invalid',
      });
    });
  };

  const alert = (): JSX.Element => (
    <AlertDialog
      showDialog={showAlert}
      message={alertMessage}
      title="User Not Found"
      onClose={() => {
        setAlertMessage('');
        setShowAlert(false);
      }}
      hideAlertButton={hideAlertButton}
    />
  );

  return (
    <Box
      sx={{
        width: '100%',
        height: 'calc(100vh - 60px)', // Full height
        display: 'flex',
        flexDirection: 'column', // Ensure footer aligns properly
        backgroundImage: `url(${LoginBg})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'repeat',
        backgroundPosition: 'center',
        margin:'40px 20px'
      }}
      className={ 'login-page'}
    >
      {/* Header with LHS Logo */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          p: 2,
          px: 4,
        }}
      >
        <div style={{display:'flex', alignItems:'center'}}>
        <img src={WOLogo} alt="Logo" style={{ paddingRight: '8px' }} />
        <span className="worx-text">Worx</span><span className="online-text">Online</span> 
        </div>
        <Button className='request-demo-button' variant="text" color="primary" onClick={() => console.log('Help Clicked')}>
          Request Demo
        </Button>
      </Box>

      {/* Main Login Form */}
      <Box
        sx={{
          flex: 1, // Take available space
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            p: 7,
            width: '300px',
            maxWidth: '400px',
            backgroundColor: 'background.paper',
            borderRadius: '34px',
            boxShadow: 3,
          }}
          className='login-form'
        >
          <Typography variant="h5" sx={{ mb: 4, textAlign: 'center', fontWeight: 'bold' }}>
            Executive Login
          </Typography>

          <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <FormControl fullWidth sx={{ mb: 3 }}>
              <Controller
                name="email"
                control={control}
                render={({ field: { value, onChange, onBlur } }) => (
                  <TextField
                    autoFocus
                    label="User ID"
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    error={Boolean(errors.email)}
                    placeholder="Enter User ID"
                  />
                )}
              />
              <FormHelperText error>{errors.email?.message}</FormHelperText>
            </FormControl>

            <FormControl fullWidth sx={{ mb: 3 }}>
              <InputLabel htmlFor="auth-login-password" error={Boolean(errors.password)}>
                Password
              </InputLabel>
              <Controller
                name="password"
                control={control}
                render={({ field: { value, onChange, onBlur } }) => (
                  <OutlinedInput
                    id="auth-login-password"
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    error={Boolean(errors.password)}
                    type={showPassword ? 'text' : 'password'}
                    sx={{ mb: 1 }}
                    // endAdornment={
                    //   <InputAdornment position="end">
                    //     <IconButton onClick={() => setShowPassword(!showPassword)}>
                    //       {showPassword ? 'Hide' : 'Show'}
                    //     </IconButton>
                    //   </InputAdornment>
                    // }
                  />
                )}
              />
              <span><a>Forgot password ? </a></span>
              <FormHelperText error>{errors.password?.message}</FormHelperText>
            </FormControl>

            <Button
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              color="primary"
              className='login-button'
              sx={{ mb: 4, backgroundColor: '#03AC3C;', color: '#fff', textTransform: 'none' }}
            >
              Login
            </Button>
            <span>Don't have an account? <a>Sign up ! </a></span>
          </form>
        </Box>
      </Box>

      {/* Footer with Image */}
      <Box
        sx={{
          textAlign: 'center',
          py: 2,
        }}
      >
        <img src={NavLogo} alt="Footer Decoration" style={{maxHeight: '100px' }} />
      </Box>
    </Box>
  );
};

export default LoginPage;
