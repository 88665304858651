import React, { useState, useEffect, SyntheticEvent } from 'react'
import { useLocation, useParams } from 'react-router-dom';

//import { ScriptLoader } from '@react-google-maps/api'
import * as PropTypes from 'prop-types'

import global from '../../../../classes/global'
import { Config } from '../../../../classes/config'
import DataTable from '../../../Common/CustomASDataGrid'
import AlertDialog from '../../../Common/AlertDialog'
import GoogleMapComponent from '../../../Common/MapComponent/GoogleMapComponent'
import AddAssetAttachment from '../../../AddAttachment/AddAssetAttachment'
import LoadIndicator from 'devextreme-react/load-indicator'
import Geocode from 'react-geocode'
import utility from '../../../../classes/utility'
import DataGrid, { Scrolling, Pager, Paging, HeaderFilter, SearchPanel, Column } from 'devextreme-react/data-grid'
import moment from 'moment'
import { useAssetContext } from '../../../../AssetContext';

import IconButton from '@mui/material/IconButton'
import Icon from '@mui/material/Icon'
import Grid from '@mui/material/Grid'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import TabPanel from '../../../Common/TabPanel'
import Spinner from '../../../Common/ReusableComponents/Spinner'
import { Input, Typography } from '@mui/material/'
import { styled } from '@mui/material/styles'
import 'devextreme/dist/css/dx.light.css'

import EditIcon from '@mui/icons-material/Edit';
import NoteAddRounded from '@mui/icons-material/NoteAddRounded'
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined'
import BuildIcon from '@mui/icons-material/Build'
import PlaceOutlined from '@mui/icons-material/PlaceOutlined'

//import { a11yPropsTop } from '../../utils/helper-functions';

// set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
Geocode.setApiKey(global.googleAPIKey)
console.log('in Geocode::' + global.googleAPIKey)

// set response language. Defaults to english.
Geocode.setLanguage('en')

// set response region. Its optional.
Geocode.setRegion('au')

export const _WOcolumns: Columns[] = [
  {
    field: 'Workordernumber',
    title: 'Work Order #',
    type: 'string',
    allowHiding: false
    //link: 'workorderLink'
  },
  {
    field: 'worktype',
    title: 'Activity',

    // width: 160,
    type: 'string',
    allowHiding: true
  },
  {
    field: 'scope',
    title: 'Scope',

    // width: 160,
    type: 'string',
    allowHiding: false
  },
  {
    field: 'priority',
    title: 'Priority',

    // width: 110,
    type: 'string',
    allowHiding: true
  },
  {
    field: 'crew',
    title: 'Crew',

    // width: 160,
    type: 'string',
    allowHiding: true
  },
  {
    field: 'WOStatus',
    title: 'Status',

    // width: 120,
    type: 'string',
    allowHiding: true
  },
  {
    field: 'servicerequest',
    title: 'Work Req #',
    width: 160,
    type: 'string',
    allowHiding: true
  },
  {
    field: 'location',
    title: 'Location',

    // width: 180,
    type: 'string',
    allowHiding: true
  },
  {
    field: 'suburb',
    title: 'Suburb',

    // width: 160,
    type: 'string',
    allowHiding: true
  },
  {
    field: 'eststartdate',
    title: 'Scheduled On',
    width: 180,
    type: 'datetime',
    allowHiding: true
  },
  {
    field: 'createdon',
    title: 'Created On',
    width: 180,
    type: 'datetime',
    allowHiding: true
  }
]

export const _scheduleWOcolumns: Columns[] = [
  {
    field: 'ScheduleID',
    title: 'Schedule ID',
    width: 160,
    type: 'string',
    allowHiding: true
  },
  {
    field: 'ScheduleType',
    title: 'Schedule Type',
    width: 250,
    type: 'string',
    allowHiding: true
  },
  {
    field: 'ActivityDescription',
    title: 'Activity',
    width: 500,
    type: 'string',
    allowHiding: true
  },

  {
    field: 'scope',
    title: 'Scope',
    width: 250,
    type: 'string',
    allowHiding: true
  },
  {
    field: 'Interval',
    title: 'Interval',
    width: 160,
    type: 'string',
    allowHiding: true
  },
  {
    field: 'ScheduledDateTime',
    title: 'Scheduled On',
    width: 250,
    type: 'datetime',
    allowHiding: true
  },
  {
    field: 'ExpireDate',
    title: 'Expire Date',
    width: 100,
    type: 'datetime',
    allowHiding: true
  }
]

export const _Docphotoscolumns: Columns[] = [
  {
    field: 'id',
    title: 'ID',

    // width: 160,
    type: 'string'
  },
  {
    field: 'document',
    title: 'Document',

    // width: 160,
    type: 'string',
    link: 'file'
  },
  {
    field: 'comments',
    title: 'Comments',

    // width: 160,
    type: 'string'
  },
  {
    field: 'uploadedon',
    title: 'Uploaded On',

    // width: 160,
    type: 'datetime'
  },
  {
    field: 'uploadedby',
    title: 'Uploaded By',

    // width: 160,
    type: 'string'
  }
]

export const _DefectsWOcolumns: Columns[] = [
  {
    field: 'defectsCode',
    title: 'Defect Code',

    // width: 160,
    type: 'string'
  },
  {
    field: 'description',
    title: 'Defect',

    // width: 160,
    type: 'string'
  },
  {
    field: 'workOrderNo',
    title: 'Work Order #',

    // width: 160,
    type: 'string'
  },
  {
    field: 'created',
    title: 'Created On',
    width: 160,
    type: 'datetime'
  }
]

export const _attributescolumns = [
  {
    dataField: 'fieldName',
    caption: 'Field Name',

    // width: 160,
    dataType: 'string',
    allowHiding: false
  },
  {
    dataField: 'fieldValue',
    caption: 'Value',

    // width: 160,
    dataType: 'string',
    allowHiding: false
  }
]

export const _assetInfocolumns: Columns[] = [
  {
    field: 'accntSystemName',
    title: 'Account System Name',

    // width: 160,
    type: 'string',
    allowHiding: false
  },
  {
    field: 'accntSystemId',
    title: 'Account System Id',

    // width: 160,
    type: 'string',
    allowHiding: false
  },
  {
    field: 'erpExtSystemName',
    title: 'Ext System Name',

    // width: 160,
    type: 'string',
    allowHiding: false
  },
  {
    field: 'functionalLocationL1Id',
    title: 'L1 Id',

    // width: 160,
    type: 'string',
    allowHiding: false
  },
  {
    field: 'functionalLocationL1Name',
    title: 'L1 Name',

    // width: 160,
    type: 'string',
    allowHiding: false
  },
  {
    field: 'functionalLocationL1Type',
    title: 'L1 Type',

    // width: 160,
    type: 'string',
    allowHiding: false
  },
  {
    field: 'functionalLocationL2Id',
    title: 'L2 Id',

    // width: 160,
    type: 'string',
    allowHiding: false
  },
  {
    field: 'functionalLocationL2Name',
    title: 'L2 Name',

    // width: 160,
    type: 'string',
    allowHiding: false
  },
  {
    field: 'functionalLocationL2Type',
    title: 'L2 Type',

    // width: 160,
    type: 'string',
    allowHiding: false
  }
]

// interface AssetDetailProps {
//   context: any
//   location: any
//   aadHttpClient: any
//   Department: any
//   currentUser: any
// }

interface Columns {
  field?: string
  title?: string
  type?: string
  width?: number
  allowHiding?: boolean
  visible?: boolean
  link?: string
}

interface AssetDetailsProps {
  useStyle?: any
  Register?: string
  AssetNumber?: number
  AssetID?: number
  id?: number
  decommisionDate?: string
  AssetType?: string
  AssetTypeId?: number
  activityId?: number
  taskId?: number
  locationId?: number
  AssetClass?: string
  AssetName?: string
  erpAssetId?: number
  erpExtAssetId?: number
  ServiceStatus?: string
  Description?: string
  Location?: any
  maintenanceAssetType?: string
  Category?: string
  assetCriticality?: string
  departmentTitle?: string
  assetsubclass?: string
  teamTitle?: string
  typeTitle?: string
  subtypeTitle?: string
  accntSystemName?: string
  accntSystemId?: number
  barcode?: string
  assetLastModified?: string
  projectCode?: string
  erpExtSystemName?: string
  functionalLocationL1Id?: number
  functionalLocationL1Name?: string
  functionalLocationL1Type?: string
  functionalLocationL2Id?: number
  functionalLocationL2Name?: string
  functionalLocationL2Type?: string
  parentAssetId?: number
  masterAssetId?: number
  assetStatusDate?: string
  Selected?: boolean
}

interface State {
  marker?: any
  useStyle?: any
  iconColor?: string
  AssetPhoto?: any
  assetdetails?: any[]
  assetDetailsItems?: AssetDetailsProps[]
  loading?: boolean
  AssetID?: any
  AssetSearchNumber?: any

  //selectedTopTab?: number
  selected?: number

  //WOcolumns?: (Columns | undefined)[]
  WOItems?: any[]
  scheduleWOItems?: any[]
  scheduleWOCount?: number

  //scheduleWOcolumns?: any[]
  //DocPhotoscolumns?: (Columns | undefined)[]
  DocPhotosItems?: any[]

  //defectsColumns?: (Columns | undefined)[]
  defectsItems?: (Columns | undefined)[]

  //attributesColumns?: (Columns | undefined)[]
  //attributesItems?: (Columns | undefined)[]
  defectsCount?: number
  DocPhotosCount?: number
}

interface WOProps {
  role?: any
  Department?: any
  currentUser?:any
  pageListItems?:any
  isContractor?:any
  team?:any
}

const AssetDetail = (props: AssetDetailsProps & WOProps) => {

  const location = useLocation();
  const { setAssetDetailItems, setFromAssetDetail } = useAssetContext();
  //const navigate = useNavigate()
  const allocationReferences: any = ''
  //let AssetSearchNumber: any = null
  //let AssetID: any = null
  const AssetCateogry: any = null
  const currentUser: any = null
  const AssetType: any = null
  const AssetTypeName: any = null
  const Compkey: any = null
  const _scheduleWOItems: any[] = []
  const _defectsItems: any[] = []
  const _DocPhotosItems: any[] = []

  const [scheduleLoading, setScheduleLoading] = useState(false)
  const [defectLoading, setDefectLoading] = useState(false)
  const [documentLoading, setDocumentLoading] = useState(false)

  //const [scheduleWOCount, setScheduleWOCount] = useState(0)
  //const [scheduleWOItems, setScheduleWOItems] = useState([])
  const [scheduleWOcolumns, setScheduleWOColumns] = useState(_scheduleWOcolumns)
  const [defectsCount, setDefectsCount] = useState(0)

  //const [defectsItems, setDefectsItems] = useState([])
  const [defectsColumns, setDefectsColumns] = useState(_DefectsWOcolumns)

  //const [DocPhotosCount, setDocPhotosCount] = useState(0)
  const [DocPhotosItems, setDocPhotosItems] = useState([])
  const [DocPhotosColumns, setDocPhotosColumns] = useState(_Docphotoscolumns)
  const [showAlert, setShowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [showDefectModal, setShowDefectModal] = useState(false)
  const [details, setDetails] = useState<any>(null)
  const [selected, setSelected] = useState(null)
  const [selectedTopTab, setSelectedTopTab] = useState<number>(0)

  //const [assetdetails, setAssetdetails] = useState(null)
  const [loadingAsset, setLoadingAsset] = useState(false)
  const [marker, setMarker] = useState(0)
  const [assetPhoto, setAssetPhoto] = useState(null)

  //const [assetDetailsItems, setAssetDetailsItems] = useState([])
  const [callAfterInit, setCallAfterInit] = useState(false)
  const [loadingWO, setLoadingWO] = useState(false)
  const [WOCount, setWOCount] = useState(0)

  //const [WOItems, setWOItems] = useState<any[]>([])
  const [WOcolumns, setWOcolumns] = useState(_WOcolumns)
  const [showModalofAttachment, setShowModalofAttachment] = useState(false)
  const [attributesLoading, setAttributesLoading] = useState(false)
  const [attributesItems, setAttributesItems] = useState([])
  const [attributesColumns, setAttributescolumns] = useState(_attributescolumns)
  const [showMap, setShowMap] = useState(false)

  const [state, setState] = useState<State>({
    marker: null,
    AssetPhoto: null,
    assetdetails: [],
    assetDetailsItems: [],
    loading: true,

    //selectedTopTab: 1,
    selected: 0,
    WOItems: [],
    scheduleWOItems: [],
    scheduleWOCount: 0,

    //scheduleWOcolumns: [],
    //DocPhotoscolumns: [],
    DocPhotosItems: [],

    //defectsColumns: [],
    defectsItems: []

    //attributesColumns: [],
    //attributesItems: []
  })

  useEffect(() => {
    let assetItems = location.state?.assetDetailItems;
    if (assetItems) {
      localStorage.setItem('assetDetailItems', JSON.stringify(assetItems));
      setAssetDetailItems(assetItems);
      setFromAssetDetail(true);
    } else {
      const storedAssetItems = localStorage.getItem('assetDetailItems');
      if (storedAssetItems) {
        assetItems = JSON.parse(storedAssetItems);
        setAssetDetailItems(assetItems);
        setFromAssetDetail(true);
      } else {
        setAssetDetailItems(null);
        setFromAssetDetail(false);
      }
    }
  }, [location.state, setAssetDetailItems, setFromAssetDetail]);

  useEffect(() => {
   
    const search = location.search;
    const params = new URLSearchParams(search);

    const AssetID = params.get('AssetID');
    const AssetSearchNumber = params.get('AssetNO')

    if (AssetID !== null) {
      // Access and update the state using setState
      setState(prevState => ({
        ...prevState,
        AssetID: AssetID
      }))
    }

    if (AssetSearchNumber !== null) {
      // Access and update the state using setState
      setState(prevState => ({
        ...prevState,
        AssetSearchNumber: AssetSearchNumber
      }))
    }

    AssetID && loadAssetDetails(AssetID)
    
  }, [])

  // useEffect(() => {
  //   getMaintenanceSchedule()
  //   getDefects(AssetID)
  //   getAssetDocument(AssetID)
  // }, [])

  // const reloadIFrame = () => {
  //   window.addEventListener('message', handleIframeTaskfordetail, true)
  // }

  // const viewDefectDetials = () => {
  //   let selected
  //   setDetails(selected)
  //   setShowDefectModal(true)
  // }

  const handleIframeTaskfordetail = (e: any) => {
    if (e.data.command === 'asset selected') {
      const mapProps: any = JSON.parse(e.data.properties)
      const AssetDetail = Config.AssetdetailURL + mapProps.ref
      window.removeEventListener('message', handleIframeTaskfordetail, false)
      window.open(AssetDetail, '_self')

      // location.reload();
      window.removeEventListener('message', handleIframeTaskfordetail, false)
    }
  }

  const getLatlong = (place: any) => {
    let address = place.toLowerCase()
    if (address.includes('street')) {
      address = address.replace('street', 'st')
    }

    Geocode.fromAddress(address).then(
      (response:any) => {
        const { lat, lng } = response.results[0].geometry.location

        if (lat && lng) {
          const marker = { lat: lat, lng: lng }
          setState(prevState => ({
            ...prevState,
            marker: marker
          }))
        }
      },
      (error:any) => {
        console.error(error)
      }
    )
  }

  const handleDetailsTabChange = (event: SyntheticEvent, newValue: number) => {
    setSelectedTopTab(newValue)
  }

  const handleToggleMap = async () => {
    setShowMap(prevShowMap => !prevShowMap)
  }

  const loadAssetDetails = (asset_id: any) => {
    setLoadingAsset(true)

    const dataToPassToService: any = {}
    if (asset_id !== null || asset_id !== undefined) {
      dataToPassToService.id = asset_id

      utility
        .genericGetDataByIdAPICall(Config.GetAssetDetails, asset_id)
        .then((item: any) => {
          const assetDetailsItemsData: AssetDetailsProps[] = []
          let AssetPhoto: any
          let marker: any
          if (item && Object.keys(item).length > 0) {
            item = item[0];
            //const coordinates = JSON.parse(item.coordinates)
            getworkorderFromAsset(item.id)
            getAssetAttributes(item.id)
            // marker = item.coordinates
            //   ? { lng: coordinates.coordinates[0], lat: coordinates.coordinates[1] }
            //   : { lat: 0, lng: 0 }
            assetDetailsItemsData.push({
              Register: item.registerTitle,
              AssetNumber: item.id,
              AssetID: item.id,
              id: item.id,
              decommisionDate: item.commission_date,
              AssetType: item.asset_type.title,
              AssetTypeId: item.asset_type.id,
              activityId: item.activityId,
              taskId: item.taskId,
              locationId: item.locationId,
              AssetClass: item.asset_class.title,
              AssetName: item.title,
              erpAssetId: item.erp_asset_id,
              erpExtAssetId: item.ext_system_id,
              ServiceStatus: item.asset_status ? item.asset_status.status : '',
              Description: item.description,
              Location: item.asset_location,
              maintenanceAssetType: item.maintenance_asset_type,
              Category: item.asset_category ? item.asset_category.title : '',
              assetCriticality: item.asset_criticality,
              departmentTitle: item.asset_team && item.asset_team.department ? item.asset_team.department.title : '',
              assetsubclass: item.asset_sub_class ? item.asset_sub_class.title : '',
              teamTitle: item.asset_team ? item.asset_team.title : '',
              typeTitle: item.asset_type ? item.asset_type.title : '',
              subtypeTitle: item.asset_sub_type ? item.asset_sub_type.title : '',
              accntSystemName: item.accnt_system_name,
              accntSystemId: item.accnt_system_id,
              barcode: item.barcode,
              assetLastModified: item.asset_last_modified,
              projectCode: item.project_code,
              erpExtSystemName: item.ext_system_name,
              functionalLocationL1Id: item.functional_location_l1_id,
              functionalLocationL1Name: item.functional_location_l1_name,
              functionalLocationL1Type: item.functional_location_l1_type,
              functionalLocationL2Id: item.functional_location_l2_id,
              functionalLocationL2Name: item.functional_location_l2_name,
              functionalLocationL2Type: item.functional_location_l2_type,
              parentAssetId: item.parent_asset_id,
              masterAssetId: item.master_asset_id,
              assetStatusDate: item.assetStatusDate,
              Selected: false,
              useStyle: item.useStyle
            })
            // setState(prevState => ({
            //   ...prevState,
            //   marker: marker
            // }))

            setState(prevState => ({
              ...prevState,
              AssetPhoto: AssetPhoto,
              assetdetails: item,
              assetDetailsItems: assetDetailsItemsData,
              loading: false
            }))
            setLoadingAsset(false)
          } else {
            setState(prevState => ({
              ...prevState,
              assetdetails: [],
              loading: false
            }))
            setLoadingAsset(false)
          }
        })
        .catch((err: any) => {
          console.log(err)
          setState(prevState => ({
            ...prevState,
            assetdetails: []
          }))
          setLoadingAsset(false)
        })
    }
  }

  const getworkorderFromAsset = (assetId: number[]) => {
    const dataToPassToServiceforWO: any = {}
    setLoadingWO(true)
    dataToPassToServiceforWO.asset_id = [assetId]

    utility
      .genericPostAPICall(Config.WOSearchAPI, dataToPassToServiceforWO)
      .then((workorder: any) => {
        let _WorkOrderItems: any[] = []

        if (workorder.length !== null || workorder.length !== 0) {
          _WorkOrderItems = workorder.map((item: any) => ({
            id: item.id,
            woid: item.id,
            workrequest: item.work_request_id && item.work_request_id > 1 ? item.work_request_id : '',
            Workordernumber: item.id,
            erpWorkorderNumber: item.erp_workorder_number,
            title: item.title,
            priority: item.priority_id,
            worktype: item.workorder_type.title,
            crew: item.crew ? item.crew.title : '',
            department: item.department_id,
            latlong: item.lat_long,
            createdon: item.created,
            Datecompleted: item.date_completed || null,
            eststartdate: item.date_scheduled_start || null,
            fieldstatus: item.status,
            suburb: item.suburb || '',
            workorderLocation: item.workorder_location || ''
          }))

          _WorkOrderItems = utility._sortItems(_WorkOrderItems, 'createdon', true)

          setWOCount(_WorkOrderItems.length)
          setState(prevState => ({ ...prevState, WOItems: _WorkOrderItems }))

          //setState({ WOItems: _WorkOrderItems })
          setLoadingWO(false)
          setWOcolumns(_WOcolumns)
        } else {
          setWOCount(_WorkOrderItems.length)
          setState(prevState => ({ ...prevState, WOItems: _WorkOrderItems }))
          setLoadingWO(false)
          setWOcolumns(_WOcolumns)
          setCallAfterInit(false)
        }
      })
      .catch((err: any) => {
        console.log(err)
        setLoadingWO(false)
      })
  }

  const getAssetAttributes = (assetId: number) => {
    setAttributesLoading(true)
    utility.genericGetDataByIdAPICall(Config.AssetAttributes, assetId).then(
      (items: any) => {
        const _attributesItems: any = []
        items.forEach((item: any) => {
          let attributeValue = item.attribute_value
          if (item.asset_attribute_name.field_type === 'DateTime') {
            attributeValue = moment(item.attributeValue).format('DD/MM/YYYY h:mm a')
          }
          _attributesItems.push({
            fieldName: item.asset_attribute_name ? item.asset_attribute_name.caption : '',
            fieldValue: attributeValue
          })
        })

        setAttributesItems(_attributesItems)
        setAttributescolumns(_attributescolumns)
        setAttributesLoading(false)
        setCallAfterInit(true)
      },
      (err: any) => {
        setAttributesLoading(false)
      }
    )
  }

  const getMaintenanceSchedule = () => {
    setScheduleLoading(true)
    const assetID = `?AssetID=${state.AssetSearchNumber}`
    utility.genericGetDataByIdAPICall(Config.MaintenanceSchedule, assetID).then(
      (items: any) => {
        const _scheduleWOItems: any[] = []
        items.forEach((item: any) => {
          _scheduleWOItems.push({
            id: item.ScheduleKey,
            ScheduleID: item.ScheduleKey ? item.ScheduleKey : '',
            ScheduleType: item.ScheduleType ? item.ScheduleType : '',
            IntervalUnitOfMeasure: item.IntervalUnitOfMeasure,
            TimeInterval: item.TimeInterval,
            Interval: item.TimeInterval + ' ' + item.IntervalUnitOfMeasure,
            ScheduledDateTime: validateDate(item.ScheduledDateTime),
            ExpireDate: validateDate(item.ExpireDate),
            updateBy: item.lastModifiedBy,
            updatedOn: validateDate(item.lastModifiedDateTime),
            scope: item.Scope ? item.Scope : '',
            ActivityDescription: item.Activity
          })
        })

        setState({
          scheduleWOCount: _scheduleWOItems.length,
          scheduleWOItems: _scheduleWOItems
        })
        setScheduleWOColumns(scheduleWOcolumns)
        setScheduleLoading(false)
        setCallAfterInit(true)
      },
      (err: any) => {
        setScheduleLoading(false)
      }
    )
  }

  const getDefects = (id: any) => {
    setDefectLoading(true)
    const dataToPassToService: any = {}
    dataToPassToService.assetId = state.AssetSearchNumber
    utility.genericPostAPICall(Config.SearchDefect, dataToPassToService).then(
      (defects: any) => {
        let _defectsItems: any[] = []
        if (defects.length !== null || defects.length !== 0) {
          defects.forEach((item: any) => {
            _defectsItems.push({
              id: item.Id,
              defectsCode: item.DefectCode,
              workOrderNo: item.WorkOrderNumber,
              description: item.DefectTitle,
              WorkOrder: item.WorkOrder,
              defectQuestions: item.DefectQuestions,
              created: item.created
            })
          })
          _defectsItems = utility._sortItems(_defectsItems, 'createdon', true)
          setState({
            defectsCount: defects.length,
            defectsItems: _defectsItems

            //defectsColumns: _DefectsWOcolumns
          })
          setDefectsColumns(defectsColumns)
          setDefectLoading(false)
          setCallAfterInit(true)
        }
      },
      (err: any) => {
        setDefectLoading(false)
      }
    )
  }

  const getAssetDocument = (id: any) => {
    setDocumentLoading(true)
    utility.genericGetDataByIdAPICall(Config.assetDocumentList, id).then(
      (assetdoc: any) => {
        let _DocPhotosItems: any[] = []
        if (assetdoc.length !== null || assetdoc.length !== 0) {
          assetdoc.forEach((item: any) => {
            _DocPhotosItems.push({
              document: item.Name ? item.Name : '',
              comments: item.Comments,
              uploadedon: item.CreatedOn ? item : '',
              id: item.ID ? item.ID : ' ',
              uploadedby: item.UploadedBy ? item.UploadedBy : '',
              fileURL: item.FileURL ? item.FileURL : ''
            })
          })
          _DocPhotosItems = utility._sortItems(_DocPhotosItems, 'uploadedon', true)
          setState({
            DocPhotosCount: assetdoc.length,
            DocPhotosItems: _DocPhotosItems

            //DocPhotoscolumns: _Docphotoscolumns
          })
          setDocPhotosColumns(_Docphotoscolumns)
          setDocumentLoading(false)
          setCallAfterInit(true)
        }
      },
      (err: any) => {
        setDocumentLoading(false)
      }
    )
  }

  const validateDate = (date: any) => {
    let d = ''
    const validDateFrom = new Date('1950-01-01')

    if (date) {
      const tempdate = new Date(date)
      if (tempdate.getTime() > validDateFrom.getTime()) {
        d = date
      }
    }

    return d
  }

  const alert = () => {
    return (
      <AlertDialog
        showDialog={showAlert}
        message={alertMessage}
        title={Config.productTitle}
        onClose={() => {
          setAlertMessage('')
          setShowAlert(false)
        }}
      />
    )
  }

  const viewDocument = (a: any) => {
    const Asset = a

    const dataToPassToService: any = {}
    if (!utility.validURL(Asset.fileURL) || !Asset.document) {
      setAlertMessage('Unable to view this document. File URL or File name does not exist')
      setShowAlert(true)

      return
    }
    dataToPassToService.Name = Asset.document
    dataToPassToService.FileURL = Asset.fileURL
    utility.genericPostAPIcallForDowloadDoct(Config.dowloadDocumentList, dataToPassToService).then((doc: any) => {
      window.open(doc, '_blank')
    })
  }

  const viewDefectDetails = () => {
    let selected
    setDetails(selected)
    setShowDefectModal(true)
  }

  const closeDefectModal = () => {
    setShowDefectModal(false)
  }

  const renderDefectdetailModal = () => {
    //return <DefectDetailModal showModal={showDefectModal} onClick={closeDefectModal} details={details} />
  }

  const showAttachmentModal = () => {
    setShowModalofAttachment(true)
  }

  const closeModalofAttachment = (item: any) => {
    setShowModalofAttachment(false)
    if (item === true) {
      setAlertMessage('Attachment(s) uploaded successfully.')
      setShowAlert(true)
    }
  }

  const stopCommsAfterInit = () => {
    setCallAfterInit(false)
  }

  const handleSelect = (event: any, newValue: any) => {
    setSelected(newValue)
  }

  // const handleSelectTopTab = (event: any, newValue: any) => {
  //   setSelectedTopTab(newValue)
  // }

  const renderAttachmentModal = () => {
    return (
      <AddAssetAttachment
        showModal={showModalofAttachment}
        onClose={closeModalofAttachment}
        //AssetId={state.assetDetailsItems ? state.assetDetailsItems.id : ''}
        updateDoc={() => setTimeout(getAssetDocument, 2000)}
        details={state.assetDetailsItems}
        currentUser={currentUser}
        //context={props.context}
        message={message}
      />
    )
  }

  const message = () => {
    setAlertMessage('Asset document has been added')
    setShowAlert(true)
  }

  const loaderTab = (title: any, items: any, isLoading: any) => {
    const count = items.length
    if (isLoading) {
      return (
        <span className='tabHeading'>
          {title} <LoadIndicator id='small-indicator' height={15} width={15} />
        </span>
      )
    } else {
      return (
        <span className='tabHeading'>
          {title} [{count}]
        </span>
      )
    }
  }

  return (
    <div>
      <Grid direction='row' container spacing={3}>
        <Grid item xs={12} sm={6}>
          <h2 id='asset-search-types'>
            {state.assetDetailsItems && state.assetDetailsItems.length > 0 ? state.assetDetailsItems[0].AssetName : ''}
          </h2>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div className='icon-buttons-list'>
            <IconButton>
            <EditIcon titleAccess='Edit Asset' className={'iconcolor'} />
            </IconButton>
            <IconButton>
            <NoteAddRounded titleAccess='Add Notes' className={'iconcolor'} />
            </IconButton>
            <IconButton>
            <AttachFileOutlinedIcon titleAccess='Attach Document' className={'iconcolor'} />
            </IconButton>
            <IconButton>
            <BuildIcon titleAccess='settings' className={'iconcolor'} />
            </IconButton>
            <IconButton onClick={handleToggleMap}>
            <PlaceOutlined titleAccess='Show Map' className={'iconcolor'} />
            </IconButton>
          </div>
        </Grid>
      </Grid>
      {!state.assetDetailsItems && !state.assetDetailsItems === undefined ? (
        <div>
          <Spinner size='large' label='Loading' />
          <br />
        </div>
      ) : (!state.assetDetailsItems && state.assetDetailsItems === undefined) ||
        (state.assetDetailsItems && state.assetDetailsItems.length <= 0) ? (
        <div className='alignCenter p-l-r-20'>
          <br />
          <p>Asset Info not found</p>
          <br />
        </div>
      ) : null}

      <div className={'ui-container asset-details'}>
        {state.assetDetailsItems && state.assetDetailsItems.length > 0 && (
          <>
            <Grid container spacing={3}>
              {!showMap ? (
                <>
                  <Grid item xs={12} sm={6} md={3}>
                    <Grid container direction='column' item spacing={1}>
                      <Grid item>
                        <Typography variant='body1'>Asset #</Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant='body2'>{state.assetDetailsItems[0].AssetNumber}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Grid container direction='column' item spacing={1}>
                      <Grid item>
                        <Typography variant='body1'>Asset Name</Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant='body2'>{state.assetDetailsItems[0].AssetName}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Grid container direction='column' item spacing={1}>
                      <Grid item>
                        <Typography variant='body1'>External Asset ID</Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant='body2'>{state.assetDetailsItems[0].erpExtAssetId}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Grid container direction='column' item spacing={1}>
                      <Grid item>
                        <Typography variant='body1'>ERP Asset ID</Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant='body2'>
                          <a
                            href={global.AsseticAssetURL + state.assetDetailsItems[0].erpAssetId}
                            className='WOlink'
                            rel='noreferrer'
                            target='_blank'
                          >
                            {state.assetDetailsItems[0].erpAssetId}
                          </a>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <Grid container direction='column' item spacing={1}>
                      <Grid item>
                        <Typography variant='body1'>Description</Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant='body2'>{state.assetDetailsItems[0].Description}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Grid container direction='column' item spacing={1}>
                      <Grid item>
                        <Typography variant='body1'>Asset Status</Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant='body2'>{state.assetDetailsItems[0].ServiceStatus}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Grid container direction='column' item spacing={1}>
                      <Grid item>
                        <Typography variant='body1'>Location</Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant='body2'>{state.assetDetailsItems[0].Location}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Grid container direction='column' item spacing={1}>
                      <Grid item>
                        <Typography variant='body1'>Department</Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant='body2'>{state.assetDetailsItems[0].departmentTitle}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Grid container direction='column' item spacing={1}>
                      <Grid item>
                        <Typography variant='body1'>Category</Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant='body2'>{state.assetDetailsItems[0].Category}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Grid container direction='column' item spacing={1}>
                      <Grid item>
                        <Typography variant='body1'>Team</Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant='body2'>{state.assetDetailsItems[0].teamTitle}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Grid container direction='column' item spacing={1}>
                      <Grid item>
                        <Typography variant='body1'>Asset Class</Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant='body2'>{state.assetDetailsItems[0].AssetClass}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Grid container direction='column' item spacing={1}>
                      <Grid item>
                        <Typography variant='body1'>Asset Sub Class</Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant='body2'>{state.assetDetailsItems[0].assetsubclass}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Grid container direction='column' item spacing={1}>
                      <Grid item>
                        <Typography variant='body1'>Asset Type</Typography>
                      </Grid>
                      <Grid item className='assetValueItem'>
                        <Typography variant='body2'>{state.assetDetailsItems[0].AssetType}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Grid container direction='column' item spacing={1}>
                      <Grid item>
                        <Typography variant='body1'>Asset Sub Type</Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant='body2'>{state.assetDetailsItems[0].subtypeTitle}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              ) : (
                <Grid container item xs={12}>
                  {state.marker && state.marker.lat !== 0 ? (
                    <GoogleMapComponent
                      singleMarkers={state.marker}
                      center={state.marker}
                      useStyle={state.useStyle}
                      iconColor={'33cc33'}
                      zoom={15}
                      height='35vh'
                      showMarker={true}
                      showMap={showMap}
                    />
                  ) : (
                    <h3>No Position Data</h3>
                  )}
                </Grid>
              )}
            </Grid>
          </>
        )}
      </div>

      <div className='display-container asset-details'>
        <h2>Other Details</h2>
        <Grid item xs={12} sm={6}>
          
            <Tabs variant='fullWidth' value={selectedTopTab} onChange={handleDetailsTabChange} className='results-container tabs-section'>
              <Tab value={0} label={loaderTab('Workorders', state.WOItems, loadingWO)} />
              <Tab value={1} label={loaderTab('Maintenance & Inspection', state.scheduleWOItems, scheduleLoading)} />
              <Tab value={2} label={loaderTab('Documents', state.DocPhotosItems, documentLoading)} />
              <Tab value={3} label={loaderTab('Defects', state.defectsItems, defectLoading)} />
              <Tab value={4} label={loaderTab('Attributes', attributesItems, attributesLoading)} />
              <Tab value={5} label={loaderTab('Asset Details', state.assetDetailsItems, loadingAsset)} />
            </Tabs>
            <TabPanel value={selectedTopTab} index={0}>
              {!loadingWO ? (
                <>
                  {state.WOItems && state.WOItems.length > 0 && (
                    <div className='results-container full-width'>
                      <DataTable
                        filteralbe={false}
                        groupable={false}
                        dataSource={state.WOItems}
                        columns={WOcolumns}
                        // fileName={'Timesheet'}
                        // columnChooser={'prestartchecklistColumns'}
                        selectMode={false}
                        showHideColumnMenu={false}
                        callAfterInit={callAfterInit}
                        //stopCommsAfterInit={()stopCommsAfterInit()}
                        loading={loadingWO}
                        allowExportSelectedData={false}
                        showNewBtn={false}
                        hideExport={true}
                        hideSearchPanel={true}
                        showCommandBtn={false}
                        showViewDetailBtn={false}
                      />
                    </div>
                  )}
                </>
              ) : (
                // <CircularProgress className="loader" />
                <Spinner size='large' label='Loading' />
              )}
            </TabPanel>

            <TabPanel value={selectedTopTab} index={1}>
              {state.scheduleWOItems && state.scheduleWOItems.length > 0 && (
                <div className='results-container full-width'>
                  <DataTable
                    //style={{ width: '100%' }}
                    filteralbe={false}
                    groupable={false}
                    dataSource={state.scheduleWOItems}
                    columns={scheduleWOcolumns}
                    // fileName={'Timesheet'}
                    // columnChooser={'prestartchecklistColumns'}
                    selectMode={false}
                    showHideColumnMenu={false}
                    callAfterInit={callAfterInit}
                    stopCommsAfterInit={() => stopCommsAfterInit()}
                    loading={loadingWO}
                    allowExportSelectedData={false}
                    showNewBtn={false}
                    hideExport={true}
                    hideSearchPanel={true}
                    showCommandBtn={false}
                    showViewDetailBtn={false}
                  />
                </div>
              )}
            </TabPanel>
            <TabPanel value={selectedTopTab} index={2}>
              {DocPhotosItems && DocPhotosItems.length > 0 && (
                <div className='results-container full-width'>
                  <DataTable
                    //style={{ width: '100%' }}
                    filteralbe={false}
                    groupable={false}
                    dataSource={DocPhotosItems}
                    columns={DocPhotosColumns}
                    // fileName={'Timesheet'}
                    // columnChooser={'prestartchecklistColumns'}
                    selectMode={false}
                    showHideColumnMenu={false}
                    callAfterInit={callAfterInit}
                    stopCommsAfterInit={() => stopCommsAfterInit()}
                    loading={loadingWO}
                    allowExportSelectedData={false}
                    showNewBtn={false}
                    hideExport={true}
                    hideSearchPanel={true}
                    showCommandBtn={false}
                    showViewDetailBtn={false}

                    //viewFile={(e: any) => viewDocument(e)}
                  />
                </div>
              )}
            </TabPanel>
            <TabPanel value={selectedTopTab} index={3}>
              {state.defectsItems && state.defectsItems.length > 0 && (
                <div className='results-container full-width'>
                  <DataTable
                    //style={{ width: '100%' }}
                    filteralbe={false}
                    groupable={false}
                    dataSource={state.defectsItems}
                    columns={defectsColumns}
                    // fileName={'Timesheet'}
                    // columnChooser={'prestartchecklistColumns'}
                    selectMode={false}
                    showHideColumnMenu={false}
                    callAfterInit={callAfterInit}
                    stopCommsAfterInit={() => stopCommsAfterInit()}
                    loading={loadingWO}
                    allowExportSelectedData={false}
                    showNewBtn={false}
                    hideExport={true}
                    hideSearchPanel={true}
                    showCommandBtn={false}
                    showViewDetailBtn={false}
                  />
                </div>
              )}
            </TabPanel>
            <TabPanel value={selectedTopTab} index={4}>
              {attributesItems && attributesItems.length > 0 && (
                <div className='results-container full-width'>
                  <DataGrid
                    id='gridContainer'
                    dataSource={attributesItems}
                    keyExpr='fieldName'
                    showBorders={false}

                    //columns={_attributescolumns}
                  >
                    <SearchPanel visible={true} width={240} placeholder='Search...' />
                    <Scrolling rowRenderingMode='virtual'></Scrolling>
                    <HeaderFilter visible={true} />
                    <Paging defaultPageSize={20} />
                    <Pager
                      visible={true}
                      allowedPageSizes={[20, 40, 'all']}
                      showPageSizeSelector={true}
                      showNavigationButtons={true}
                    />
                  </DataGrid>
                </div>
              )}
            </TabPanel>
            <TabPanel value={selectedTopTab} index={5}>
              <div className='results-container full-width details-list'>
                {state.assetDetailsItems && state.assetDetailsItems.length > 0 && (
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={6}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={3}>
                          Project Identifier:
                        </Grid>
                        <Grid item xs={12} sm={9} className={'bold'}>
                          {state.assetDetailsItems[0].accntSystemId ? state.assetDetailsItems[0].accntSystemId : ''}
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={3}>
                          Account System Name:
                        </Grid>
                        <Grid item xs={12} sm={9} className={'bold'}>
                          {state.assetDetailsItems[0].accntSystemName ? state.assetDetailsItems[0].accntSystemName : ''}
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={3}>
                          Location ID:
                        </Grid>
                        <Grid item xs={12} sm={9} className={'bold'}>
                          {state.assetDetailsItems[0].locationId ? state.assetDetailsItems[0].locationId : ''}
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={3}>
                          Activity ID:
                        </Grid>
                        <Grid item xs={12} sm={9} className={'bold'}>
                          {state.assetDetailsItems[0].activityId ? state.assetDetailsItems[0].activityId : ''}
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={3}>
                          Task ID:
                        </Grid>
                        <Grid item xs={12} sm={9} className={'bold'}>
                          {state.assetDetailsItems[0].taskId ? state.assetDetailsItems[0].taskId : ''}
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={3}>
                          Barcode:
                        </Grid>
                        <Grid item xs={12} sm={9} className={'bold'}>
                          {state.assetDetailsItems[0].barcode ? state.assetDetailsItems[0].barcode : ''}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={3}>
                          Decommision Date:
                        </Grid>
                        <Grid item xs={12} sm={9} className={'bold'}>
                          {state.assetDetailsItems[0].decommisionDate
                            ? moment(state.assetDetailsItems[0].decommisionDate).format('DD/MM/YY h:mm a')
                            : null}
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={3}>
                          Modified Date:
                        </Grid>
                        <Grid item xs={12} sm={9} className={'bold'}>
                          {state.assetDetailsItems[0].assetLastModified
                            ? moment(state.assetDetailsItems[0].assetLastModified).format('DD/MM/YY h:mm a')
                            : null}
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={3}>
                          Asset Status Changed Date:
                        </Grid>
                        <Grid item xs={12} sm={9} className={'bold'}>
                          {state.assetDetailsItems[0].assetStatusDate
                            ? moment(state.assetDetailsItems[0].assetStatusDate).format('DD/MM/YY h:mm a')
                            : null}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </div>
            </TabPanel>
          
        </Grid>
      </div>
    </div>
  )
}

export default AssetDetail
