import React, { useState, useEffect, useRef, useCallback } from 'react'
import utility from '../../../classes/utility'
import { Config } from '../../../classes/config'
import DataTable from '../../Common/CustomASDataGrid'
import Spinner from '../../Common/ReusableComponents/Spinner'
import ViewControlMeasure from './ViewRiskControlMeasure'
import global from '../../../classes/global'
import CustomStore from 'devextreme/data/custom_store'
import { LoadOptions } from 'devextreme/data'
import TreeViewComponent from '../../Common/ReusableComponents/TreeViewComponent'

interface LoadResult {
  data: any[]
  totalCount: number
}

interface RiskGroup {
  id: number
  title: string
  is_active: boolean
  category_id: number
}

interface RiskCategory {
  id: number
  title: string
  risk_groups: RiskGroup[]
  items: any[]
}
let selectedRiskGroups: any = []
let initialRiskCategories: any = []

const RiskControlMeasure = (props: any) => {
  if (props.role !== global.worxOnline_Admin) {
    window.open('#/AccessDenied', '_self')
  }

  const [dataLoaded, setDataLoaded] = useState(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [items, setItems] = useState<any[]>([])
  const [riskCategories, setRiskCategories] = useState<RiskCategory[]>([])
  const [RiskHierarchys, setRiskHierarchys] = useState<any[]>([])
  const [selectedRiskGroupIds, setSelectedRiskGroupIds] = useState<number[]>([])
  const [selectedKeys, setSelectedKeys] = useState<any[]>([])
  const filterValue = [['is_active', '=', true]]
  // const [initialRiskCategories, setInitialRiskCategories] = useState<any[]>([])
  const treeViewRef = useRef<any>(null)

  const setHierarchyValue = (rowData: any, value: number) => {
    rowData.hierarchy_id = value || null
    const hierarchy = RiskHierarchys.find((d: any) => d.hierarchy_id === value)
    if (hierarchy) {
      rowData.title = hierarchy.title
    }
  }

  const setRiskGroupIdsValue = (rowData: any, value: any[]) => {
    rowData.risk_group_ids = value || []
  }

  const onUpdateSelectedKeys = useCallback((selectedKeys: number[]) => {
    console.log('Selected Keys:', selectedKeys)
    selectedRiskGroups = selectedKeys
  }, [])

  const columns = [
    {
      field: 'id',
      title: 'ID',
      width: 80,
      type: 'string',
      allowHiding: false,
      visibleColumn: false,
      formItem: {
        visible: false
      }
    },
    {
      field: 'title',
      title: 'Title',
      width: 300,
      type: 'string',
      allowHiding: false,
      isRequired: true
    },
    {
      field: 'hierarchy_id',
      title: 'Hierarchy',
      width: 300,
      type: 'string',
      allowHiding: false,
      setCellValue: setHierarchyValue,
      Lookup: {
        dataSource: RiskHierarchys,
        valueExpr: 'id',
        displayExpr: 'title'
      }
    },
    {
      field: 'risk_group_ids',
      title: 'Risk Group',
      width: 120,
      type: 'string',
      visibleColumn: false,
      allowHiding: true,
      // Lookup: {
      //   dataSource: riskCategories,
      //   valueExpr: 'id',
      //   displayExpr: 'title'
      // },
      setCellValue: setRiskGroupIdsValue,
      editCellComponent: (props: any) => (
        <TreeViewComponent
          cellData={props}
          treeViewItems={props.data?.data?.transformedRiskCategories || initialRiskCategories}
          onUpdateSelectedKeys={onUpdateSelectedKeys}
          selectedKeys={selectedKeys}
        />
      ),
      formItem: {
        visible: true
      }
    },
    {
      field: 'is_active',
      title: 'Active',
      width: 80,
      type: 'boolean',
      allowHiding: false,
      calculateCellValue: (data: any) => data.is_active ?? true, // Default to true
      setCellValue: (newData: any, value: any) => {
        newData.is_active = value
      }
    },
    {
      field: 'created',
      title: 'Created',
      width: 160,
      type: 'datetime',
      visible: false,
      allowHiding: true,
      formItem: {
        visible: false
      }
    },
    {
      field: 'created_by',
      title: 'Created By',
      type: 'string',
      visible: false,
      allowHiding: true,
      formItem: {
        visible: false
      }
    }
  ]

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const riskHierarchyResponse: any = await utility.genericGetAPICall(Config.RiskHierarchy)
        const riskHierarchyData = riskHierarchyResponse.map((r: any) => ({ id: r.id, title: r.title }))
        setRiskHierarchys(riskHierarchyData)

        setLoading(false)
        setDataLoaded(true)
      } catch (error) {
        console.error(error)
        setLoading(false)
      }
    }

    fetchData()
  }, [])

  const transformToTreeStructure = (data: any, selectedIds: any = []) => {
    return data.map((category: any) => {
      const items = category.risk_groups.map((group: any) => ({
        id: `group-${group.id}`,
        title: group.title,
        is_active: group.is_active,
        category_id: group.category_id,
        parentId: category.id,
        selected: selectedIds.includes(group.id) // Mark as selected if in selectedIds
      }))

      // Determine if any item is selected
      const isExpanded = items.some((item: any) => item.selected)

      return {
        id: category.id,
        title: category.title,
        created: category.created,
        created_by: category.created_by,
        risk_groups: category.risk_groups,
        parentId: category.parentId,
        items: items,
        expanded: isExpanded
        //selected: selectedIds.includes(category.id)
      }
    })
  }

  const dataSource = new CustomStore({
    load: async (loadOptions: LoadOptions): Promise<LoadResult> => {
      const riskCategoriesData: any = await utility.genericGetAPICall(Config.RiskCategorieswithGroups)
      initialRiskCategories = transformToTreeStructure(riskCategoriesData, [])
      // setInitialRiskCategories(riskCategories)
      console.log('Initial risk categories data:', riskCategories)

      return await utility.genericGetAPICall(Config.RiskControlmeasures).then((riskResponse: any) => {
        const riskData = riskResponse.map((r: any) => {
          const selectedRiskGroupIds = [
            ...new Set(
              r.riskrelations
                .map((relation: any) => relation.risk_group_id)
            )
          ];
          const transformedRiskCategories = transformToTreeStructure(riskCategoriesData, selectedRiskGroupIds)
          const risksrelations = r.risksrelations && r.risksrelations.map((element: any) => ({
              id: element.id,
              risk_id: element.risk_id,
              risk_group_id: element.risk_group_id,
              control_measure_id: element.control_measure_id
            }))
          return {
            ...r,
            transformedRiskCategories,
            is_active: r.is_active ? true : false,
            hierarchyId: r.hierarchy_id,
            risksrelations: risksrelations,
            updated: r.updated
          }
        })
        console.log('Transformed data with risk categories:', riskData)
        
        const sortedRiskData = utility._sortItems(riskData, 'updated', false)
        return {
          data: sortedRiskData,
          totalCount: sortedRiskData.length
        }
      })
    },
    key: 'id',
    onLoaded: () => {
      console.log('Data loaded')
      setLoading(false)
      setDataLoaded(true)
    },
    insert: async (values: any) => {
      try {
        values.riskrelations = [];
        if (values.risk_group_ids) {
          values.risk_group_ids.forEach((item: any) => {
            // If the item is prefixed with "group-", treat it as riskId
            if (typeof item === 'string' && item.startsWith("group-")) {
              const riskId = Number(item.replace("group-", "")); // Extract the numeric part
              values.riskrelations.push({
                id: 0, // Assuming 0 for new entries
                risk_id: riskId, // Set risk_id
                risk_group_id: 0, // Set risk_group_id to 0 since this entry is a riskId
                control_measure_id:  0 // Default control_measure_id
              });
            } else {
              // Treat plain numeric values as riskGroupId
              const riskGroupId = Number(item); // Ensure it's a number
              values.riskrelations.push({
                id: 0, // Assuming 0 for new entries
                risk_id: 0, // Set risk_id to 0 since this entry is a riskGroupId
                risk_group_id: riskGroupId, // Set risk_group_id
                control_measure_id: 0 // Default control_measure_id
              });
            }
          });
      
          // Remove risk_group_ids from the payload as it's no longer needed
          delete values.risk_group_ids;
        }
        await utility.genericPostAPICall(Config.RiskControlmeasures, values)
      } catch (error: any) {
        if (error instanceof Error && error.message.startsWith('Network response was not ok')) {
          try {
            const errorData = JSON.parse(error.message.replace('Network response was not ok: ', ''))
            const errorMessage = utility.extractErrorMessage(errorData)
            throw new Error(errorMessage)
          } catch (parseError) {
            throw new Error('Failed to process error response.')
          }
        } else {
          throw new Error('Failed to post data.')
        }
      }
    },
    update: async (key: any, values: any) => {
      try {
        if (values.risk_group_ids) {
          values.risk_group_ids = values.risk_group_ids.map((item: any) => {
            if (typeof item === 'string' && item.startsWith("group-")) {
              return Number(item.replace("group-", ""));
            }
            return Number(item); // Ensure that item is returned as a number
          });
        }
        await utility.genericUpdatePatchAPICall(Config.RiskControlmeasures, key, values)
      } catch (error: any) {
        throw error
      }
    },
    remove: async (key: any) => {
      try {
        await utility.genericDeleteAPICall(Config.RiskControlmeasures, key)
      } catch (error: any) {
        throw error
      }
    }
  })

  // const onEditorPreparing = (e: any) => {
  //   console.log('onEditorPreparing:', e.dataField); // Log dataField
  //   if (e.dataField === 'risk_group_ids') {
  //     console.log('risk_group_ids:', e.row.data.risk_group_ids); // Log risk_group_ids
  //     e.editorOptions.selectedItemKeys = e.row.data.risk_group_ids || [];
  //   }
  // };

  return (
    <>
      <div>
        {!loading ? (
          <div className='results-container'>
            <DataTable
              dataSource={dataSource}
              style={{ width: '100%' }}
              filteralbe={true}
              groupable={true}
              columns={columns}
              fileName={'Risk Control Measure'}
              columnChooser={'RiskControlMeasureColumns'}
              selectionMode={false}
              showHideColumnMenu={true}
              loading={loading}
              allowExportSelectedData={false}
              columnHidingEnabled={true}
              showCommandBtn={false}
              hideSearchPanel={false}
              hideCommandDeleteBtn={false}
              showNewBtn={false}
              showViewDetailBtn={false}
              hideNewBtn={true}
              defaultfiltervalue={filterValue}
              showMoreBtn={true}
              visible={dataLoaded}
              //onEditorPreparing={onEditorPreparing}
            />
          </div>
        ) : (
          <Spinner size='large' className='loader' />
        )}
      </div>
    </>
  )
}

export default RiskControlMeasure
