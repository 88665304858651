import React, { useEffect, useState, useCallback } from 'react'
import DropDownBox, { DropDownBoxTypes } from 'devextreme-react/drop-down-box';
import DataGrid, { Selection, FilterRow, Scrolling } from 'devextreme-react/data-grid'

const gridColumns = ['Select All']

interface Props {
  label?: string
  stylingMode: string | any
  placeholder?: string | any
  displayExpr: string | any
  valueExpr?: string | any
  grouped?: boolean | any
  selectedItem: any
  showClearButton?: boolean | any
  readOnly?: boolean | any
  disabled?: boolean | any
  onChange: any
  required?: any
  items: any
  spellcheck?: boolean | any
}

const MultiSelect: React.FC<Props> = props => {
  const [items, setItems] = useState(props.items)
  const [selectedItem, setSelectedItem] = useState(props.selectedItem)

  useEffect(() => {
    setItems(props.items)
  }, [props.items])
  
  useEffect(() => {
    setSelectedItem(props.selectedItem)
  }, [props.selectedItem])

  const dataGridOnSelectionChanged = (e: any) => {
    setSelectedItem(e.selectedRowKeys.length ? e.selectedRowKeys : [])
    const gridBoxValue = e.selectedRowKeys.length ? e.selectedRowKeys : []
    props.onChange(gridBoxValue)
  }

  const syncDataGridSelection = useCallback((e: DropDownBoxTypes.ValueChangedEvent) => {
    setSelectedItem(e.value || []);
  }, []);

  const dataGridRender = useCallback((items: any) => {
    return (
      <DataGrid
        height={430}
        dataSource={items}
        columns={gridColumns}
        hoverStateEnabled={false}
        selectedRowKeys={selectedItem}
        onSelectionChanged={dataGridOnSelectionChanged}
      >
        <Selection mode='multiple' />
        <Scrolling mode='virtual' />
        <FilterRow visible={true} />
      </DataGrid>
    )
  }, [items, selectedItem])

  return (
    <DropDownBox
      displayExpr={props.displayExpr}
      placeholder={props.placeholder}
      showClearButton={props.showClearButton}
      stylingMode={props.stylingMode}
      dataSource={items}
      value={selectedItem}
      onValueChanged={syncDataGridSelection}
      contentRender={() => dataGridRender(items)}
      label={props.label}
      labelMode={'floating'}
      disabled={props.disabled}
    />
  )
}

export default MultiSelect
